import axios from "axios";

export const createprofile = async(walletAddress, userName) =>{

    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
//     let datas = {
//         "algoAddress": walletAddress,
//         "accountType": "",
//         "profileName": firstName, //first name
//         "twitterName": "",
//         "profileURL": "",
//         "bio": "",
//         "profileImagePath": "",
//         "bgvImagePath": "",
//         "profileImageAsString": lastName, //last name
//         "twitterName": "",
//         "bgvImageAsString": description,
//         "followers": [],
//         "following": socialLinkList, //social links
//         "validuser": 1,
//         "email": "",
//         "passwd": ""
// }
      let datas = {
        "algoAddress": walletAddress,
        "profileURL": userName, //user name
        "bgvImagePath": "https://gateway.pinata.cloud/ipfs/Qmdez1SZp2VvYfoJNVo6K8cNjer3HBUp51PivC7Cp/5dLBU?_gl=1*14oea56*_ga*NTA2MzM5MTg3LjE2OTAzNTIxNDU.*_ga_5RMPXG14TE*MTY5MDU0MzkyMC4zLjEuMTY5MDU0NDMxMC42MC4wLjA.",
        "profileImagePath": "https://gateway.pinata.cloud/ipfs/QmdLCS6mfnfxvhy9TKZ7h3MCNAJ6y3piz2M7MKMhp2zvej?_gl=1*1p8csnr*rs_ga*OWNkNDU4MjktNDA4My00MzBlLTljMTktYmNkNjYyMDUyZDFk*rs_ga_5RMPXG14TE*MTY4MTg5MTIzMy41LjEuMTY4MTg5MTMwOC41Ny4wLjA"
      }

const options2 = {
    method: 'POST',
    url: '/platform/v1/userinfo',
    data: datas
  };
  let status;
  await axios.request(options2).then(function (response2) {
   console.log("response",response2);
   status = true;
  //  window.location.reload();
  }).catch(function (error) {
      console.error("done2",error);
      status = false
  });
    
  return status;
}

export const createprofileNew = async(walletAddress) =>{

  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    let datas = 
    {
      "walletAddress": walletAddress,
      "coverImagePath": "https://gateway.pinata.cloud/ipfs/Qmdez1SZp2VvYfoJNVo6K8cNjer3HBUp51PivC7Cp/5dLBU?_gl=1*14oea56*_ga*NTA2MzM5MTg3LjE2OTAzNTIxNDU.*_ga_5RMPXG14TE*MTY5MDU0MzkyMC4zLjEuMTY5MDU0NDMxMC42MC4wLjA.",
      "profileImagePath": "https://gateway.pinata.cloud/ipfs/QmdLCS6mfnfxvhy9TKZ7h3MCNAJ6y3piz2M7MKMhp2zvej?_gl=1*1p8csnr*rs_ga*OWNkNDU4MjktNDA4My00MzBlLTljMTktYmNkNjYyMDUyZDFk*rs_ga_5RMPXG14TE*MTY4MTg5MTIzMy41LjEuMTY4MTg5MTMwOC41Ny4wLjA"
    }

const options2 = {
  method: 'POST',
  url: '/platform/v1/userprofile',
  data: datas
};
let status;
await axios.request(options2).then(function (response2) {
 console.log("response",response2);
 status = true;
//  window.location.reload();
}).catch(function (error) {
    console.error("done2",error);
    status = false
});
  
return status;
}

export const createprofileSignUpNew = async(walletAddress, username) =>{

  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    let datas = 
    {
      "userName": username,
      "walletAddress": walletAddress,
      "coverImagePath": "https://gateway.pinata.cloud/ipfs/Qmdez1SZp2VvYfoJNVo6K8cNjer3HBUp51PivC7Cp/5dLBU?_gl=1*14oea56*_ga*NTA2MzM5MTg3LjE2OTAzNTIxNDU.*_ga_5RMPXG14TE*MTY5MDU0MzkyMC4zLjEuMTY5MDU0NDMxMC42MC4wLjA.",
      "profileImagePath": "https://gateway.pinata.cloud/ipfs/QmdLCS6mfnfxvhy9TKZ7h3MCNAJ6y3piz2M7MKMhp2zvej?_gl=1*1p8csnr*rs_ga*OWNkNDU4MjktNDA4My00MzBlLTljMTktYmNkNjYyMDUyZDFk*rs_ga_5RMPXG14TE*MTY4MTg5MTIzMy41LjEuMTY4MTg5MTMwOC41Ny4wLjA"
    }

const options2 = {
  method: 'POST',
  url: '/platform/v1/userprofile',
  data: datas
};
let status;
await axios.request(options2).then(function (response2) {
 console.log("response",response2);
 status = true;
//  window.location.reload();
}).catch(function (error) {
    console.error("done2",error);
    status = false
});
  
return status;
}

export const getuserDetailsbywallet = async(address) =>{
      try{
        let response2 = await fetch(`/platform/v1/userinfo/${address}`);
        const data2 = await response2.json();
        console.log("Api inside", data2)
        return {data2};
      }catch(err){
        console.log("vercelerrro",err)
        return false;
      }
       
}

export const getuserDetailsbywalletNew = async(address) =>{
  try{
    let response2 = await fetch(`/platform/v1/userprofile/${address}`);
    const data2 = await response2.json();
    console.log("Api inside", data2)
    return {data2};
  }catch(err){
    console.log("vercelerrro",err)
  }
   
}
export const getAlluserDetails = async() =>{
      let response2 = await fetch(`/platform/v1/userinfo`)
    //console.log(response2);
      const data2 = await response2.json();
    console.log("Api inside", data2)
    return {data2};
}

export const getAlluserDetailsNew = async() =>{
      let response2 = await fetch(`/platform/v1/userprofile`)
    //console.log(response2);
      const data2 = await response2.json();
    console.log("Api inside", data2)
    return {data2};
}

export const putbgImagebywallet = async(firstName, lastName, username, walletAddress, description, twitter, discord, coverImage, profileImage) =>{

  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  let datas = {
   "algoAddress": walletAddress,
   "accountType": "",
   "profileName": firstName, //first name
   "bio": lastName, //last name
   "email": description,
   "twitterName": twitter, //twitter
   "profileURL": username,
   "passwd": discord, // discord
   "bgvImagePath": coverImage,
   "profileImagePath": profileImage
  }
     const options2 = {
         method: 'PUT',
         url: '/platform/v1/userinfo',
         data: datas
       };
       let status;
       await axios.request(options2).then(function (response2) {
        console.log("response",response2);
         status = true;
       }).catch(function (error) {
           console.error("done2",error);
           status = false;
       });
       return status;
}

export const putbgImagebywalletNew = async(firstName, lastName, username, walletAddress, description, twitter, discord, youtube, website, coverImage, profileImage) =>{

  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  let datas = 
  {
    "firstName": firstName,
    "lastName": lastName,
    "userName": username,
    "walletAddress": walletAddress,
    "description": description,
    "twitter": twitter,
    "discord": discord,
    "youtube": youtube,
    "website": website,
    "coverImagePath": coverImage,
    "profileImagePath": profileImage
  }
     const options2 = {
         method: 'PUT',
         url: '/platform/v1/userprofile',
         data: datas
       };
       let status;
       await axios.request(options2).then(function (response2) {
        console.log("response",response2);
         status = true;
       }).catch(function (error) {
           console.error("done2",error);
           status = false;
       });
       return status;
}

//create events
export const createevents = async(name,descr,email,starttime,endtime,rewards,walletaddrr,imagepath,category) =>{

    let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    let datas = {
        "name": name,
        "description":descr,
        "email":email,
        "startTime":starttime,
        "endTime":endtime,
        "rewards":rewards,
        "walletAddress":walletaddrr,
        "imagePath":imagepath,
        "status":category,
        "approvalStatus":"No"
        }

const options2 = {
    method: 'POST',
    url: '/platform/v1/events',
    headers: {
      'x-api-key': `${key}`    
    },
    data: datas
  };
  
  axios.request(options2).then(function (response2) {
   console.log("response",response2);
  //  window.location.reload();
  }).catch(function (error) {
      console.error("done2",error);
  });
    
}

export const getEventbywallet = async(address) =>{
   
    let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
      //Get method start
      
        let response2 = await fetch(`/platform/v1/events/${address}`, 
      {
          headers: {
              'x-api-key': `${key}`    
            },
        }
        )
      //console.log(response2);
        const data2 = await response2.json();
      console.log("Api inside", data2)
      return {data2};
}

export const putEventStatus = async(keyid,status) =>{

    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
     let datas = {
        "keyId":keyid,
        "approvalStatus":"Yes"
    }
        const options2 = {
            method: 'PUT',
            url: '/platform/v1/events',
            data: datas
          };
          let value;
          let data;
          await axios.request(options2).then(function (response2) {
           console.log("response",response2);
           value = true;
           data = response2;
          //  window.location.reload();
          }).catch(function (error) {
              console.error("done2",error);
              value = false; 
              data = error;
          });
        return [value, data];
}

export const getAllEvents = async() =>{
   
    let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
      //Get method start
      
        let response2 = await fetch(`/platform/v1/events`, 
      {
          headers: {
              'x-api-key': `${key}`    
            },
        }
        )
      //console.log(response2);
        const data2 = await response2.json();
      console.log("Api inside", data2)
      return {data2};
}

//user visists
export const uservisist = async() =>{
   
    let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
      //Get method start
      
        let response2 = await fetch(`/platform/v1/visitinfo`, 
      {
          headers: {
              'x-api-key': `${key}`    
            },
        }
        )
      //console.log(response2);
        const data2 = await response2.json();
      console.log("Api inside", data2)
      return {data2};
    }
export const createUserVisits = async(algoaddre,networkType,wallettype,pagename) =>{

  const res = await fetch('https://geolocation-db.com/json/')
  // console.log("ipv4",await res.json())
  let k = (await res.json()).IPv4;
  console.log("ipv4",k)

    let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    let datas = {
        "ipAddress":(k).toString(),
        "algoAddress":algoaddre,
        "networkType":networkType,
        "walletType":wallettype,
        "pageName":pagename
        }

const options2 = {
    method: 'POST',
    url: '/platform/v1/visitinfo',
    headers: {
        'x-api-key': `${key}`    
    },
    data: datas
    };
    
    axios.request(options2).then(function (response2) {
    console.log("response",response2);
    //  window.location.reload();
    }).catch(function (error) {
        console.error("done2",error);
    });
    
}

//Activity table
export const createActivityTable = async(algoaddre,txntype,nftaddress,txhash,page) =>{

    let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    let datas = {
        "walletAddress": algoaddre,
        "txnType": txntype,
        "nftAddress": nftaddress,
        "txnHash": txhash,
        "txnPage": page
    }

const options2 = {
    method: 'POST',
    url: '/platform/v1/activity',
    headers: {
        'x-api-key': `${key}`    
    },
    data: datas
    };
    
    axios.request(options2).then(function (response2) {
    console.log("response",response2);
    //  window.location.reload();
    }).catch(function (error) {
        console.error("done2",error);
    });
    
}


export const getActivityByWallet = async(address) =>{
   
    let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
      //Get method start
      
        let response2 = await fetch(`/platform/v1/activity/${address}`, 
      {
          headers: {
              'x-api-key': `${key}`    
            },
        }
        )
      //console.log(response2);
        const data2 = await response2.json();
      console.log("Api inside", data2)
      return {data2};
}

//NFt details
export const createNFTDetails = async(address,nftaddress,tokenid,type,descr,name,socialLink,imagePath,nfttype,profileimage) =>{

    let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    let datas ={

        "creatorAddress":address,
        "nftAddress":nftaddress,
        "escrowAddress":tokenid,
        "nftType":type,
        "nftDescription":descr,
        "nftName":name,
        "nftPrice":"", 
        "socialLink":socialLink, 
        "ownerAddress":address,
        "previousAddress":address,
        "saleDetails":"no", 
        "valid":nfttype,
        "imagePath":imagePath,
        "userProfile":profileimage
     }

const options2 = {
    method: 'POST',
    url: '/platform/v1/nftdetails',
    headers: {
        'x-api-key': `${key}`    
    },
    data: datas
    };
    
    axios.request(options2).then(function (response2) {
    console.log("response",response2);
    //  window.location.reload();
    }).catch(function (error) {
        console.error("done2",error);
    });
    
}

export const getNFTDetailsByWallet = async(address) =>{
   
    let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
      //Get method start
      
        let response2 = await fetch(`/platform/v1/nftdetails/${address}`, 
      {
          headers: {
              'x-api-key': `${key}`    
            },
        }
        )
      //console.log(response2);
        const data2 = await response2.json();
      console.log("Api inside", data2)
      return {data2};
}

export const getNFTDetailsByTypeSale = async(type,sale) =>{
   
    let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
      //Get method start
     
        let response2 = await fetch(`/platform/v1/nftdetails/${type}/${sale}`, 
      {
          headers: {
              'x-api-key': `${key}`    
            },
        }
        )

      // let response2 = await fetch(`https://testapi1.stasisonline.in/platform/v1/nftdetails/${type}/${sale}`,{
      //   mode: 'no-cors',
      //   method: 'GET',
      //   headers:{
      //     'Access-Control-Allow-Origin': '*' ,
      //     'Content-Type':'application/json',
      //     'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE'
      //   }
      // })
      // console.log("response2",response2.Response);
        const data2 = await response2.json();
      console.log("response2 inside", data2)
      return {data2};
}
export const getNFTDetailsByTypeSaleBycategory = async(type,sale,category) =>{
   
  let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
    //Get method start
   
      let response2 = await fetch(`/platform/v1/nftsale/${type}/${sale}/${category}`, 
    {
        headers: {
            'x-api-key': `${key}`    
          },
      }
      )

    // let response2 = await fetch(`https://testapi1.stasisonline.in/platform/v1/nftdetails/${type}/${sale}`,{
    //   mode: 'no-cors',
    //   method: 'GET',
    //   headers:{
    //     'Access-Control-Allow-Origin': '*' ,
    //     'Content-Type':'application/json',
    //     'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE'
    //   }
    // })
    // console.log("response2",response2.Response);
      const data2 = await response2.json();
    console.log("response2 inside", data2)
    return {data2};
}

export const getNFTDetailsByAddressTypeSale = async(owner,type,sale) =>{
   
    let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
      //Get method start
      
        let response2 = await fetch(`/platform/v1/nftdetails/${owner}/${type}/${sale}`, 
        {
          headers: {
            'x-api-key': `${key}`    
          },
      })
      //console.log(response2);
        const data2 = await response2.json();
      console.log("Api inside", data2)
      return {data2};
}

export const putByPrice = async(price,keyid) =>{
   
    let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
     
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
     let datas = {
        "nftPrice":price, 
        "keyValue":keyid
        }
        const options2 = {
            method: 'PUT',
            url: '/platform/v1/nftdetails',
            headers: {
              'x-api-key': `${key}`    
            },
            data: datas
          };
          
          axios.request(options2).then(function (response2) {
           console.log("response",response2);
          //  window.location.reload();
          }).catch(function (error) {
              console.error("done2",error);
          });
}

export const putByEscrow = async(escrowadd,keyid) =>{
   
    let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
     
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
     let datas = {
        "escrowAddress":escrowadd,
        "keyValue":keyid       
        }
        const options2 = {
            method: 'PUT',
            url: '/platform/v1/nftescrow',
            headers: {
              'x-api-key': `${key}`    
            },
            data: datas
          };
          
          axios.request(options2).then(function (response2) {
           console.log("response",response2);
          //  window.location.reload();
          }).catch(function (error) {
              console.error("done2",error);
          });
}

export const putByOwner = async(owner,keyid) =>{
   
    let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
     
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
     let datas = {
        "ownerAddress":owner,
        "keyValue":keyid
        }
        const options2 = {
            method: 'PUT',
            url: '/platform/v1/nftowner',
            headers: {
              'x-api-key': `${key}`    
            },
            data: datas
          };
          
          axios.request(options2).then(function (response2) {
           console.log("response",response2);
          //  window.location.reload();
          }).catch(function (error) {
              console.error("done2",error);
          });
}

export const putBySale = async(sale,keyid) =>{

  let coverAndProfileImages = await getuserDetailsbywalletNew(localStorage.getItem("walletAddress"));
   
    let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
     
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
     let datas = {
        "saleDetails":sale, 
        "keyValue":keyid,
        "userProfile":coverAndProfileImages.data2[0].profileImagePath
        }
        const options2 = {
            method: 'PUT',
            url: '/platform/v1/nftsale',
            headers: {
              'x-api-key': `${key}`    
            },
            data: datas
          };
          
          axios.request(options2).then(function (response2) {
           console.log("response",response2);
          //  window.location.reload();
          }).catch(function (error) {
              console.error("done2",error);
          });
}

export const putByPreviousowner = async(previousAddress,keyid) =>{
   
    let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
     
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
     let datas = {
        "previousAddress":previousAddress,
        "keyValue":keyid
        }
        const options2 = {
            method: 'PUT',
            url: '/platform/v1/nftprevoius',
            headers: {
              'x-api-key': `${key}`    
            },
            data: datas
          };
          
          axios.request(options2).then(function (response2) {
           console.log("response",response2);
          //  window.location.reload();
          }).catch(function (error) {
              console.error("done2",error);
          });
}

//Land create
export const createLandDetails = async(name,descript,latit,longi,assetid,walletaddress,imagePath,owneraddress) =>{

  let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  let datas ={

    "name":name,
    "description":descript,
    "latitude":latit,
    "longitude":longi,
    "assetId":assetid,
    "walletAddress":walletaddress,
    "status":"no", 
    "price":"0", 
    "ownerAddress":owneraddress,
    "imagePath":imagePath
   }

const options2 = {
  method: 'POST',
  url: '/platform/v1/land',
  headers: {
      'x-api-key': `${key}`    
  },
  data: datas
  };
  
  axios.request(options2).then(function (response2) {
  console.log("response",response2);
  //  window.location.reload();
  }).catch(function (error) {
      console.error("done2",error);
  });
  
}


export const getallLand = async() =>{
   
  let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
    //Get method start
    
      let response2 = await fetch(`/platform/v1/land`, 
      {
        headers: {
          'x-api-key': `${key}`    
        },
    })
    //console.log(response2);
      const data2 = await response2.json();
    console.log("Api inside", data2)
    return {data2};
}

export const getallLandbywallet = async(walletAddress) =>{
   
  let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
    //Get method start
    
      let response2 = await fetch(`/platform/v1/land/${walletAddress}`, 
      {
        headers: {
          'x-api-key': `${key}`    
        },
    })
    //console.log(response2);
      const data2 = await response2.json();
    console.log("Api inside", data2)
    return {data2};
}

export const updateLandByPrice = async(price,assetid) =>{
   
  let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
   
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
   let datas = {
      "price":price, 
      "assetId":assetid
      }
      const options2 = {
          method: 'PUT',
          url: '/platform/v1/land',
          headers: {
            'x-api-key': `${key}`    
          },
          data: datas
        };
        
        axios.request(options2).then(function (response2) {
         console.log("response",response2);
        //  window.location.reload();
        }).catch(function (error) {
            console.error("done2",error);
        });
}

export const updateLandByOnwer = async(walletaddress,assetid) =>{
   
  let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
   
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
   let datas = {
    "walletAddress":walletaddress,
    "assetId":assetid
      }
      const options2 = {
          method: 'PUT',
          url: '/platform/v1/landowner',
          headers: {
            'x-api-key': `${key}`    
          },
          data: datas
        };
        
        axios.request(options2).then(function (response2) {
         console.log("response",response2);
        //  window.location.reload();
        }).catch(function (error) {
            console.error("done2",error);
        });
}

export const getallAvatar = async() =>{
   
  let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
    //Get method start
    
      let response2 = await fetch(`/platform/v1/avatar/allavatar/no/launchpad`, 
      {
        headers: {
          'x-api-key': `${key}`    
        },
    })
    //console.log(response2);
      const data2 = await response2.json();
    console.log("Api inside", data2)
    return {data2};
}
export const getallAvatarSale = async() =>{
   
  let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
    //Get method start
    
      let response2 = await fetch(`/platform/v1/avatar/allavatar/no/avatar`, 
      {
        headers: {
          'x-api-key': `${key}`    
        },
    })
    //console.log(response2);
      const data2 = await response2.json();
    console.log("Api inside", data2)
    return {data2};
}
export const getallLaunchpadYes = async() =>{
   
  let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
    //Get method start
    
      let response2 = await fetch(`/platform/v1/avatar/allavatar/yes/launchpad`, 
      {
        headers: {
          'x-api-key': `${key}`    
        },
    })
    //console.log(response2);
      const data2 = await response2.json();
    console.log("Api inside", data2)
    return {data2};
}
export const getallAvatarYes = async() =>{
   
  let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
    //Get method start
    
      let response2 = await fetch(`/platform/v1/avatar/allavatar/yes/avatar`, 
      {
        headers: {
          'x-api-key': `${key}`    
        },
    })
    //console.log(response2);
      const data2 = await response2.json();
    console.log("Api inside", data2)
    return {data2};
}

export const updateAvatarStatus = async(buyer,name) =>{
   
  let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
   
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
   let datas = {
      "buyerAddress":buyer,
      "status":"yes", 
      "createdTime":"30/3/23",
      "name":name
      }
      const options2 = {
          method: 'PUT',
          url: '/platform/v1/avatar',
          headers: {
            'x-api-key': `${key}`    
          },
          data: datas
        };
        
        axios.request(options2).then(function (response2) {
         console.log("response",response2);
        //  window.location.reload();
        }).catch(function (error) {
            console.error("done2",error);
        });
}

export const getallLandSale = async(sale) =>{
   
  let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
    //Get method start
    
      let response2 = await fetch(`/platform/v1/landauction/${sale}`, 
      {
        headers: {
          'x-api-key': `${key}`    
        },
    })
    //console.log(response2);
      const data2 = await response2.json();
    console.log("getallLandSale", data2)
    return {data2};
}

export const updateAuctionDetails = async(amount,id) =>{
   
  let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
   
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
   let datas = {
    "bidAmount":amount, 
    "assetId":id
      }
      const options2 = {
          method: 'PUT',
          url: '/platform/v1/landauction',
          headers: {
            'x-api-key': `${key}`    
          },
          data: datas
        };
        
        axios.request(options2).then(function (response2) {
         console.log("response",response2);
        //  window.location.reload();
        }).catch(function (error) {
            console.error("done2",error);
        });
}

export const getallNFt = async() =>{
   
  let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
    //Get method start
    
      let response2 = await fetch(`/platform/v1/nftdetails`, 
      {
        headers: {
          'x-api-key': `${key}`    
        },
    })
    //console.log(response2);
      const data2 = await response2.json();
    console.log("getallLandSale", data2)
    return {data2};
}

//create launchpad
export const createLaunchpad = async(name,descr,email,starttime,endtime,rewards,walletaddrr,imagepath,price) =>{

  let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  let datas = {
      "name": name,
      "description":descr,
      "email":email,
      "startTime":starttime,
      "endTime":endtime,
      "totalSale":rewards,
      "walletAddress":walletaddrr,
      "imagePath":imagepath,
      "price":price,
      "approvalStatus":"No"
      }

const options2 = {
  method: 'POST',
  url: '/platform/v1/launchpad',
  headers: {
    'x-api-key': `${key}`    
  },
  data: datas
};

axios.request(options2).then(function (response2) {
 console.log("response",response2);
//  window.location.reload();
}).catch(function (error) {
    console.error("done2",error);
});
  
}

//store nftlaunchpad transcation
export const recordTxnLaunchpad = async(name,phase,type,txhash,address,price,imgurl) =>{

  let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  let datas = {
    "name": name,
    "phase":phase,
    "typeOfNft":type,
    "txnHash":txhash,
    "buyerAddress":address,
    "price":price,
    "imageUrl":imgurl
      }

const options2 = {
  method: 'POST',
  url: '/platform/v1/nftlaunchpad',
  headers: {
    'x-api-key': `${key}`    
  },
  data: datas
};

axios.request(options2).then(function (response2) {
 console.log("response",response2);
//  window.location.reload();
}).catch(function (error) {
    console.error("done2",error);
});
  
}

export const getallrecordTxnLaunchpad = async() =>{
   
  let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
    //Get method start
    
      let response2 = await fetch(`/platform/v1/nftlaunchpad`, 
      {
        headers: {
          'x-api-key': `${key}`    
        },
    })
    //console.log(response2);
      const data2 = await response2.json();
    console.log("getallLandSale", data2)
    return {data2};
}

export const postipfs = async() =>{



//   let key = "u0bk8fvk1y-MXxvEmjXH8JXyy1AKIrlxrmOKmHLd9f4NI+0PTtxYOM=";
//   axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
//   axios.defaults.headers.common['Authorization'] = 'Bearer u0bk8fvk1y-MXxvEmjXH8JXyy1AKIrlxrmOKmHLd9f4NI+0PTtxYOM=';
//   // axios.defaults.headers.common['Content-Type'] = 'application/json';
  let datas = {
    name: 'IPFS API',
    membership_id: 'u0kvc23e5w',
    service: 'ipfs',
  };

// const options2 = {
//   method: 'POST',
//   url: '/api/v1/consortia/u0hbb8lzc8/environments/u0o0486ki4/services',
//   headers: {
//     'x-api-key': `${key}` ,
//     // 'Authorization':'Bearer u0bk8fvk1y-MXxvEmjXH8JXyy1AKIrlxrmOKmHLd9f4NI+0PTtxYOM='   
//   },
//   data: datas
// };

// axios.request(options2).then(function (response2) {
//  console.log("response",response2);
// //  window.location.reload();
// }).catch(function (error) {
//     console.error("done2",error);
// });
  

//neww
const resFile = await axios({
  method: "post",
  url: "api/v1/consortia/u0hbb8lzc8/environments/u0o0486ki4/services/",
  data: datas,
  headers: {
    'Authorization':'Bearer u0bk8fvk1y-MXxvEmjXH8JXyy1AKIrlxrmOKmHLd9f4NI+0PTtxYOM=',
      // 'pinata_api_key': "3c04667b948dfaba1be7",
      // 'pinata_secret_api_key': '62d496b53365b6e87d738a92605be1b39ee7cd936e84a9e0063b9df587003db2',                        
      "Content-Type": "application/json"
  },
});                
// const realipfsurl = `https://gateway.pinata.cloud/ipfs/${resFile.data.IpfsHash}`;                                  
console.log("Pinata updated",resFile)

}

export const SignUpApi = async(email, password, walletAddress, username) =>{

  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  let data = {
    "mailId": email,
    "passWord": password,
    "signinStatus": false,
    "walletAddress": walletAddress,
    "userName": username
  }

const options2 = {
  method: 'POST',
  url: '/platform/v1/sigup',
  data: data
};
let status;
await axios.request(options2).then(function (response2) {
  console.log("response",response2);
  status= true;
}).catch(function (error) {
    console.error("done2",error);
    status = false;
});
  return status;
}

export const getSignUp = async(emailId) =>{
      const url = `/platform/v1/sigup/${emailId}`;
      try {
        const response = await fetch(url);
    
        if (response.ok) {
          const data = await response.json();
          console.log('working:', data[0]);
          return [true, data[0]];
        } else {
          console.log('Error:', response);
          return [false, 'Error occurred while fetching data'];
        }
      } catch (error) {
          console.log('Error:', error);
          return [false, 'Error occurred while making the request'];
      }
}

export const SignInApi = async(emailId, password) =>{
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  let data = {
    "mailId": emailId,
    "passWord": password,
  }

const options2 = {
  method: 'POST',
  url: '/platform/v1/signincheck',
  data: data
};
let status;
await axios.request(options2).then(function (response2) {
  console.log("response",response2);
  status = response2;
}).catch(function (error) {
    console.error("done2",error);
    status = error;
});
  return status;
}

export const signStatus = async(emailId, signInStatus) =>{
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  let data = {
    "mailId": emailId,
    "signinStatus": signInStatus
  }

const options2 = {
  method: 'PUT',
  url: '/platform/v1/signin',
  data: data
};
let status;
await axios.request(options2).then(function (response2) {
  console.log("response",response2);
  status = response2;
}).catch(function (error) {
    console.error("done2",error);
    status = error;
});
  return status;
}

export const ResetPassword = async(emailId) =>{
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const options2 = {
  method: 'POST',
  url: `/platform/v1/resetpassword/${emailId}`,
};

let status;
await axios.request(options2).then(function (response2) {
  console.log("response",response2);
  status = true;
}).catch(function (error) {
    console.error("done2",error);
    status = false;
});
  return status;
}

export const ResetPasswordSubmitApi = async(emailId, otp, password) =>{
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  let data = {
    "mailId": emailId,
    "otp": otp,
    "passWord": password
  }

const options2 = {
  method: 'PUT',
  url: '/platform/v1/resetpassword',
  data: data
};
let status;
await axios.request(options2).then(function (response2) {
  console.log("response",response2);
  status = true;
}).catch(function (error) {
    console.error("done2",error);
    status = false;
});
  return status;
}

//launchpadtxn table
export const createLaunchpadTxnTable = async(txnTime,txnHash,senderAddress,amount,contractAddress,keyId) =>{

  let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  let datas = {
    "txnTime": txnTime,
    "txnHash": txnHash,
    "senderAddress": senderAddress,
    "amount": amount,
    "contractAddress": contractAddress,
    "keyId": keyId
  }

const options2 = {
  method: 'POST',
  url: '/platform/v1/launchpadtxn',
  headers: {
      'x-api-key': `${key}`    
  },
  data: datas
  };
  
  axios.request(options2).then(function (response2) {
  console.log("response",response2);
  //  window.location.reload();
  }).catch(function (error) {
      console.error("done2",error);
  });
  
}

export const getLanuchpadTxn = async(contract) =>{
  let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
    //Get method start
    
      let response2 = await fetch(`/platform/v1/launchpadtxn/${contract}`, 
    {
        headers: {
            'x-api-key': `${key}`    
          },
      }
      )
      const data2 = await response2.json();
    console.log("Api inside", data2)
    return {data2};
}



//nftlaunchpadtxn table
export const createNftLaunchpadTxnTable = async(txnTime,phase,txnHash,senderAddress,amount,contractAddress,keyId) =>{

  let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  let datas = {
      "txnTime": txnTime,
      "phase": phase,
      "txnHash": txnHash,
      "senderAddress": senderAddress,
      "amount": amount,
      "contractAddress": contractAddress,
      "keyId": keyId
  }

const options2 = {
  method: 'POST',
  url: '/platform/v1/launchpadnfttxn',
  headers: {
      'x-api-key': `${key}`    
  },
  data: datas
  };
  
  axios.request(options2).then(function (response2) {
  console.log("response",response2);
  //  window.location.reload();
  }).catch(function (error) {
      console.error("done2",error);
  });
  
}

export const getNFTLanuchpadTxn = async(contract,phase) =>{
  let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
    //Get method start
    
      let response2 = await fetch(`/platform/v1/launchpadnfttxn/${contract}/${phase}`, 
    {
        headers: {
            'x-api-key': `${key}`    
          },
      }
      )
      const data2 = await response2.json();
    console.log("Api inside", data2)
    return {data2};
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


export const getNFTLanuchpadTxnV2 = async (contract, phase) => {
  const apiKey = "DGZJ4YRDXJCJ4MDKBWR8MSRHZ3VC75DKYH";
  const baseScanUrl = "https://api.basescan.org/api";
  
  try {
    const response = await fetch(`${baseScanUrl}?module=account&action=tokennfttx&contractaddress=${contract}&page=1&offset=100&startblock=0&endblock=27025780&sort=desc&apikey=${apiKey}`);
    const data = await response.json();

    if (Array.isArray(data.result) && data.result.length > 0) {
      const transactions = await Promise.all(data.result.map(async (transaction) => {
        try {
          const txResponse = await fetch(`${baseScanUrl}?module=proxy&action=eth_getTransactionByHash&txhash=${transaction.hash}&apikey=${apiKey}`);
          const txData = await txResponse.json();
          transaction.gas = parseInt(txData.result?.value || "0", 16);
        } catch (error) {
          console.error(`Error fetching transaction data for hash ${transaction.hash}:`, error);
          transaction.gas = 0;
        }
        return transaction;
      }));

      data.result = transactions;
    } else {
      console.error("No valid transaction results found:", data.result);
    }

    return { data2: data };
  } catch (error) {
    console.error("Error fetching NFT Launchpad transactions:", error);
    return { data2: { result: [] } };
  }
};

export const getMyNFTLanuchpadTxnV2 = async (contract, phase, address) => {
  const apiKey = "DGZJ4YRDXJCJ4MDKBWR8MSRHZ3VC75DKYH";
  const baseScanUrl = "https://api.basescan.org/api";
  
  try {
    const response = await fetch(`${baseScanUrl}?module=account&action=tokennfttx&contractaddress=${contract}&address=${address}&page=1&offset=100&startblock=0&endblock=27025780&sort=desc&apikey=${apiKey}`);
    const data = await response.json();

    if (Array.isArray(data.result) && data.result.length > 0) {
      const transactions = await Promise.all(data.result.map(async (transaction) => {
        try {
          const txResponse = await fetch(`${baseScanUrl}?module=proxy&action=eth_getTransactionByHash&txhash=${transaction.hash}&apikey=${apiKey}`);
          const txData = await txResponse.json();
          transaction.gas = parseInt(txData.result?.value || "0", 16);
        } catch (error) {
          console.error(`Error fetching transaction data for hash ${transaction.hash}:`, error);
          transaction.gas = 0;
        }
        return transaction;
      }));

      data.result = transactions;
    } else {
      console.error("No valid transaction results found:", data.result);
    }

    return { data2: data };
  } catch (error) {
    console.error("Error fetching NFT Launchpad transactions:", error);
    return { data2: { result: [] } };
  }
};


export const getTradingHistory = async(nftAddress) =>{
  let key = "BvXlBA50Iw58XBSBZltS2H5P9IwS76f9hojA6aE5";
    //Get method start
    
      let response2 = await fetch(`/platform/v1/nftactivity/${nftAddress}`, 
    {
        headers: {
            'x-api-key': `${key}`    
          },
      }
      )
      const data2 = await response2.json();
    console.log("Api inside", data2)
    return {data2};
}