import { Button, Col, Container, Form, InputGroup, Row, Stack } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import LoginIcon from '../asserts/IMAGES/logo-icon.svg'

function Footer() {
    return (  
        <div className="footer">
            <Container>
                <Row>
                    <Col md={8} className="mb-4">
                        <Row>
                            <Col xs={4}>
                                <h4 className="mb-20 text-600">Explore</h4>

                                <ul className="b2 list-unstyled m-0 p-0">
                                    {/* <li><NavLink to="/avatar">Avatar</NavLink></li> */}
                                    <li><NavLink className="nav-link" to="/event" onClick={e => e.preventDefault()}>Premium Donation Event</NavLink></li>
                                    <li><NavLink className="nav-link" to="/map/explore" onClick={e => e.preventDefault()}>Map</NavLink></li>
                                    <li><NavLink className="nav-link" to="/market/explore" onClick={e => e.preventDefault()}>Marketplace</NavLink></li>
                                </ul>
                            </Col>
                            {/* <Col xs={4}>
                                <h4 className="mb-20 text-600">About</h4>

                                <ul className="b2 list-unstyled m-0 p-0">
                                    <li><NavLink to="#">Wiki</NavLink></li>
                                    <li><NavLink to="#">Our Team</NavLink></li>
                                    <li><NavLink to="#">Blog</NavLink></li>
                                </ul>
                            </Col>   */}
                            <Col xs={4}>
                                <h4 className="mb-20 text-600">Links</h4>

                                <ul className="b2 list-unstyled m-0 p-0">
                                    <li><NavLink className="nav-link" to="/tourism/explore" onClick={e => e.preventDefault()}>Virtual Tourism</NavLink></li>
                                    <li><NavLink className="nav-link" to="/launchpad" onClick={e => e.preventDefault()}>Fundraising</NavLink></li>
                                    <li><NavLink className="nav-link" to="/nft-launchpad">NFT Membership</NavLink></li>
                                    <li><NavLink className="nav-link" to="/stats" onClick={e => e.preventDefault()}>Stats</NavLink></li>
                                </ul>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4} className="mb-5">
                        <h4 className="mb-20 text-600">Join Our Community</h4>

                        {/* <Form className="mb-4">
                            <InputGroup>
                                <Form.Control
                                    placeholder="Email Address"
                                />
                                <Button variant="reset" id="button-addon2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                </svg>
                                </Button>
                            </InputGroup>
                        </Form> */}

                        <Stack gap={4} direction="horizontal">
                            {/* <a href="#" target="_blank" rel="noreferrer" className="facebbok social-icon"></a> */}
                            <a href="https://twitter.com/DivineDimeVerse" target="_blank" rel="noreferrer" className="twitter social-icon"></a>
                            <a href="https://www.instagram.com/divinedimeverse/" target="_blank" rel="noreferrer" className="instagram social-icon"></a>
                            <a href="https://www.youtube.com/channel/UCGmSvLWGKiFvzjZHBDG686Q" target="_blank" rel="noreferrer" className="youtube social-icon"></a>
                            <a href="https://discord.com/invite/2EFMpZCZ2k" target="_blank" rel="noreferrer" className="discord social-icon"></a>
                            <a href="https://medium.com/@DivineDimension" target="_blank" rel="noreferrer" className="medium social-icon"></a>
                        </Stack >

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="d-flex justify-content-center align-items-center text-14">
                            <img src={LoginIcon} className="me-md-3 me-2" alt="logo" />
                            <p className="text-block text-500">Copyright @ {new Date().getFullYear()} Divine Dimension</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Footer;