import { Link, NavLink } from 'react-router-dom';
import Banner from '../../asserts/IMAGES/nft-banner-image.png'
import BannerBG from '../../asserts/IMAGES/nft-banner-bg.png'
import RocketIcon from '../../asserts/IMAGES/rocket-icon.svg'
import { Col, Nav, Row } from 'react-bootstrap';

import { CardViewHorizontal } from '../../Snippets/CardView';
import { useEffect, useState } from 'react';
// import  {abi1,tokenaddress1De} from './NFTLaunchpadcontracts';
// import  {nftLaunchpadABI as abi1} from '../../abi';
import  {tokenaddress1Ca} from '../../address';
import { useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { ethers, ContractFactory  } from 'ethers';
import web3Instance from '../../web3Inastance';
import { CircularProgress } from '@mui/material';
import { web3 } from 'web3';
// import { abiLaunchpad, abierc20 } from './LaunchpadContract';
import CardImageH from '../../asserts/IMAGES/card-bg-h.png';
import CulturalCompleted1 from '../../asserts/IMAGES/CulturalCompleted1.png';
import CulturalCompleted2 from '../../asserts/IMAGES/CulturalCompleted2.png';
import CulturalCompleted3 from '../../asserts/IMAGES/CulturalCompleted3.png';
import DivineLive1 from '../../asserts/IMAGES/DivineLive1.png';
import DivineLive2 from '../../asserts/IMAGES/DivineLive2.png';
import DivineLive3 from '../../asserts/IMAGES/DivineLive3.png';
import WorldUpcoming1  from '../../asserts/IMAGES/WorldUpcoming1.png';
import WorldUpcoming2  from '../../asserts/IMAGES/WorldUpcoming2.png';
import WorldUpcoming3  from '../../asserts/IMAGES/WorldUpcoming3.png';
import { divineLive1 as DivineLive11 } from '../../address';
import { abiLaunchpad as abi1, abierc20 } from '../../abi';

function Launchpad({web3State}) {
    const [num, setNum] = useState(2);
    const [clicked, setClicked] = useState(0);
    const [time1, setTime1] = useState("Click here");
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const [endtime, setEndtime] = useState();


      function formatDate(inputDate) {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const date = new Date(inputDate);
        return date.toLocaleString('en-GB', options);
      }

      const updateCurrentTime = (epochtime) => {
        const now = new Date(epochtime * 1000);
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
  
        const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;   
        setEndtime(formattedTime);
        console.log("time", formattedTime, "||", endtime);
      };

    const fun = async() => {
        if(isConnected){
            const web3 = web3Instance;
        if (!web3) return;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const NFTLaunchpadcontract = new ethers.Contract(DivineLive11, abi1, signer);
        const endTime = web3.utils.toNumber((await NFTLaunchpadcontract.end_time())._hex);
        updateCurrentTime(endTime)
        }  
        else{
            console.log("skip")
        }   
    }

    useEffect(() => {
        fun();
    }, [address, isConnected])


    return ( 
        <>
            <div className="mb-20 d-flex d-md-none align-items-center justify-content-between">
                <h1 className="text-600 mb-0">NFT Membership</h1>
            </div>

            <div className="mb-lg-5 mb-4 mt-4 page-banner text-white page-banner-dark d-flex px-100" style={{backgroundColor: 'rgba(4, 11, 32, 1)'}}>
                <img src={BannerBG} className='page-banner-bg' alt="image" /> 
                <img src={Banner} className='page-banner-image-sm-expand' alt="image" />

                <div className='my-5 my-sm-auto text-sm-start text-center pb-sm-0 pb-4 w-100'>
                    <Row>
                        <Col xs={12} md={8} xl={7} sm={10}>
                            <h1 className='text-500'>Empower Dreams: Join Our Fundraising Campaign!</h1>
                            <p>Together, let's turn aspirations into reality. Explore our impactful fundraising campaign and be a catalyst for positive change. Your support matters.</p>

                            <Link to="/market/explore" className='btn btn-small mb-2 b1 btn-outline-white me-md-3 me-2 d-inline-flex align-items-center justify-content-center'><img className='me-2' src={RocketIcon} alt="RocketIcon" /> Explore more</Link>
                            <Link to="/launchpad/create" className='btn btn-small mb-2 b1 btn-white'>Apply for Fundraising</Link>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="d-flex mb-40 overflow-auto">
                <div className="inner-links flex-nowrap text-nowrap navbar-nav d-flex flex-row">
                    <Nav.Link className="nav-link" to="/launchpad" onClick={() => {setNum(1);
                    setClicked(1);}} style={{
                        background: clicked == 1 ? 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2)' : 'transparent',
                      }}>Completed</Nav.Link>
                    <Nav.Link className="nav-link ms-md-4" to="#Live" onClick={() => {setNum(2);
                    setClicked(2);}} style={{
                        background: clicked == 2 ? 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2)' : 'transparent',
                      }}>Live</Nav.Link>
                    <Nav.Link className='ms-md-4' to="#" onClick={() => {setNum(3);
                    setClicked(3);}} style={{
                        background: clicked == 3 ? 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2)' : 'transparent',
                      }}>Upcoming</Nav.Link>
                </div>
            </div>

            <div className='overflow-xl-hidden'>
                <h2 className='h1 mb-20'>Fundraising events</h2>
            {(num == 1) ? <><Row className='gx-2 gx-md-3' xs={1} sm={2} md={3} lg={2} xl={3}>
            {!endtime ? <>
                    <center><CircularProgress color="inherit" /></center>
                </> : <>
                    {/* <Col className='mb-md-3 mb-2'>
                        <CardViewHorizontal size='sm' image={CulturalCompleted1} number="5" contract="0xc0C8fD94c1666dF665142F253724FDF352b4C2E2" description="Native launchpad of DivineDimension platform" web3State = {web3State}/>
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewHorizontal size='sm' image={CulturalCompleted2} number="5" contract="0xc0C8fD94c1666dF665142F253724FDF352b4C2E2" description="Native launchpad of DivineDimension platform" web3State = {web3State}/>
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewHorizontal size='sm' image={CulturalCompleted3} number="5" contract="0xc0C8fD94c1666dF665142F253724FDF352b4C2E2" description="Native launchpad of DivineDimension platform" web3State = {web3State}/>
                    </Col> */}
                    </>}
                </Row></> : ((num == 2)?<>
                <Row className='gx-2 gx-md-3' xs={1} sm={2} md={3} lg={2} xl={3}>
                {!endtime ? <>
                    <center><CircularProgress color="inherit" /></center>
                </> : <>
                    <Col className='mb-md-3 mb-2'>
                    {/* 0x68c9E2a39a40be9B5CB862446A2F9d715Cb320B6 */}
                        {/* <CardViewHorizontal size='sm' image={DivineLive1} number="1" contract="0x042bE4BC61B5d9c53a4c6Fd197903E4F2e8902D0" description="Native launchpad of DivineDimension platform" web3State = {web3State}/> */}
                        <CardViewHorizontal size='sm' image={DivineLive1} number="1" contract={DivineLive11} description="Native launchpad of DivineDimension platform" web3State = {web3State}/> 
                    </Col>
                    {/* <Col className='mb-md-3 mb-2'>
                        <CardViewHorizontal size='sm' image={DivineLive2} number="2" contract="0x026Fd752101d3e93d2b5C092C1cc17f3E849764F" description="Native launchpad of DivineDimension platform" web3State = {web3State}/>
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewHorizontal size='sm' image={DivineLive3} number="3" contract="0xD7f448a4BCd718e48CC1343749F1Ba820710F27d" description="Native launchpad of DivineDimension platform" web3State = {web3State}/>
                    </Col> */}
                    </>}
                </Row>
                </>:<><Row className='gx-2 gx-md-3' xs={1} sm={2} md={3} lg={2} xl={3}>
                {!endtime ? <>
                    <center><CircularProgress color="inherit" /></center>
                </> : <>
                    {/* <Col className='mb-md-3 mb-2'>
                        <CardViewHorizontal size='sm' image={WorldUpcoming1} number="4" contract="0x1E3D21EB95f4D2B05ecE5e37debb56bc11b330C3" description="Native launchpad of DivineDimension platform" web3State = {web3State}/>
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewHorizontal size='sm' image={WorldUpcoming2} number="4" contract="0x1E3D21EB95f4D2B05ecE5e37debb56bc11b330C3" description="Native launchpad of DivineDimension platform" web3State = {web3State}/>
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewHorizontal size='sm' image={WorldUpcoming3} number="4" contract="0x1E3D21EB95f4D2B05ecE5e37debb56bc11b330C3" description="Native launchpad of DivineDimension platform" web3State = {web3State}/>
                    </Col> */}
                    </>}
                </Row></>)}
                
            </div>
        </>
     );
}

export default Launchpad;