import { Button, Card, Col, Dropdown, Row, Stack, Table } from "react-bootstrap";
import Down from '../../../asserts/IMAGES/down-danger.svg'
import Link from '../../../asserts/IMAGES/external-link.svg'
import Bar from "./BarChart";
import { useState,useEffect } from "react";
import { getTradingHistory } from "../../../awsdatafile";
import { getTradingHistoryFirebase } from "../../../FirebaseFunctions";


export function Properties() {
    return(
        <div className="border py-md-3 py-2 px-md-4 px-2">
            <Row className="g-md-3 g-2" xs={1} sm={2} lg={4}>
                <Col>
                    <div className="border p-3">
                        <p className="text-500 mb-2 text-13">Tower</p>
                        <h6 className="b2 text-black d-flex mb-0 justify-content-between lh-1"><span>dream spire tower</span> <span>45.00%</span></h6>
                    </div>
                </Col>
                <Col>
                    <div className="border p-3">
                        <p className="text-500 mb-2 text-13">Condo id</p>
                        <h6 className="b2 text-black d-flex mb-0 justify-content-between lh-1"><span>dst0130</span> <span>0.05%</span></h6>
                    </div>
                </Col>
                <Col>
                    <div className="border p-3">
                        <p className="text-500 mb-2 text-13">Condo no</p>
                        <h6 className="b2 text-black d-flex mb-0 justify-content-between lh-1"><span>dst3302</span> <span>0.05%</span></h6>
                    </div>
                </Col>
                <Col>
                    <div className="border p-3">
                        <p className="text-500 mb-2 text-13">Floor no</p>
                        <h6 className="b2 text-black d-flex mb-0 justify-content-between lh-1"><span>33</span> <span>0.54%</span></h6>
                    </div>
                </Col>
                <Col>
                    <div className="border p-3">
                        <p className="text-500 mb-2 text-13">Trait Count</p>
                        <h6 className="b2 text-black d-flex mb-0 justify-content-between lh-1"><span>4</span> <span>100.00%</span></h6>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export function PriceHistory() {
    return(
        <div className="border py-md-3 py-2 px-md-4 px-2">
            <div className="mb-3 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <h4 className="mb-0 text-600 me-2">Price History</h4>
                    <span className="b2 d-flex text-red"><img src={Down} className="me-2 ms-3" alt="Down" /> Last 30d</span>
                </div>
                <Stack gap={3} direction="horizontal">
                    <Button varient="reset" className="text-black bg-transparent b2 border-0 p-0">7d</Button>
                    <Button varient="reset" className="text-gray bg-transparent b2 border-0 p-0">30d</Button>
                    <Button varient="reset" className="text-gray bg-transparent b2 border-0 p-0">All</Button>
                </Stack>
            </div>
            {/* /.mb-3 */}

            <Bar />
        </div>
    )
}

export function TradingHistory({nftAddress, effectControl}) {
    const[TradingHistory,setgetTradingHistory]=useState([]);  
    const[pageSize,setPageSize]=useState(12);
    const dbcallTradingHistory=async()=>{        
        if(nftAddress  === null || nftAddress === "" || nftAddress === " " || nftAddress=== undefined || nftAddress=== ''){
        }
        else{              
        // let TradingHistoryObj = await getTradingHistory(nftAddress)   ;
        // setgetTradingHistory(TradingHistoryObj.data2);
        // console.log(nftAddress+" "+TradingHistoryObj.data2)
        let TradingHistoryObj = await getTradingHistoryFirebase(nftAddress)   ;
        setgetTradingHistory(TradingHistoryObj);
        console.log(nftAddress+" "+TradingHistoryObj)
    }        
}      
useEffect(()=>{
    dbcallTradingHistory()
}, [nftAddress,effectControl]);
    return(
        <Card className='mb-3'>
           
            {/* <Card.Body className="d-flex">
                <Dropdown>
                    <Dropdown.Toggle variant="white" className="btn-link bg-transparent p-0 border-0" id="dropdown-basic">
                        All Types
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="p-3 dropdown-filter">
                        <div className='filter-sidebar'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="flexCheckChristians" />
                                <label className="form-check-label" htmlFor="flexCheckChristians">
                                    All <span>(4200)</span>
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="flexCheckHindu" />
                                <label className="form-check-label" htmlFor="flexCheckHindu">
                                Hindu <span>(2000)</span>
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="flexCheckIslam" />
                                <label className="form-check-label" htmlFor="flexCheckIslam">
                                Islam <span>(400)</span>
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="flexCheckShinto" />
                                <label className="form-check-label" htmlFor="flexCheckShinto">
                                Christians <span>(200)</span>
                                </label>
                            </div>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>             
            </Card.Body> */}
            
    {TradingHistory === null || TradingHistory === "" || TradingHistory === undefined || TradingHistory[0] === null || TradingHistory[0] === "" || TradingHistory[0] === undefined ?
     ( 
                 
        <div className="text-center bg">
        <h2>No Trading Yet</h2>                            
        </div>
                  
    ):
    (<>  <Table striped responsive bordered={false} hover className="mb-0">
        <thead>
            <tr>
                <th>Key Id</th>
                <th>Wallet Address</th>
                <th>Txn Type</th>
                <th>Txn Time</th>
                <th>Txn Hash</th>
            </tr>
        </thead>
        { TradingHistory.map((x,index)=>{
           if(index<pageSize)                
            return(  
        
               <tbody>
                        <tr>
                            {/* <td>{x.keyID==="" || x.keyID=== undefined || x.keyID=== null? "0":x.keyID}</td> */}
                            <td>{index? "0":(index+1)}</td>
                            <td>{x.walletAddress==="" || x.walletAddress=== undefined|| x.walletAddress=== null? "0x0000000000000...":x.walletAddress}</td>
                            <td>{x.txnType===""||x.txnType===undefined||x.txnType===null?"-":x.txnType}</td>
                            <td>{x.txnTime===""||x.txnTime===undefined||x.txnTime===null? "YYYY-MM-DD 00:00:00":x.txnTime}</td>
                            <td>
                               <a href={`https://basescan.org/tx/${x.txnHash}`} target="_blank"><img src={Link} className="d-block" alt="link" /></a>
                            </td>
                       </tr>  
            </tbody> 
                                          
                 )
        })}  </Table>  
        </>
        )}
             
        </Card>
    )
}
