import { Link, NavLink } from 'react-router-dom';
import Banner from '../../asserts/IMAGES/nft-banner-image.png'
import BannerBG from '../../asserts/IMAGES/nft-banner-bg.png'
import RocketIcon from '../../asserts/IMAGES/rocket-icon.svg'
import { Col, Nav, Row } from 'react-bootstrap';

import { CardViewHorizontal, CardViewInfoDE, CardViewInfoCA, CardViewInfoWV } from '../../Snippets/CardView';

function NFTDivineExplorer() {
    return ( 
        <>
            <div className="mb-20 d-flex d-md-none align-items-center justify-content-between">
                <h1 className="text-600 mb-0">NFT Membership</h1>
            </div>

            {/* <div className="d-flex overflow-auto">
                <div className="inner-links flex-nowrap text-nowrap navbar-nav d-flex flex-row">
                    <NavLink className="nav-link" to="/nft-launchpad/single">Divine Explorer NFT</NavLink>
                    <Nav.Link className="nav-link" to="#Live">Cultural Adventurer</Nav.Link>
                    <Nav.Link className='nav-link' to="#">World voyager NFT</Nav.Link>
                </div>
            </div> */}

            <div className="mb-lg-5 mb-4 mt-4 page-banner text-white page-banner-dark d-flex px-100" style={{backgroundColor: 'rgba(4, 11, 32, 1)'}}>
                <img src={BannerBG} className='page-banner-bg' alt="image" /> 
                <img src={Banner} className='page-banner-image-sm-expand' alt="image" />

                <div className='my-5 my-sm-auto text-sm-start text-center pb-sm-0 pb-4 w-100'>
                    <Row>
                        <Col xs={12} md={8} xl={7} sm={10}>
                            <h1 className='text-500'>Sacred Access: Unveiling the Power of Divine POD Membership NFTs!</h1>
                            <p>Unlock the Infinite: Immerse in Divine Dimension with Our Trio of NFT Membership Passes, Opening Portals to Your Desired Destinations within the Enchanting D-Pod!</p>

                            <Link to="/market/explore" className='btn btn-small mb-2 b1 btn-outline-white me-md-3 me-2 d-inline-flex align-items-center justify-content-center' onClick={e => e.preventDefault()}><img className='me-2' src={RocketIcon} alt="RocketIcon" /> Explore more</Link>
                            {/* <Link to="#" className='btn btn-small mb-2 b1 btn-white'>Go to Fundraising</Link> */}
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='overflow-xl-hidden'>
                <h2 className='h1 mb-20'>Seed Round</h2>
                
                <Row className='gx-2 gx-md-3' xs={1} sm={2} md={3}>
                    <Col className='mb-3'>
                        <CardViewInfoDE />
                    </Col>
                    <Col className='mb-3'>
                        <CardViewInfoCA />
                    </Col>
                    <Col className='mb-3'>
                        <CardViewInfoWV />
                    </Col>
                </Row>
            </div>
        </>
     );
}

export default NFTDivineExplorer;