import { Web3 } from 'web3';
// import { abierc20 } from './pages/Launchpad/LaunchpadContract';
import { abierc20 } from './abi';
import { useWeb3Modal } from '@web3modal/ethers5/react';
import { dimeContract as contracterc20 } from './address';
import { ethers } from 'ethers';

export const config = {
  chainId: 8453,
  chainIdHex: '0x2105',
  name: 'Base',
  currency: 'ETH',
  explorerUrl: 'https://basescan.org/',
  rpcUrl: 'https://base-rpc.publicnode.com/'
}
const API_KEY ="DGZJ4YRDXJCJ4MDKBWR8MSRHZ3VC75DKYH"
const Network_Name ="Base"
// export const provider = ethers.getDefaultProvider(Network_Name, {
//   etherscan: API_KEY});
export const provider = ethers.getDefaultProvider('https://base-rpc.publicnode.com/');



const connectToEthereum = async () => {
    try {
      if (window.ethereum) {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const web3 = new Web3(window.ethereum);
        return web3;
      } else {
        throw new Error('No Ethereum wallet found.');
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  };

export const dimeWalletBalance = async() => {
    const web3 = await connectToEthereum();
    if (!web3) return web3;
    const erc20token = new web3.eth.Contract(abierc20, contracterc20);
    if(localStorage.getItem("walletAddress")!== null && localStorage.getItem("walletAddress") !== "" ){
        let userBalance = web3.utils.toNumber(await erc20token.methods.balanceOf(localStorage.getItem("walletAddress")).call())
        return userBalance;
    }
    else{
        return 0;
    }
}

export const ethWalletBalance = async() => {
  const web3 = new Web3(window.ethereum);
  if(localStorage.getItem("walletAddress") && localStorage.getItem("walletAddress")!== null && localStorage.getItem("walletAddress") !== "" ){
  const balanceWei = await web3.eth.getBalance(localStorage.getItem("walletAddress"));
  const balanceEther = web3.utils.fromWei(balanceWei, "ether");
  let ethBalance = parseFloat(balanceEther).toFixed(5);
  return ethBalance;
  }
  else
  {
    return 0;
  }
}

export const ConnectWalletCommon = async () => {
  const { open } = useWeb3Modal();
  await open();
};

export const ChangeNetwork = async (walletProvider) => {
  try {
    await walletProvider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: config.chainIdHex }],
    });
  } catch (switchError) {
    if (switchError.code === config.chainId) {
      try {
        await walletProvider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: config.chainIdHex,
              chainName: config.chainName,
              rpcUrls: [config.rpcUrl],
              blockExplorerUrls: [config.explorerUrl],
            },
          ],
        });
      } catch (addError) {
        throw addError;
      }
    }
  }
};