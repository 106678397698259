import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Button, ButtonGroup, Card, Col, Dropdown, Nav, Row, Stack, Tab, Tabs, Toast, Modal } from 'react-bootstrap';
import ShopImage from '../../asserts/IMAGES/shope-image.png'
import Loader from '../../asserts/IMAGES/loader-icon.svg'
import Refresh from '../../asserts/IMAGES/refresh-icon.svg'
import Verified from '../../asserts/IMAGES/verified-icon.svg'
import CopyIcon from '../../asserts/IMAGES/copy-icon.svg'
import RoyaltiesIcon from '../../asserts/IMAGES/royalties-icon.svg'
import Ethereum from '../../asserts/IMAGES/ethereum-filled.svg.svg'
import Solana from '../../asserts/IMAGES/solana-filled.svg.svg'
import Credit from '../../asserts/IMAGES/credit-card-filled.svg.svg'
import WinterLogo from '../../asserts/IMAGES/winter-logo.svg'
import { PriceHistory, Properties, TradingHistory } from './Components/TabsContent';
import { CardViewShop } from '../../Snippets/CardView';

import { Navigation, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/scss';
import 'swiper/scss/navigation';

import {  Zoom, toast, ToastContainer} from 'react-toastify';
import '../../toast-style-override.css';

import { useEffect } from 'react';
import { getNFTDetailsByTypeSale, putByOwner, putByPrice, putBySale } from '../../awsdatafile';

// import {abibytecode} from "../abi/datasbytecodeNormalNFT";
// import { abi } from '../abi/Normalnftcontract';
// import {abibytecodeauction} from '../abi/datasbytecodeAuctionNFT';
// import {abiauction} from '../abi/Auctionnftcontract';
// import {abibytecoderoyalty} from '../abi/datasbytecodeRoyaltyNFT';
// import {abiroylaty} from '../abi/Royaltynftcontract';
import { abibytecoderoyalty, abibytecode, abibytecodeauction} from '../../abibytecode';
import { abiroylaty, abinormal as abi, abiauction} from '../../abi';
import Web3 from 'web3';
import { useNavigate } from 'react-router-dom';
import { ClockLoader } from 'react-spinners';
import {Ethereum as ConnectPopup} from '../../component/tabs/TabContent';
import { ethers, ContractFactory  } from 'ethers';
import bigInt from 'big-integer';
import web3Instance from '../../web3Inastance';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';
import { getNFTDetailsByTypeSaleFirebase, putByOwnerFirebase, putBySaleFirebase, putBypriceFirebase } from '../../FirebaseFunctions';
import { ChangeNetwork, ConnectWalletCommon as ConnectWallet } from '../../GeneralFunctions';

function MarketExploreCart({web3State}) {
    const location = useLocation();
    let navigate = useNavigate();

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();

    const dataParam = new URLSearchParams(location.search).get('id');
    const dataObject = dataParam ? JSON.parse(decodeURIComponent(dataParam)) : null;
    console.log("data",dataObject)
    const [like, setLike] = useState(false);
    const [show, setShow] = useState(false);
    const[loader, setLoader] = useState(false);
    const handleShowLoad = () => setLoader(true);
    const handleHideLoad = () => setLoader(false);
    const [checkAccount, setCheckAccount] = useState(null);
    const [transChange, setTransChange] = useState(false);
    const [ispopup, setIspopup] = useState(false);
    const handleClose = () => setIspopup(false); 

    const [saleImage, setsaleImage] = useState([""]);

    const getNftImage = async() =>{
        let SaleImage =[];
        if(dataObject.nftType === "Royalty"){
            //  SaleImage = await getNFTDetailsByTypeSale("Royalty","yes")
            SaleImage = await getNFTDetailsByTypeSaleFirebase("Royalty","yes")
        }
        else if(dataObject.nftType === "Normal"){
            // SaleImage = await getNFTDetailsByTypeSale("Normal","yes")
            SaleImage = await getNFTDetailsByTypeSaleFirebase("Normal","yes")
        }
        else{
            // SaleImage = await getNFTDetailsByTypeSale("Auction","yes")
            SaleImage = await getNFTDetailsByTypeSaleFirebase("Auction","yes")
        }
        
        // setsaleImage(SaleImage.data2)
        setsaleImage(SaleImage)

    }
    console.log("saleimgae",saleImage)
    useEffect(()=>{
        getNftImage();
        if(address !== "" && address !== undefined && address !== null){
            setCheckAccount(true);
        }
        else{
            setCheckAccount(false);
        }
    },[address,isConnected]) 

    const buyNow = async() =>{
       
        handleShowLoad()
        if(address === null || address === "0x" || address === undefined || address === ''){
            toast.warning(`please connect wallet`,{autoClose:5000})
            handleHideLoad()            
        }
        else if(dataObject.creatorAddress === address)
        {   
            toast.warning(`please connect Another wallet`,{autoClose:5000})
            handleHideLoad()            
        } 
        else{            
            //if(parseInt(assetbalanceApp) === 1 || parseInt(assetbalanceApp) === "1"){                                
            handleShowLoad()  
            toast.dismiss();            
            toast.info("NFT Purchase InProgress",{autoClose: 5000});  
            let AssId = dataObject.escrowAddress
            let PriceId =parseFloat(dataObject.nftPrice)
           
            const web3 = web3Instance;
            if (!web3) return;
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner(); 
            const account = address;

            let abicode =[];
            if(dataObject.nftType === "Normal"){
                abicode = abi
                
            }else if (dataObject.nftType === "Royalty"){
                abicode = abiroylaty
            }else{
                abicode = abiauction
            }
            const contract = new ethers.Contract(dataObject.nftAddress,abicode,signer);
            const bigNumber =  web3.utils.toBigInt(dataObject.nftPrice);
            try{

                // await contract.methods.setTokenState(dataObject.escrowAddress,true).send({
                //     from:localStorage.getItem('walletAddress'),
                //     gas: 210000,
                //     //gasPrice: '20000000000'
                //   });  
            
                let buyReceipt = await contract.buyThing(AssId,{value: bigNumber, gasLimit: 210000});
                let bres = await buyReceipt.wait();
                // .send({
                //     from:localStorage.getItem('walletAddress'), 
                //     value: bigNumber,
                //     gas: 210000
                // });
                // await contract.methods.approve(dataObject.nftAddress,dataObject.escrowAddress).send({
                //     from:localStorage.getItem('walletAddress'),
                //     gas: 210000,
                //     //gasPrice: '20000000000'
                // }) 
                // await putByPrice(dataObject.nftPrice,dataObject.keyValue);
                await putBypriceFirebase(dataObject.nftPrice,dataObject.id);
                console.log("datacheck",dataObject.nftPrice,dataObject.id);
                // await putByOwner(localStorage.getItem('walletAddress'),dataObject.keyValue);
                await putByOwnerFirebase(address,dataObject.id);
                // await putBySale("no",dataObject.keyValue);
                await putBySaleFirebase("no",dataObject.id, address);
                toast.success(`NFT Purchased Successfully`,{autoClose: 8000});     
                setTransChange(!transChange);                       
                handleHideLoad();
                navigate('/profile/nfts');
            }catch(error){                    
                console.error(error);
                toast.warning(`you are facing error `,{autoClose:5000});
                handleHideLoad();
                //done2()         
            }
           
        } 
    }

    // const connectToEthereum = async () => {
    //     try {
    //       if (window.ethereum) {
    //         await window.ethereum.request({ method: 'eth_requestAccounts' });
    //         const web3 = new Web3(window.ethereum);
    //         if(localStorage.getItem("walletAddress") !== "" && localStorage.getItem("walletAddress") !== undefined && localStorage.getItem("walletAddress") !== null){
    //             setCheckAccount(true);
    //         }
    //         else{
    //             setCheckAccount(false);
    //         }
    //         return web3;
    //       } else {
    //         throw new Error('No Ethereum wallet found.');
    //       }
    //     } catch (error) {
    //       console.error(error);
    //       setCheckAccount(false);
    //       return null;
    //     }
    //   };
      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const refresh = () => {
        window.location.reload();
    }

    return ( 
        <>
         <ToastContainer position='bottom-right' draggable = {false} transition={Zoom} autoClose={4000} closeOnClick = {false}/>
            {/* <ToastContainer position="bottom-end" className="p-3" style={{ zIndex: 1 }}>
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                    <Toast.Body className='b2 px-3 text-gray'>Copied Succesfully!</Toast.Body>
                </Toast>
            </ToastContainer> */}
            <div className="d-flex overflow-auto">
                <div className="inner-links flex-nowrap text-nowrap navbar-nav d-flex flex-row">
                <NavLink className="nav-link" to="/market/explore">Explore</NavLink>
                    <NavLink className="nav-link" to="/market/create">Create</NavLink>
                    <NavLink className="nav-link" to="/market/explore/single">Trending Collection</NavLink>
                   
                    <NavLink className="nav-link" to="/market/explore/shop/heritage">Heritage</NavLink>
                    <NavLink className="nav-link" to="/market/explore/shop/royalty">Artifact</NavLink>
                  
                    <NavLink className="nav-link" to="/market/explore/shop/auction">Auction</NavLink>
                </div>
            </div>

            <div className="mb-lg-5 mb-4 mt-md-1 mt-4">
                <div className='d-md-none'>
                    <p className='b2 mb-2 text-black'>Divine Dimension 
                     {/* <span className='ps-2'>Condos</span>  */}
                     <img src={Verified} className='ms-1' alt="Verified" /></p>

                    <h1 className='text-36 text-black text-600'>Divine Dimension Spire <br />Condo - DST0130</h1>

                    <p className='b2 mb-20'>Owned by <span className='ms-1 text-block'>addr1..tarx6</span></p>
                </div>
                <div className='d-flex justify-content-end'>
                    <ButtonGroup aria-label="First group">
                        {/* <Button onClick={() => setLike(!like)} variant="white" className='d-flex align-items-center'>
                            {like ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="me-2" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                </svg>
                            ):(
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="me-2" viewBox="0 0 16 16">
                                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                                </svg>
                            )}
                            {like ? 1 : 0}
                        </Button>
                        <Button variant="white" className='d-flex align-items-center'>
                            <img src={Loader} className='d-block' alt="Loader" />
                        </Button> */}
                        <Button variant="white" className='d-flex align-items-center' onClick={refresh}>
                            <img src={Refresh} className='d-block' alt="Refresh" />
                        </Button>
                    </ButtonGroup>
                </div>
                <Row className='gx-lg-5 gx-md-4'>
                    <Col md={5} xs={12} className='mb-md-0 mb-2'>
                        <div className='shop-frame d-flex border'>
                            <img src={dataObject.imagePath} alt="ShopImage" className='img-fluid m-auto' />
                        </div> 
                    </Col>
                    <Col md={7} xs={12} className='pt-md-3'>
                        <div className='d-none d-md-block'>
                            <p className='b2 mb-2 text-black'>Divine Dimension  
                            {/* <span className='ps-2'>Condos</span>  */}
                            <img src={Verified} className='ms-1' alt="Verified" /></p>

                            <h1 className='text-36 text-black text-600'>{dataObject.nftName}  <br />{dataObject.valid} - {dataObject.escrowAddress}</h1>

                            <p className='b2 mb-20'>Owned by <span className='ms-1 text-block'>{(dataObject.ownerAddress).substring(0, 4)}...{(dataObject.ownerAddress).substring((dataObject.ownerAddress).length -4, (dataObject.ownerAddress).length)}</span></p>
                        </div>
                        
                        <div className='d-none d-md-block'>
                            <Button variant='white' onClick={() =>  {navigator.clipboard.writeText(dataObject.nftAddress); setShow(true)}} className='px-md-3 px-2 me-md-3 me-2 mb-md-3 mb-2' size='sm'>NFT Address: {(dataObject.nftAddress).substring(0, 4)}...{(dataObject.nftAddress).substring((dataObject.nftAddress).length -4, (dataObject.ownerAddress).length)} <img src={CopyIcon} className='ms-1' style={{verticalAlign: '-5px'}} alt='Copy Icon' /></Button>
                            <Button variant='white' onClick={() =>  {navigator.clipboard.writeText(dataObject.escrowAddress); setShow(true)}} className='px-md-3 px-2 mb-md-3 mb-2' size='sm'>Token ID: {dataObject.escrowAddress} <img src={CopyIcon} className='ms-1' style={{verticalAlign: '-5px'}} alt='Copy Icon' /></Button>
                            
                            <p className='b2 mb-20'>{dataObject.nftDescription}</p>

                            <h4 style={{color: 'var(--yellow)'}} className='d-flex align-items-center text-18 text-600 mb-3'>
                                {dataObject.nftType === "Royalty"? (<>
                                <img src={RoyaltiesIcon} className='me-2' alt="RoyaltiesIcon" /> 
                                 Royalties
                                </>):(<></>)}
                                
                                </h4>
                        </div>

                        <Card className='mb-3'>
                            <Card.Header className='text-500'>Current Price</Card.Header>
                            <Card.Body>
                                <div><strong className='text-54 text-600'>₳ {parseFloat(dataObject.nftPrice/1000000000000000000).toFixed(4)}</strong><sub className='b2 ms-3 doller text-gray'>($37 USD)</sub></div>
                                <p className='b2 mb-20'>Top Offer: ₳ <span className='px-2'>38</span></p>

                                <Row className='gx-2'>
                                    <Col xs={6} sm={5} md={4}>
                                        <Dropdown>
                                        {checkAccount && (chainId == 8453) ? 
                                        <>{loader ? <>
                                            <Button variant="black" className='b2 w-100 px-3 dropdown-noarray' size='sm' id="dropdown-basic"><ClockLoader color="#ffffff" size={25} /></Button>
                                        </> : <>
                                            <Dropdown.Toggle variant="black" className='b2 w-100 px-3 dropdown-noarray' size='sm' id="dropdown-basic" onClick={()=>buyNow()}>
                                                Buy NFT 
                                            </Dropdown.Toggle>
                                        </>}</> : 
                                        <>{checkAccount ? 
                                            <Dropdown.Toggle variant="black" className='b2 w-100 px-3 dropdown-noarray' size='sm' id="dropdown-basic"  onClick={() => ChangeNetwork(walletProvider)}> Change Network </Dropdown.Toggle>: 
                                            <Dropdown.Toggle variant="black" className='b2 w-100 px-3 dropdown-noarray' size='sm' id="dropdown-basic"  onClick={ConnectWallet}> Connect Wallet </Dropdown.Toggle> }
                                            {/* <Dropdown.Toggle variant="black" className='b2 w-100 px-3 dropdown-noarray' size='sm' id="dropdown-basic" onClick={chainId !== 84532 ? ChangeNetwork : ConnectWallet}>
                                        {chainId !== 84532 ? "Change Network" : "Connect Wallet"} 
                                    </Dropdown.Toggle> */}
                                    </>}
                                    <Modal show={ispopup} size="lg" centered="true" onHide={handleClose}>
                        <Modal.Body className='p-md-4 p-3'>
                            <div className='p-md-2'>
                            <div className='text-end position-relative' style={{marginBottom: '-20px', zIndex: 2}}>
                                <Button variant='reset' onClick={handleClose} className='p-0 text-gray m-0'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="d-block" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                                </Button>
                            </div>
                            <Row className='justify-content-center'>
                                <Col md={9}>
                                 <div className='text-center mt-md-0 mt-3 mb-3'>
                                <h2>Connect wallet</h2>
                                <p className='b2 mx-md-4 px-md-5'>Choose how you want to connect. There are several wallet providers.</p>
                                </div>

                                <Tabs defaultActiveKey="ethereum"
                                      id="uncontrolled-tab-example"
                                      className='mb-md-4 mb-lg-4 mb-3 w-100'>
                                    <Tab eventKey="ethereum" title="Ethereum">
                                        <ConnectPopup/>
                                    </Tab>
                                </Tabs>
                                </Col>
                            </Row>
            
                            </div>
                        </Modal.Body>
                    </Modal>
                                        

                                            {/* <Dropdown.Menu className='dropdown-filter dropdown-buy p-3'>
                                                <p className='b2 pb-2 border-bottom text-gray-36'>Introducing More Ways to Buy!</p>
                                                
                                                <Stack gap={3} className='mb-3' direction='horizontal'>
                                                    <img src={Ethereum} alt='image' />
                                                    <img src={Solana} alt='image' />
                                                    <img src={Credit} alt='image' />
                                                </Stack>
                                                <p className='b2 mb-20'>You can now buy your favorite items using ETH, SOL, and credit card!</p>
                                                
                                                <h6 style={{color: '#6B7280'}} className='text-13 mb-3 text-500'>Powered by <img src={WinterLogo} alt="WinterLogo" /> Winter</h6>

                                                <Button variant='gray' className='border b2 w-100' size="md">Got it</Button>
                                            </Dropdown.Menu> */}
                                        </Dropdown>
                                    </Col>
{/* 
                                    <Col xs={6} sm={5} md={4}>
                                        <Button variant='white' size='sm b2 w-100 px-3'>Make Offer</Button>
                                    </Col> */}
                                </Row>
                            </Card.Body>
                        </Card>
                        
                        <div className='d-md-none'>
                            <Button variant='white' onClick={() =>  {navigator.clipboard.writeText('9114...9937'); setShow(true)}} className='px-2 me-2 mb-2' size='sm'>Policy ID: 9114...9937 <img src={CopyIcon} className='ms-0' style={{verticalAlign: '-5px'}} alt='Copy Icon' /></Button>
                            <Button variant='white' onClick={() =>  {navigator.clipboard.writeText('9114...3330'); setShow(true)}} className='px-2 mb-2' size='sm'>Asset ID: 9114...3330 <img src={CopyIcon} className='ms-0' style={{verticalAlign: '-5px'}} alt='Copy Icon' /></Button>
                            
                            <p className='b2 mb-20'>A luxurious condo in Dream Spire Tower on Divine Island</p>

                            <h4 style={{color: 'var(--yellow)'}} className='d-flex align-items-center text-18 text-600 mb-3'><img src={RoyaltiesIcon} className='me-2' alt="RoyaltiesIcon" /> 5.00% Royalties</h4>
                        </div>

                    </Col>
                </Row>
            </div>

            <div className='mb-5'>
                <Tabs
                    defaultActiveKey="properties"
                    id="uncontrolled-tab-example"
                    className='mb-md-4 mb-3'
                >
                    {/* <Tab eventKey="properties" title="Properties">
                        <Properties />
                    </Tab>
                    <Tab eventKey="price" title="Price History">
                        <PriceHistory />
                    </Tab> */}
                    <Tab eventKey="trading" title="Trading History">
                        <TradingHistory nftAddress={dataObject.nftAddress} effectControl={transChange}/>
                    </Tab>
                </Tabs>
            </div>


            <div className='overflow-xl-hidden'>
                <h2 className='h1 mb-20'>Explore the Collection</h2>

                <Swiper
                    modules={[Navigation, A11y]}
                    spaceBetween={16}
                    slidesPerView={'auto'}
                    autowidth="true"
                    navigation
                    className='swiper-card-4'
                    // onSwiper={(swiper) => console.log(swiper)}
                    // onSlideChange={() => console.log('slide change')}
                >
                     {saleImage[0] === "" ||saleImage[0] === null || saleImage[0] === undefined || saleImage[0] === "undefined" ?
                        (<>
                        </>):(<>
                            {saleImage.map((r,i)=>{
                                if(r.nftName !== dataObject.nftName){
                                    return(
                                        <div>
                                            <SwiperSlide><CardViewShop x ={r} /></SwiperSlide>
                                            
                                        </div>
                                    )
                                }
                               
                            })}
                        </>)}
                    
                    {/* <SwiperSlide><CardViewShop /></SwiperSlide>
                    <SwiperSlide><CardViewShop /></SwiperSlide>
                    <SwiperSlide><CardViewShop /></SwiperSlide> */}
                </Swiper>
            </div>
            
        </>
     );
}

export default MarketExploreCart;