import { Button, Col, Container, FloatingLabel, Form, InputGroup, Row , Tabs, Tab, Modal} from "react-bootstrap";
import BackIcon from '../../asserts/IMAGES/back-icon.svg';
import { Link, useNavigate } from "react-router-dom";
import ImageIcon from '../../asserts/IMAGES/image-icon.svg'
import DateTimePicker from "../../Snippets/DateTimePicker";
//own imports
import { ToastContainer, Zoom, toast} from 'react-toastify';
import Compress from "react-image-file-resizer";
import React,{useEffect,useState} from 'react';
import {Web3 ,utils} from 'web3';
// import { abibytecodeLaunchpad2, abiLaunchpad2 } from "./LaunchpadBytesCode";
import { abibytecodeLaunchpad2 } from "../../abibytecode";
import { abiLaunchpad2 } from "../../abi";
// import { customStakeabi, customStakeabibytecode } from "../NFTStaking/CustomStakeContract";
import { customStakeabi } from "../../abi";
import { customStakeabibytecode } from "../../abibytecode";
// import { abierc20, contracterc20 } from "./LaunchpadContract";
import { abierc20 } from "../../abi";
import { dimeContract as contracterc20 } from "../../address";
import axios from 'axios';
import {createevents} from "../../awsdatafile";
import { createEventsFirebase } from '../../FirebaseFunctions';
import { ClockLoader } from 'react-spinners';
import {Ethereum as ConnectPopup} from '../../component/tabs/TabContent';
import { ethers, ContractFactory  } from 'ethers';
import web3Instance from '../../web3Inastance';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';
import {getLanuchpadTxnFirebase, createLaunchpadTxnTableFirebase} from '../../FirebaseFunctions';
import { ChangeNetwork, ConnectWalletCommon as ConnectWallet } from "../../GeneralFunctions";

function CreateLaunchpad({web3State}) {
    const navigate = useNavigate();

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
//own code

    const[loader, setLoader] = useState(false);
    const [contractAddress, setContractAddress] = useState('');
    const [stakeAddress, setStakeAddress] = useState('');
    const handleHideLoad = () => setLoader(false);   
    const [getFile,setFile] = useState(""); 
    const [Img,setImg] = useState("");
    const [Imgname,setImgname] = useState("");
    const [launchpadTitle, setLaunchpadTitle] = useState("");
    const [goalAmount, setGoalAmount] = useState();
    const [rewardAmount, setRewardAmount] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());
    const [startEpoch, setStartEpoch] = useState();
    const [endEpoch, setEndEpoch] = useState();
    const [startTimedb, setStartTimedb] = useState('');
    const [endTimedb, setEndTimedb] = useState('');
    const [Description,setDescription] = useState("");
    const [category,setCategory] = useState("");
    const [email,setEmail] = useState("");
    const [images, setImages] = useState([]);
    const [imgCover, setImgCover] = useState("");
    const [checkAccount, setCheckAccount] = useState(null);
    const [walletAddress, setWalletAddress] = useState("");
    const [open, setOpen] = useState(false);
    
    const [ispopup, setIspopup] = useState(false);
    const handleClose = () => setIspopup(false);

    const [createLaunchpadLoader, setCreateLaunchpadLoader] = useState(false);

    const handleCreateLaunchpadLoad = () => setCreateLaunchpadLoader(true);
    const handleCreateLaunchpadHide = () => setCreateLaunchpadLoader(false);

    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
      setChecked(event.target.checked);
    };

    const emptyFields = () => {
        setFile("");
        setImg("");
        setLaunchpadTitle("");
        setCategory("");
        setDescription("");
        setWalletAddress("");
        setEmail("");
        setGoalAmount("");
        
    }

const captureFile =async(event) => {
    event.stopPropagation()
    event.preventDefault()
    const file = event.target.files[0]
    setImgname(file.name)
    setFile(file)
    const MIN_FILE_SIZE = 1024 // 1KB
    const MAX_FILE_SIZE = 500120 // 500KB
    let fileSizeKiloBytes = file.size 
    let c=0;
    if(fileSizeKiloBytes < MIN_FILE_SIZE){
      toast.dismiss();
      toast.error("File size is less than minimum limit",{autoClose:3000});          
      c=c+1;
      handleHideLoad()                               
      await sleep(4000);
    //   window.location.reload(false)
    }
    if(fileSizeKiloBytes > MAX_FILE_SIZE){
      toast.dismiss();
      toast.error("File size is greater than maximum limit",{autoClose:3000});      
      c=c+1;
      handleHideLoad()  
      await sleep(4000);                             
    //   window.location.reload(false)
    }        
    if(c===0){
    let reader = new window.FileReader()
    try{
    Compress.imageFileResizer(file, 500, 500, 'JPEG', 200, 0,
    uri => {          
        setImg(uri)        
    },
    'base64'
    );
    reader.readAsArrayBuffer(file)        
    }catch (err) {      
    }
    }else{
      toast.dismiss();
      toast.error("Support file size: 1 kb to 500 kb ",{autoClose:3000});                
      handleHideLoad()                               
      await sleep(4000);
    //   window.location.reload(false)
      
    }
    
}; 

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
// const connectToEthereum = async () => {
//     try {
//       if (window.ethereum) {
//         await window.ethereum.request({ method: 'eth_requestAccounts' });
//         const web3 = new Web3(window.ethereum);
//         if(address !== "" && address !== undefined && address !== null){
//             setCheckAccount(true);
//         }
//         else{
//             setCheckAccount(false);
//         }
//         return web3;
//       } else {
//         throw new Error('No Ethereum wallet found.');
//       }
//     } catch (error) {
//       console.error(error);
//       setCheckAccount(false);
//       return null;
//     }
//   };

  const formatDateTime = (date1, time1) => {
    const date = new Date(date1 * 1000);
    const time = new Date(time1 * 1000)
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = time.getHours().toString().padStart(2, '0');
    const minutes = time.getMinutes().toString().padStart(2, '0');
    const seconds = time.getSeconds().toString().padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  const createNft =async() =>{
    handleCreateLaunchpadLoad();
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;          
        if(address === null || address === undefined || address === ''){                      
          toast.warning(`please connect your wallet`,{autoClose: 5000});            
          handleCreateLaunchpadHide();                     
        }
        else if(launchpadTitle === "" || launchpadTitle  === undefined || launchpadTitle === null){          
          toast.warning(`Please Enter Title`,{autoClose: 5000}); 
          handleCreateLaunchpadHide();                     
        }
        else if(!/\S/.test(launchpadTitle)){          
          toast.warning(`only space not allowed`,{autoClose: 5000});            
          handleCreateLaunchpadHide();                     
        }
        else if(format.test(launchpadTitle)){          
          toast.warning(`Please enter valid Title special character not allowed`,{autoClose: 5000});            
          handleCreateLaunchpadHide();                     
        }                
        else if(Img === "" || Img === undefined || Img === null){          
          toast.warning(`Please Select Image`,{autoClose: 5000});            
          handleCreateLaunchpadHide();                   
        }  
        else if(Description === "" || Description  === undefined || Description === null){          
            toast.warning(`Please Enter Title`,{autoClose: 5000}); 
            handleCreateLaunchpadHide();                     
        }  
        else{            
          toast.info("Deploying and Uploading Details",{autoClose: 5000});             
        //   toast.info("Image Uploading in IPFS",{autoClose: 5000}); 
    if (getFile) {
        try{
            const formData = new FormData();
            formData.append("file", getFile);
            const resFile = await axios({
                method: "post",
                url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
                data: formData,
                headers: {
                    'pinata_api_key': "a4454043d8776ac1d3b7",
                    'pinata_secret_api_key': '903a589e02512750f7cc3bb7b3fa9031c9428ac40bbfe9778c01394f65813aaf',                        
                    "Content-Type": "multipart/form-data"
                },
              });                
              const ipfsurl = `https://gateway.pinata.cloud/ipfs/${resFile.data.IpfsHash}`;                                  
              console.log("Pinata updated",ipfsurl);
    try {
        const web3 = web3Instance;
        if (!web3) return;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
  
        const account = address;
  
        // const contract = new web3.eth.Contract(abiLaunchpad2);
        const factory = new ContractFactory(abiLaunchpad2, abibytecodeLaunchpad2, signer);

        const Stakefactory = new ContractFactory(customStakeabi, customStakeabibytecode, signer);
  
        let starttime1 = formatDateTime(startDate, startTime);
        setStartTimedb(starttime1);
        let epochstarttime = toepoch(starttime1);
        setStartEpoch(epochstarttime);
        console.log(starttime1);
        let endtime1 = formatDateTime(endDate, endTime);
        setEndTimedb(endtime1)
        let epochendtime = toepoch(endtime1);
        setEndEpoch(epochendtime);
        console.log(endtime1);

        console.log(goalAmount);
        let goalAmount1 = parseInt(goalAmount);
        console.log("chacking2",launchpadTitle,ipfsurl, web3.utils.toNumber(parseInt(goalAmount)), epochstarttime, epochendtime);

        const contractInstance = await factory.deploy(launchpadTitle,"Base",ipfsurl, web3.utils.toNumber(parseInt(goalAmount)), epochstarttime, epochendtime);
        let contractreciept = await contractInstance.deployTransaction.wait();

        // const contractInstance = await contract
        //   .deploy({
        //     data: abibytecodeLaunchpad2,
        //     arguments: [launchpadTitle,"Base",ipfsurl, web3.utils.toNumber(parseInt(goalAmount)), epochstarttime, epochendtime] ,
        //   })
        //   .send({
        //     from: account
        //   });
  
        console.log('Contract deployed at address:', contractInstance.address);
        setContractAddress(contractInstance.address);

        console.log("check",rewardAmount);
        let stakereward = rewardAmount * 1000000000000000000;
        console.log("check3",utils.toBigInt(rewardAmount * 1000000000000000000));
        // const stakeInstance = await Stakefactory.deploy("0x19AcB98E4E2Ba4c39BDbEDE4706Cb37d4E0d4f18", contractInstance.address, utils.toBigInt(rewardAmount * 1000000000000000000), 86400);
        const stakeInstance = await Stakefactory.deploy(contracterc20, contractInstance.address, utils.toBigInt(rewardAmount * 1000000000000000000), 86400);
        let stakeContractReceipt = await stakeInstance.deployTransaction.wait();

        console.log('StakeContract deployed at address:', stakeInstance.address);
        setStakeAddress(stakeInstance.address);

        // const erc20token = new ethers.Contract("0x19AcB98E4E2Ba4c39BDbEDE4706Cb37d4E0d4f18",abierc20, signer);
        const erc20token = new ethers.Contract(contracterc20 ,abierc20, signer);
        let approveresult = await erc20token.approve(stakeInstance.address,utils.toBigInt(rewardAmount * 1000000000000000000));
        let approvereciept = await approveresult.wait();

        const staking = new ethers.Contract(stakeInstance.address,customStakeabi, signer);
        let rewardTransferResult = await staking.migrateReward();
        let rewardTransferReciept = await rewardTransferResult.wait();
        console.log(rewardTransferReciept);

        let crossmintFeature = "No";
        if(checked)
            crossmintFeature = "Yes";
        await createEventsFirebase(launchpadTitle, Description, contractInstance.address, starttime1, endtime1, parseInt(goalAmount), address, ipfsurl, category, email, crossmintFeature, stakeInstance.address);
        // await createEvents(launchpadTitle, Description, contractInstance.address, starttime1, endtime1, parseInt(goalAmount), address, ipfsurl, category, email, crossmintFeature, stakeInstance.address);
        console.log("Data stored in events database table!!!");
        setOpen(true);
        handleCreateLaunchpadHide();
        emptyFields();
        // navigate("/event");
      } catch (error) {
        console.error('Contract deployment error:', error);
        handleCreateLaunchpadHide();
      }
    }catch (error) {              
        console.log("Error sending File to IPFS: ")
        console.log(error)                                
        handleCreateLaunchpadHide();
    }
}
        }
}

const firebaseTest = async() => {
    await getLanuchpadTxnFirebase("contractAddress");
    await createLaunchpadTxnTableFirebase(123,"mintresult.hash" ,address,123,"contractAddress",4 );
    // await createEventsFirebase(launchpadTitle, Description, "contractInstance.address", 123, 321, 699, address, "ipfsurl", "category", "email", "crossmintFeature", "stakeInstance.address");
    }

const toepoch = (date) =>{
    const dateTime = new Date(date);

// Get the Unix Epoch timestamp in milliseconds
const timestamp = dateTime.getTime();

// Convert milliseconds to seconds (Unix timestamp)
const epochTimeInSeconds = Math.floor(timestamp / 1000);

// console.log(epochTimeInSeconds);
return epochTimeInSeconds;

}


  

  const handleStartDate = (dateTime) => {
    setStartDate(dateTime.unix);
  };
  const handleStartTime = (dateTime) => {
    setStartTime(dateTime.unix);
  };
  const handleEndDate = (dateTime) => {
    setEndDate(dateTime.unix);
  };
  const handleEndTime = (dateTime) => {
    setEndTime(dateTime.unix);
  };
 const handleGoalChange = (event) =>{
    const newValue = parseInt(event.target.value);
    console.log("checking",event.target.value);
    setGoalAmount(newValue);
 }
 const handleRewardChange = (event) =>{
    const newValue = parseInt(event.target.value);
    console.log("checking",event.target.value);
    setRewardAmount(newValue);
 }
    const handleCategoryChange = (event) =>{
        setCategory(event.target.value);
    }
    const handleEmail = (event) =>{
        setEmail(event.target.value);
    }
    const handleWalletAddress = (event) =>{
       setWalletAddress(event.target.value);
    }

    useEffect(() => {
        if(address !== "" && address !== undefined && address !== null){
            setCheckAccount(true);
            setWalletAddress(address);
        }
        else{
            setCheckAccount(false);
        }
     }, [isConnected,address,chainId]);


    return ( 
        <>
            <Row className="mb-40">
                <Col lg={12}>
                    <Button variant="reset" className="btn-back me-md-3 me-2 " onClick={() => navigate(-1)}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={BackIcon} alt="back" className="d-block" />
                        <p style={{ margin: 0 }}>Back</p>
                      </div>
                    </Button>
                    <h1 className="mb-2 text-600 d-flex align-items-start">
                        Create Fundraising
                    </h1>
                    <p className='b1'>Craft a Compelling Narrative: Complete all the Details to Ignite Curiosity and Drive Support for Your Fundraising Journey!</p>
                </Col>
            </Row>

            <Container fluid={"sm"} className="px-0 mb-5">
                {/* <input type="file" id="upload" hidden onChange={captureFile} />
                <label htmlhtmlFor="upload" className="upload-area mb-4 form-control d-flex">
                    <div className="m-auto text-center">
                        <Button variant='white' className="mb-3" size="sm"><img className='d-block' src={ImageIcon} alt="DotsConnect" /></Button> 
                        <p><span className="text-block">Browse your computer or <br />drag a picture to add a cover</span> <br />(recommended size: 1340 x 670)</p>
                    </div>
                </label> */}
            {/* {getFile === null || getFile === "" || getFile === undefined || getFile === "undefined" ?
            (<>
            <input type='file' id='uploadFile' hidden onChange = {captureFile}/>
            <label htmlFor='uploadFile' className='border-dark cursor-pointer mb-20 text-center justify-content-center d-flex b1' style={{height: '280px'}}>
                <div className='m-auto'>
                    <span className='border d-inline-block p-3'><img src={ImageIcon} alt="image_icon" className='d-block' /></span>
                    <p className='text-black mt-3'>Browse your computer<br />(recommended size: 1340 x 670)<br /><span className='text-gray'>PNG, JPG. Max 500 KB.</span></p>
                </div>
            </label>  
            </>):(<>
            <input type='file' id='uploadFile' hidden onChange = {captureFile}/>
            <label htmlFor='uploadFile' className='border-dark cursor-pointer mb-20 text-center justify-content-center d-flex b1' style={{height: '280px'}}>
                <img src={Img} alt="imgCover" className='img-fluid w-100 h-100 object-cover w-90 rounded-16' />
            </label>
            </>)} */}
                {/* <input type="file" id="upload" hidden onChange={captureFile} />
                <label htmlFor="upload" className="upload-area mb-4 form-control d-flex">
                <div className="m-auto text-center">
                    <Button variant='white' className="mb-3" size="sm"><img className='d-block' src={ImageIcon} alt="DotsConnect" /></Button> 
                    <p><span className="text-block">Browse your computer</span><br />(recommended size: 1340 x 670)</p>
                </div>
                </label> */}
                <Row className="md-4">
                    <Col md={6} className='d-md-block'>
                      <h4 className='text-500 mb-3'>Upload file</h4>
                      <input type='file' id='uploadFile' hidden onChange={captureFile} />
                      <label htmlFor='uploadFile' className='border-dark cursor-pointer mb-md-4 mb-3 text-center d-flex b1 align-items-center justify-content-center' style={{ minHeight: '500px' }}>
                        <div>
                          <span className='border d-inline-block'><img src={ImageIcon} alt="image_icon" className='d-block' /></span>
                          <p className='text-black text-black m-auto'>Browse your computer<br /><span className='text-gray'>PNG/Img. Max 500kb.</span></p>
                        </div>
                      </label>
                    </Col>
                    <Col md={6} className='d-md-block mb-3'>
                      <h4 className='text-500 mb-3'>Preview</h4>
                      {Img === null || Img === "" || Img === undefined || Img === "undefined" ? (
                        <>
                          <div className='border-dark text-center d-flex b1' style={{ minHeight: '500px' }}>
                            <p className='text-black m-auto'>Upload file and choose <br />collection to preview your <br />brand new NFT</p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className='border-dark text-center d-flex b1'>
                            <img src={Img} alt="Img" className='img-fluid w-100 rounded-16' />
                          </div>
                        </>
                      )}
                    </Col>
                </Row>

                <Row>
                <ToastContainer position='bottom-right' draggable = {false} transition={Zoom} autoClose={4000} closeOnClick = {false}/>
                    <Col sm={6} className="mb-3">
                        <FloatingLabel
                            controlId="floatingInput.Title"
                            label="Email"
                        >
                            <Form.Control
                                type='mail'
                                placeholder=" "
                                value = {email}
                                onChange={handleEmail}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col sm={6} className="mb-3">
                        <FloatingLabel
                            controlId="floatingInput.Title"
                            label="WalletAddress"
                        >
                            <Form.Control
                                type='text'
                                placeholder=" "
                                value = {walletAddress}
                                onChange={handleWalletAddress}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col sm={6} className="mb-3">
                        <FloatingLabel
                            controlId="floatingInput.Title"
                            label="Launchpad Title"
                        >
                            <Form.Control
                                type='text'
                                placeholder=" "
                                value = {launchpadTitle}
                                onChange={(event) => setLaunchpadTitle(event.target.value)}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col sm={6} className="mb-3">
                        <FloatingLabel
                            controlId="floatingInput.Category"
                            label="Launchpad Category"
                        >
                            <Form.Select aria-label="Floating label select example" onChange={handleCategoryChange}> 
                                <option>Select category</option>
                                <option value="General">General</option>
                                <option value="Donation">Donation</option>
                                <option value="Construction">Construction</option>
                                <option value="SpecialPermission">Special Permission</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                    <Col sm={12} className="mb-3">
                        <FloatingLabel controlId="floatingTextarea2" label="Description">
                            <Form.Control
                                as="textarea"
                                placeholder=" "
                                style={{ height: '140px' }}
                                onChange={(event) => setDescription(event.target.value)}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col xs={6} className="mb-3">
                        <DateTimePicker label="Start date" type={'date'} value={startDate} ondate={ handleStartDate }/>
                    </Col>
                    <Col xs={6} className="mb-3">
                        <DateTimePicker label="Start time" type={'time'} value={startTime} ondate={ handleStartTime }/>
                    </Col>
                    <Col xs={6} className="mb-3">
                        <DateTimePicker label="End date" type={'date'} value={endDate} ondate={ handleEndDate }/>
                    </Col>
                    <Col xs={6} className="mb-3">
                        <DateTimePicker label="End time" type={'time'} value={endTime} ondate={ handleEndTime }/>
                    </Col>
                    {/* <Col sm={4} xs={6} className="mb-3">
                        <FloatingLabel
                            controlId="floatingInput.Title"
                            label="Total amount"
                        >
                            <Form.Control
                                type='text'
                                value="0"
                                placeholder=" "
                            />
                        </FloatingLabel>
                    </Col> */}
                    <Col sm={12} xs={12} className="mb-3">
                        <FloatingLabel
                            controlId="floatingInput.Title"
                            label="Total Required Amount"
                        >
                            <Form.Control
                                type='number'
                                value={goalAmount}
                                placeholder=" "
                                onChange={handleGoalChange}/>
                        </FloatingLabel>
                    </Col>
                    <Col sm={12} xs={12} className="mb-3">
                        <FloatingLabel
                            controlId="floatingInput.Title"
                            label="Stake Reward Amount"
                        >
                            <Form.Control
                                type='number'
                                value={rewardAmount}
                                placeholder=" "
                                onChange={handleRewardChange}/>
                        </FloatingLabel>
                    </Col>
                    <Col sm={12} xs={12} className="mb-3" style={{marginLeft: "20px"}}>
                        <FormControlLabel
                          style={{ fontWeight: "bolder" }}
                          value="end"
                          control={<Checkbox checked={checked} onChange={handleChange} />}
                          label={
                            <span style={{ fontWeight: 'bold' }}>
                                If you want to enable donation through a credit card using a service called "Crossmint," check the box. Crossmint acts like a bridge, converting your country's currency into cryptocurrency for crypto transactions at that particular instance.
                            </span>
                          }
                          labelPlacement="end"
                        />
                    </Col>
                    {/* <Col sm={4} xs={12} className="mb-3">
                        <FloatingLabel
                            controlId="floatingInput.Category"
                            label="Server"
                        >
                            <Form.Select aria-label="Floating label select example">
                                <option>Any Server</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                                 <option value="1">AWS</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Col> */}
                    {/* <Col sm={6} className="mb-3">
                        <FloatingLabel
                            controlId="floatingInput.Title"
                            label="Email or discord name"
                        >
                            <Form.Control
                                type='text'
                                placeholder=" "
                                value={email}
                                onChange={handleEmail}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col sm={6} className="mb-3">
                        <FloatingLabel
                            controlId="floatingInput.Title"
                            label="Additional info"
                        >
                            <Form.Control
                                type='text'
                                placeholder=" "
                            />
                        </FloatingLabel>
                    </Col> */}
                        <Box sx={{ width: '100%' }}>
                          <Collapse in={open}>
                            <Alert
                              severity="info"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setOpen(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                              sx={{ mb: 2 }}
                            >
                              Your fundraising event has been successfully 
                              deployed and submitted for admin review to list 
                              in premium donation event. You can either wait or 
                              message to our discord server to speed up listing your 
                              fundraising event.
                            </Alert>
                          </Collapse>
                        </Box>
                    <Col xs={6} className="mb-3 pt-2">
                        <Button type="reset" className="px-md-4 b2 px-3" variant="white">Cancel</Button>
                    </Col>
                    {checkAccount && (chainId == 8453) ? 
                    <><Col xs={6} className="mb-3 text-end pt-2">
                    {createLaunchpadLoader ? <>
                        <Button type="reset" className="px-md-5 b2 px-4" variant="black"><center><ClockLoader color="#ffffff" size={25} /></center></Button>
                    </> : <>
                    <Button type="reset" className="px-md-5 b2 px-4" variant="black" onClick={createNft}>Create Fundraising</Button>
                    </>}
                </Col></> : 
                    <><Col xs={6} className="mb-3 text-end pt-2">
                    {/* <Button type="reset" className="px-md-5 b2 px-4" variant="black"  onClick={() => {setIspopup(true)}}> Create Fundraising </Button> */}
                    {checkAccount ? 
                    <Button type="reset" className="px-md-5 b2 px-4" variant="black"  onClick={() => ChangeNetwork(walletProvider)}> Change Network </Button>: 
                    <Button type="reset" className="px-md-5 b2 px-4" variant="black"  onClick={ConnectWallet}> Connect Wallet </Button> }
                    {/* <Button type="reset" className="px-md-5 b2 px-4" variant="black"  onClick={chainId !== 84532 ? ChangeNetwork : ConnectWallet}> {chainId !== 84532 ? "Change Network" : "Connect Wallet"}  </Button> */}
                   </Col></>}
                   <Modal show={ispopup} size="lg" centered="true" onHide={handleClose}>
                        <Modal.Body className='p-md-4 p-3'>
                            <div className='p-md-2'>
                            <div className='text-end position-relative' style={{marginBottom: '-20px', zIndex: 2}}>
                                <Button variant='reset' onClick={handleClose} className='p-0 text-gray m-0'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="d-block" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                                </Button>
                            </div>
                            <Row className='justify-content-center'>
                                <Col md={9}>
                                 <div className='text-center mt-md-0 mt-3 mb-3'>
                                <h2>Connect wallet</h2>
                                <p className='b2 mx-md-4 px-md-5'>Choose how you want to connect. There are several wallet providers.</p>
                                </div>

                                <Tabs defaultActiveKey="ethereum"
                                      id="uncontrolled-tab-example"
                                      className='mb-md-4 mb-lg-4 mb-3 w-100'>
                                    <Tab eventKey="ethereum" title="Ethereum">
                                        <ConnectPopup/>
                                    </Tab>
                                </Tabs>
                                </Col>
                            </Row>
            
                            </div>
                        </Modal.Body>
                    </Modal>
                </Row>
            </Container>
        </>
     );
}

export default CreateLaunchpad;