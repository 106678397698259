// src/Web3Context.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { createWeb3Modal, defaultConfig, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
// import { abierc20, contracterc20 } from './pages/Launchpad/LaunchpadContract.js';
import { abierc20 } from './abi';
import { dimeContract as contracterc20 } from './address';

const API_KEY ="DGZJ4YRDXJCJ4MDKBWR8MSRHZ3VC75DKYH"
const Network_Name ="Base"
// Your Web3Modal configuration
const projectId = 'a0566b417a74c151a64e8e2f9c911652'
const metadata = {
    name: 'Divine Dimension',
    description: 'My Website description',
    url: 'https://www.divinedimension.io/',
    icons: ['https://testnet.divinedimension.io/static/media/logo-icon.cdce6297c02ec9b165cd0f543ffb50ef.svg']
  }
  const mainnet = {
    chainId: 8453,
    name: 'Base',
    currency: 'ETH',
    explorerUrl: 'https://basescan.org/',
    rpcUrl: 'https://base-rpc.publicnode.com/'
  }
  createWeb3Modal({
    ethersConfig: defaultConfig({ 
      metadata,
      defaultChainId: 8453,
      enableEIP6963: true,
      enableInjected: true,
      enableCoinbase: true,
      rpcUrl: 'https://base-rpc.publicnode.com/'
    }),
    chains: [mainnet],
    projectId
  })

// Create a context
const Web3Context = createContext();

export const useWeb3 = () => useContext(Web3Context);

export const Web3Provider = ({ children }) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();

  const [balances, setBalances] = useState({
    dime: 0.00,
    eth: 0.00
  });

  const balanceOfTokens = async () => {
    if (isConnected) {
      try {
        // const provider = ethers.getDefaultProvider(Network_Name, {
        //   etherscan: API_KEY});
        const provider = ethers.getDefaultProvider('https://base-rpc.publicnode.com/');

        // const dime = await getTokenBalance(address, DIME_Token_Address, DIME_Token_ABI);
        const eth = await provider.getBalance(address);
        
        // let dimeBalance = ethers.utils.formatUnits(dime, 9);
        let etherBalance = ethers.utils.formatUnits(eth, 18);

        // const signer = provider.getSigner();
        // const erc20token = new web3.eth.Contract(abierc20, "0xf35A6D2F0f08638e6f09473Dd5f40e69b4889a8C");
        const erc20token = new ethers.Contract(contracterc20,abierc20, provider);

        const dime = await erc20token.balanceOf(address);

        let dimeBalance = ethers.utils.formatUnits(dime, 18);

        setBalances({
          dime: parseFloat(dimeBalance).toFixed(2),
          eth: parseFloat(etherBalance).toFixed(4)
        });

        console.log("Balances updated:", balances);
      } catch (error) {
        console.error("Error fetching token balances:", error);
      }
    }
    else{
      setBalances({
        dime: 0.00,
        eth: 0.00
      });
    }
  };

  useEffect(() => {
      balanceOfTokens();
  }, [isConnected, address]);

  return (
    <Web3Context.Provider value={{ address, balances }}>
      {children}
    </Web3Context.Provider>
  );
};
