import { Outlet } from "react-router-dom";
import LayoutAfterLogin from "../../component/LayoutAL";

function Allusers({ethBalance, dimeBalance}) {
    return ( 
        <LayoutAfterLogin ethBalance={ethBalance} dimeBalance={dimeBalance}>
            <div className="pb-5">
                <Outlet />
            </div>
        </LayoutAfterLogin> 
     );
}

export default Allusers;