import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import LoginIcon from '../asserts/IMAGES/logo-icon.svg'
import NavIcon1 from '../asserts/IMAGES/navIcon-1.svg'
import NavIcon2 from '../asserts/IMAGES/navIcon-2.svg'
import Avatar from '../Snippets/AvatarDropdown';
import ETH from '../asserts/IMAGES/eth-icon.svg';
import DIME from '../asserts/IMAGES/dime.png';
import { NavLink } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

function Header({ethBalance, dimeBalance}) {

    return ( 
      <>
        <Navbar key={"xl"} expand={'xl'} className="bg-white sticky-top py-xl-0 mb-xl-3 mb-2">
          <Container fluid>
            <Navbar.Brand href="#"><img src={LoginIcon} alt="logo icon" /></Navbar.Brand>

            <div className='order-xl-1 ms-xl-0 ms-auto d-flex align-items-center'>   
                <div className='d-flex text-16 text-500 align-items-center'><img className='me-1' src={ETH} alt="ETH" />{ethBalance ? parseFloat(ethBalance).toFixed(6) : "0.00"}</div>
                <div className='d-flex text-16 text-500 align-items-center ms-lg-4 ms-3'><img className='me-1' src={DIME} alt="DIME" height="22px"/>{dimeBalance ? dimeBalance : "0.00"}</div>
                <Avatar ethBalance={ethBalance} dimeBalance={dimeBalance}/>         
            </div>

            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-xl`}
              aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl`}>
                    <img src={LoginIcon} alt="logo icon" />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Menu />               
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>

      </>
     );
}

export default Header;

export function Menu(){
  return(
    <Nav className="justify-content-end flex-grow-1 pe-xl-3">
      {/* <NavLink className="nav-link" to="/avatar">Avatar</NavLink> */}
      <NavLink className="nav-link" to="/event" onClick={e => e.preventDefault()}>Premium Donation Event<Badge bg='light'>Upcoming</Badge></NavLink>
      <NavLink className="nav-link" to="/map/explore" onClick={e => e.preventDefault()}>Map<Badge bg='light'>Upcoming</Badge></NavLink>
      <NavLink className="nav-link" to="/market/explore" onClick={e => e.preventDefault()}>Marketplace<Badge bg='light'>Upcoming</Badge></NavLink>
      <NavLink className="nav-link" to="/tourism/explore" onClick={e => e.preventDefault()}>Virtual Tourism<Badge bg='light'>Upcoming</Badge></NavLink>
      <NavLink className="nav-link" to="/launchpad" onClick={e => e.preventDefault()}>Fundraising<Badge bg='light'>Upcoming</Badge></NavLink>
      <NavLink className="nav-link" to="/nft-launchpad">NFT Membership</NavLink>
      <NavLink className="nav-link" to="/NFTstaking" onClick={e => e.preventDefault()}>NFT Stake<Badge bg='light'>Upcoming</Badge></NavLink>
      <NavLink className="nav-link" to="/stats" onClick={e => e.preventDefault()}>Stats<Badge bg='light'>Upcoming</Badge></NavLink>
    </Nav>
  )
}