import { Col, Dropdown, Row, Stack } from 'react-bootstrap';
import ImageIcon from '../../asserts/IMAGES/image-icon.svg';
import AvatarPic from '../../asserts/IMAGES/avatar-pic.png';
import { Link, NavLink, Outlet } from 'react-router-dom';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import Compress from "react-image-file-resizer";
import { useEffect, useState } from 'react';
import { getuserDetailsbywalletNew, putbgImagebywalletNew } from '../../awsdatafile';
import axios from 'axios';
import { useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { getuserDetailsbywalletNewFirebase, putbgImagebywalletNewFirebase } from '../../FirebaseFunctions';

export default function ProfileEdit() {
    const { address, chainId, isConnected } = useWeb3ModalAccount();

    const [images, setImages] = useState([]);
    const [imageValue, setImageValue] = useState(false);
    const [imgCover, setImgCover] = useState("");
    const [newImgCover, setNewImgCover] = useState("");
    const [imgProfile, setImgProfile] = useState("");
    const [getFileCover,setFileCover] = useState("")
    const [getFileProfile,setFileProfile] = useState("") 
    const [Imgname,setImgname] = useState("")

    console.log("imgCover", imgCover);
    console.log("imgProfile", imgProfile);
    console.log("getFileCover", getFileCover);
    console.log("getFileProfile", getFileProfile);

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const imageToIpfsHash = async (getFile) => {
        let ipfsurl;
            if(getFile)
            {
                try{
                    const formData = new FormData();
                    formData.append("file", getFile);
                    const resFile = await axios({
                        method: "post",
                        url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
                        data: formData,
                        headers: {
                            'pinata_api_key': "a4454043d8776ac1d3b7",
                            'pinata_secret_api_key': '903a589e02512750f7cc3bb7b3fa9031c9428ac40bbfe9778c01394f65813aaf',                        
                            "Content-Type": "multipart/form-data"
                        },
                      });                
                      ipfsurl = `https://gateway.pinata.cloud/ipfs/${resFile.data.IpfsHash}`;                                  
                      console.log("Pinata updated",ipfsurl)
                    }catch(err){
                        console.error("IPFS error function", err);
                    }
            }
            else{
                console.log("file not found");
            }
            return ipfsurl;
    }

    const getImages = async () => {
        try{
            // let coverAndProfileImages = await getuserDetailsbywalletNew(address);
            // console.log("coverAndProfileImages", coverAndProfileImages);
            //     setImgCover(coverAndProfileImages.data2[0].coverImagePath);
            //     setImgProfile(coverAndProfileImages.data2[0].profileImagePath);
            //     setImages(coverAndProfileImages.data2[0]);
            let coverAndProfileImages = await getuserDetailsbywalletNewFirebase(address);
            console.log("coverAndProfileImages", coverAndProfileImages);
                setImgCover(coverAndProfileImages[0]?.coverImagePath);
                setImgProfile(coverAndProfileImages[0]?.profileImagePath);
                setImages(coverAndProfileImages[0]);
        }catch(error){
            console.error(error);
        }
    }

    useEffect(() => {
        if(address != undefined && address != null && address != "")
        getImages();
    }, [address, isConnected])

    const coverPictureUpdate = async (getFileCovers) => {
        // let getProfileUpdate = await getuserDetailsbywalletNew(address);
        let getProfileUpdate = await getuserDetailsbywalletNewFirebase(address);
        let coverImage = await imageToIpfsHash(getFileCovers);
        setNewImgCover(coverImage);
        console.log("getProfileUpdate", getProfileUpdate);
        console.log("coverImage", coverImage);
        // let apiProfileUpdate = await putbgImagebywalletNew(getProfileUpdate.data2[0].firstName, getProfileUpdate.data2[0].lastName, getProfileUpdate.data2[0].userName, getProfileUpdate.data2[0].walletAddress, getProfileUpdate.data2[0].description, getProfileUpdate.data2[0].twitter, getProfileUpdate.data2[0].discord, getProfileUpdate.data2[0].youtube, getProfileUpdate.data2[0].website, coverImage, getProfileUpdate.data2[0].profileImagePath);
        let apiProfileUpdate = await putbgImagebywalletNewFirebase(getProfileUpdate[0].firstName, getProfileUpdate[0].lastName, getProfileUpdate[0].userName, getProfileUpdate[0].walletAddress, getProfileUpdate[0].description, getProfileUpdate[0].twitter, getProfileUpdate[0].discord, getProfileUpdate[0].youtube, getProfileUpdate[0].website, coverImage, getProfileUpdate[0].profileImagePath);
        if(apiProfileUpdate)
        {
            toast.success("Cover Image Update Successful");
            await getImages();
        }
        else
        {
            toast.error("Cover Image Update Unsuccessful");
        }
    }

    const profilePictureUpdate = async (getFileProfile) => {
        // let getProfileUpdate = await getuserDetailsbywalletNew(address);
        let getProfileUpdate = await getuserDetailsbywalletNewFirebase(address);
        let profileImage = await imageToIpfsHash(getFileProfile);
        setImgProfile(profileImage);
        // let apiProfileUpdate = await putbgImagebywalletNew(getProfileUpdate.data2[0].firstName, getProfileUpdate.data2[0].lastName, getProfileUpdate.data2[0].userName, getProfileUpdate.data2[0].walletAddress, getProfileUpdate.data2[0].description, getProfileUpdate.data2[0].twitter, getProfileUpdate.data2[0].discord, getProfileUpdate.data2[0].youtube, getProfileUpdate.data2[0].website, getProfileUpdate.data2[0].coverImagePath, profileImage);
        let apiProfileUpdate = await putbgImagebywalletNewFirebase(getProfileUpdate[0].firstName, getProfileUpdate[0].lastName, getProfileUpdate[0].userName, getProfileUpdate[0].walletAddress, getProfileUpdate[0].description, getProfileUpdate[0].twitter, getProfileUpdate[0].discord, getProfileUpdate[0].youtube, getProfileUpdate[0].website, getProfileUpdate[0].coverImagePath, profileImage);
        if(apiProfileUpdate)
        {
            toast.success("Profile Image Update Successful");
            await getImages();
        }
        else
        {
            toast.error("Profile Image Update Unsuccessful");
        }
    }

    const captureFile =async(event) => {
        console.log("start");
        event.stopPropagation()
        event.preventDefault()
        const file = event.target.files[0]
        setImgname(file.name)
        setFileCover(file)
        const MIN_FILE_SIZE = 1024 // 1KB
        const MAX_FILE_SIZE = 500120 // 500KB
        let fileSizeKiloBytes = file.size 
        let c=0;
        if(fileSizeKiloBytes < MIN_FILE_SIZE){
          toast.dismiss();
          toast.error("File size is less than minimum limit",{autoClose:3000});          
          c=c+1;                               
          await sleep(4000);
          window.location.reload(false)
        }
        if(fileSizeKiloBytes > MAX_FILE_SIZE){
          toast.dismiss();
          toast.error("File size is greater than maximum limit",{autoClose:3000});      
          c=c+1;
          await sleep(4000);                             
          window.location.reload(false)
        }        
        if(c===0){
            console.log("start2");
        let reader = new window.FileReader()
        try{
        Compress.imageFileResizer(file, 500, 500, 'JPEG', 200, 0,
        uri => {          
            setImgCover(uri)          
        },
        'base64'
        );
        console.log("start4");
        setImageValue(true);
        reader.readAsArrayBuffer(file)
        console.log("start5");
        await coverPictureUpdate(file);   
        console.log("start6");     
        }catch (err) {      
        }
        }else{
          toast.dismiss();
          toast.error("Support file size: 1 kb to 500 kb ", {autoClose:3000});                                               
          await sleep(4000);
          window.location.reload(false)
          
        }
        
    };

    const captureFileProfile =async(event) => {
        event.stopPropagation()
        event.preventDefault()
        const file = event.target.files[0]
        setImgname(file.name)
        setFileProfile(file)
        const MIN_FILE_SIZE = 1024 // 1KB
        const MAX_FILE_SIZE = 500120 // 500KB
        let fileSizeKiloBytes = file.size 
        let c=0;
        if(fileSizeKiloBytes < MIN_FILE_SIZE){
          toast.dismiss();
          toast.error("File size is less than minimum limit",{autoClose:3000});          
          c=c+1;                               
          await sleep(4000);
          window.location.reload(false)
        }
        if(fileSizeKiloBytes > MAX_FILE_SIZE){
          toast.dismiss();
          toast.error("File size is greater than maximum limit",{autoClose:3000});      
          c=c+1;
          await sleep(4000);                             
          window.location.reload(false)
        }        
        if(c===0){
        let reader = new window.FileReader()
        try{
        Compress.imageFileResizer(file, 500, 500, 'JPEG', 200, 0,
        uri => {          
            setImgProfile(uri)          
        },
        'base64'
        );
        setImageValue(true);
        reader.readAsArrayBuffer(file)   
        await profilePictureUpdate(file);     
        }catch (err) {      
        }
        }else{
          toast.dismiss();
          toast.error("Support file size: 1 kb to 500 kb ",{autoClose:3000});                                               
          await sleep(4000);
          window.location.reload(false)
          
        }
        
    };

    return(
        <>
         <ToastContainer position='bottom-right' draggable = {false} transition={Zoom} autoClose={4000} closeOnClick = {false}/>
            <div className="d-flex mb-40 d-md-none overflow-auto">
                <div className="inner-links w-100 flex-nowrap text-nowrap navbar-nav me-0 d-flex flex-row">
                    <NavLink className="nav-link" to="/profile/edit">Profile</NavLink>
                    <NavLink className="nav-link" to="/profile/account">Account</NavLink>
                    <NavLink className="nav-link" to="/profile/wallets">Wallets</NavLink>
                    <NavLink className="nav-link" to="/profile/notifications">Notifications</NavLink>
                </div>
            </div>

            {images === null || images === "" || images === undefined || images === "undefined" ?
            (<>
            <input type='file' id='uploadFile' hidden onChange = {captureFile}/>
            <label htmlFor='uploadFile' className='border-dark cursor-pointer mb-20 text-center justify-content-center d-flex b1' style={{height: '280px'}}>
                <div className='m-auto'>
                    <span className='border d-inline-block p-3'><img src={ImageIcon} alt="image_icon" className='d-block' /></span>
                    <p className='text-black mt-3'>Browse your computer<br /><span className='text-gray'>PNG, JPG. Max 500 KB.</span></p>
                </div>
            </label>  
            </>):(<>
            <input type='file' id='uploadFile' hidden onChange = {captureFile}/>
            <label htmlFor='uploadFile' className='border-dark cursor-pointer mb-20 text-center justify-content-center d-flex b1' style={{height: '280px'}}>
                <img src={imgCover} alt="imgCover" className='img-fluid w-100 h-100 object-cover w-90 rounded-16' />
            </label>
            </>)}
            <Row>
                <Col lg={3} className='ps-3 ps-lg-5 mb-lg-5 pb-lg-4 pb-3'>
                <Stack className='profile-stack align-items-end mb-lg-5 mb-3' direction='horizontal' gap={2}>
                    {images === null || images === "" || images === undefined || images === "undefined" ?
                    (<>
                        <input type='file' id='uploadProfileFile' hidden onChange = {captureFileProfile}/>
                        <label htmlFor='uploadProfileFile' className='border-dark cursor-pointer'>
                            <img src={AvatarPic} className='profile-pic' alt='Avatar' /> 
                        </label> 
                    </>):(<>
                            <input type='file' id='uploadProfileFile' hidden onChange = {captureFileProfile}/>
                            <label htmlFor='uploadProfileFile' className='border-dark cursor-pointer'>
                                <img src={imgProfile} className='profile-pic' alt='imgProfile' /> 
                            </label>
                    </>)}
                </Stack>

                    <Stack className="side-links text-nowrap d-none d-md-flex" direction='vertical' gap={3}>
                        <NavLink className="nav-link" to="/profile/edit/setting">Profile</NavLink>
                        <NavLink className="nav-link" to="/profile/nfts">Account</NavLink>
                        <NavLink className="nav-link" to="/profile/wallet">Wallets</NavLink>
                        <NavLink className="nav-link" to="/profile/activity">Activity</NavLink>
                    </Stack>
                </Col>    
                <Col lg={9}>
                    <Outlet />
                </Col>    
            </Row> 

        </>
    )
}