import { Link, useNavigate } from 'react-router-dom';
import CardImageV from '../asserts/IMAGES/card-bg-v.png';
import CardImageH from '../asserts/IMAGES/card-bg-h.png';
import CardImageH2 from '../asserts/IMAGES/card-bg-h-2.png';
import CardImageInfoDE from '../asserts/IMAGES/info-card-de.png';
import CardImageInfoCA from '../asserts/IMAGES/info-card.png';
import CardImageInfoWV from '../asserts/IMAGES/info-card-wv.png';
import CardShopImage from '../asserts/IMAGES/shop-image.png';
import CardIcon from '../asserts/IMAGES/card-icon.svg';
import CardIconDE from '../asserts/IMAGES/dime-outline-de.png';
import Avatar from '../asserts/IMAGES/avatar-pic.png';
import { async } from 'q';
import { Button } from 'react-bootstrap';
import { toast,ToastContainer  } from 'react-toastify';
import { useState, useEffect } from 'react';
import {Web3} from 'web3';
// import { abiLaunchpad } from '../pages/Launchpad/LaunchpadContract';
import { abiLaunchpad } from '../abi';
// import DivineLive from '../asserts/IMAGES/DivineLive.png'
// import WorldUpcoming  from '../asserts/IMAGES/WorldUpcoming.png'
import { useWeb3ModalAccount } from '@web3modal/ethers5/react';
import web3Instance from '../web3Inastance';


export function CardViewVertical({img,x}){
    return ( 
        <Link to={`/all?address=${x.walletAddress}`} className="card-view text-white card-view-vertical">
            <img src={img ? img : CardImageV} alt="card v" />
            <div className='card-view-vertical-info'>
                <h4 className='text-20 text-500'>{x ?.firstName}</h4>
                {/* <p className='text-500 text-white-07'>4 Rewards Left</p> */}
            </div>
        </Link>
     );
};

export function CardViewHorizontalNft({x,y},{mode, size}){
    return ( 
        <Link to={`/market/explore/shop/heritage?category=${y}`} className={`card-view text-${mode === 'light' ? 'dark' : 'white'} card-view-horizontal ${size ? 'card-view-'+size : ''}`}>
            <img src={mode === 'light' ? CardImageH2 : x} alt="card H" />
            <div className='card-view-horizontal-info d-flex'>
                <div className='m-auto'>
                    <img src={CardIcon} className='card-view-icon' alt="CardIcon" />
                    <h4 className='text-20 text-600'>{y}</h4>
                    <p className={`text-500 ${mode === 'light' ? '' : 'text-white-07'}`}>Collections</p>
                </div>
            </div>
        </Link>
     );
};

export function CardViewHorizontal({mode, size, number, contract, image, description, web3State}){

    const [endTime, setEndTime] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [contractStatus, setContractStatus] = useState(null);
    const [name2, setName2] = useState("")

    // const connectToEthereum = async () => {
    //     try {
    //       if (window.ethereum) {
    //         await window.ethereum.request({ method: 'eth_requestAccounts' });
    //         const web3 = new Web3(window.ethereum);
    //         return web3;
    //       } else {
    //         throw new Error('No Ethereum wallet found.');
    //       }
    //     } catch (error) {
    //       console.error(error);
    //       return null;
    //     }
    //   };

      function formatDate(epochTimestamp) {
        const dateObj = new Date(epochTimestamp * 1000); // Convert seconds to milliseconds
        const day = dateObj.getDate();
        const month = dateObj.toLocaleString('default', { month: 'short' }); // Get short month name
        const year = dateObj.getFullYear();
        return `${day} ${month} ${year}`;
      }
      

      const fun = async() => {

        const web3 = web3Instance;
        if (!web3) return;
        
        const launchpad = new web3.eth.Contract(abiLaunchpad, contract);
        let startTime1 = web3.utils.toNumber(await launchpad.methods.start_time().call());
        setStartTime(startTime1);

        let endTime1 = web3.utils.toNumber(await launchpad.methods.end_time().call());
        console.log(endTime1);
        setEndTime(formatDate(endTime1));
        console.log(endTime);

        let name1 = await launchpad.methods.name().call();
        setName2(name1)

        const currentTime = new Date().getTime() / 1000; // Get current time in seconds
      
        if (currentTime < startTime1) {
          setContractStatus("Coming Soon");
        } else if (currentTime >= startTime1 && currentTime <= endTime1) {
          setContractStatus("Active");
        } else {
          setContractStatus("Ended");
        }
      }

      useEffect(() => {
        fun();
    }, [contract])

    return ( 
        <Link
        to={{
          pathname: `/launchpad/shop`,
          search: `?name=${name2}&contract=${contract}&number=${number}&image=${image}&description=${description}`,
        }}
        className={`card-view text-${mode === 'light' ? 'dark' : 'white'} card-view-horizontal ${
          size ? 'card-view-' + size : ''
        }`}
      >
            {/* <img src={mode === 'light' ? CardImageH2 : CardImageH} alt="card H" /> */}
            {/* <img src={image === '1' ? CardImageH : ( image === '2' ? DivineLive : WorldUpcoming)  } alt="card H" /> */}
            <img src={image} alt="card H" />
            <div className='card-view-horizontal-info d-flex'>
                <div className='m-auto'>
                    <img src={CardIcon} className='card-view-icon' alt="CardIcon" />
                    <h4 className='text-20 text-600'>Divine Dimension New Year Special Collection {name2}</h4>
                    {contractStatus === "Active" ? (
          <p className={`text-500 ${mode === 'light' ? '' : 'text-white-07'}`}>{endTime}</p>
        ) : (
          <p className={`text-500 ${mode === 'light' ? '' : 'text-white-07'}`}>
            {contractStatus === "Coming Soon" ? "Coming Soon" : "Ended"}
          </p>
        )}
                </div>
            </div>
        </Link>
     );
};

export function CardViewHorizontalRoyalty({x,y},{mode, size}){
    return ( 
        <Link to={`/market/explore/shop/royalty?category=${y}`} className={`card-view text-${mode === 'light' ? 'dark' : 'white'} card-view-horizontal ${size ? 'card-view-'+size : ''}`}>
            <img src={mode === 'light' ? CardImageH2 : x} alt="card H" />
            <div className='card-view-horizontal-info d-flex'>
                <div className='m-auto'>
                    <img src={CardIcon} className='card-view-icon' alt="CardIcon" />
                    {/* <h4 className='text-20 text-600'>Divine Dimension New Year Special Collection</h4>
                    <p className={`text-500 ${mode === 'light' ? '' : 'text-white-07'}`}>4 Rewards Left</p> */}
                    <h4 className='text-20 text-600'>{y}</h4>
                    <p className={`text-500 ${mode === 'light' ? '' : 'text-white-07'}`}>Collections</p>
                </div>
            </div>
        </Link>
     );
};
export function CardViewHorizontalAuction({x,y},{mode, size}){
    return ( 
        <Link to={`/market/explore/shop/auction?category=${y}`} className={`card-view text-${mode === 'light' ? 'dark' : 'white'} card-view-horizontal ${size ? 'card-view-'+size : ''}`}>
            <img src={mode === 'light' ? CardImageH2 : x} alt="card H" />
            <div className='card-view-horizontal-info d-flex'>
                <div className='m-auto'>
                    <img src={CardIcon} className='card-view-icon' alt="CardIcon" />
                    {/* <h4 className='text-20 text-600'>Divine Dimension New Year Special Collection</h4>
                    <p className={`text-500 ${mode === 'light' ? '' : 'text-white-07'}`}>4 Rewards Left</p> */}
                    <h4 className='text-20 text-600'>{y}</h4>
                    <p className={`text-500 ${mode === 'light' ? '' : 'text-white-07'}`}>Collections</p>
                </div>
            </div>
        </Link>
     );
};

export function CardViewHorizontalDe({mode, size, text,phase,date, description}){
    const glowStyle = {
        textShadow: '0 0 5px #18748B, 0 0 10px #18748B, 0 0 15px #18748B, 0 0 20px #18748B',
        color: mode === 'light' ? 'black' : 'white'
    };
    const containerStyle = {
        backgroundColor: '#18748B',
        display: 'inline-block',
        padding: '10px',
        borderRadius: '10px' // Optional: If you want rounded corners
    };

    const imageStyle = {
        boxShadow: '0 0 5px #18748B, 0 0 10px #18748B, 0 0 15px #18748B, 0 0 20px #18748B',
        borderRadius: '10px' // Optional: If you want rounded corners
    };
    return ( 
        // <Link to={phase==="1"?`/nft-launchpad/divine-explorer/shop?phase=${phase}&description=${description}`:"#"} className={`card-view text-${mode === 'light' ? 'dark' : 'white'} card-view-horizontal ${size ? 'card-view-'+size : ''}`}>
        //     <img src={CardImageInfoDE} alt="card H" />
        //     <div className='card-view-horizontal-info d-flex'>
        //         <div className='m-auto'>
        //             <img src={CardIcon} className='card-view-icon' alt="CardIcon" />
        //             <h4 className='text-20 text-600'>{text}</h4>
        //             <p className={`b2 text-700 ${mode === 'light' ? '' : 'text-white'}`}>{date}</p>
        //         </div>
        //     </div>
        // </Link>
        <Link to={phase==="1"?`/nft-launchpad/divine-explorer/shop?phase=${phase}&description=${description}`:"#"} className={`card-view text-${mode === 'light' ? 'dark' : 'white'} card-view-horizontal ${size ? 'card-view-'+size : ''}`}>
            <img src={CardImageInfoDE} alt="card H" />
            <div className='card-view-horizontal-info d-flex'>
                <div className='m-auto' style={{containerStyle}}>
                <img src={CardIconDE} className='card-view-icon' alt="CardIcon" style={imageStyle} />
                    <h4 style={glowStyle} className='text-20 text-600'>{text}</h4>
                    <p style={glowStyle} className={`b2 text-700 ${mode === 'light' ? '' : 'text-white'}`}>{date}</p>
                </div>
            </div>
        </Link>

     );
};

export function CardViewHorizontalCa({mode, size, text,phase,date,description}){
    return ( 
        <Link to={phase==="1"?`/nft-launchpad/cultural-adventurer/shop?phase=${phase}&description=${description}`:"#"} className={`card-view text-${mode === 'light' ? 'dark' : 'white'} card-view-horizontal ${size ? 'card-view-'+size : ''}`} onClick={e => e.preventDefault()}>
            <img src={CardImageInfoCA} alt="card H" />
            <div className='card-view-horizontal-info d-flex'>
                <div className='m-auto'>
                    <img src={CardIcon} className='card-view-icon' alt="CardIcon" />
                    <h4 className='text-20 text-600'>{text}</h4>
                    <p className={`b2 text-700 ${mode === 'light' ? '' : 'text-white'}`}>{date}</p>
                </div>
            </div>
        </Link>
     );
};

export function CardViewHorizontalWv({mode, size, text,phase,date,description}){
    return ( 
        <Link to={phase==="1"?`/nft-launchpad/world-voyager/shop?phase=${phase}&description=${description}`:"#"} className={`card-view text-${mode === 'light' ? 'dark' : 'white'} card-view-horizontal ${size ? 'card-view-'+size : ''}`} onClick={e => e.preventDefault()}>
            <img src={CardImageInfoWV} alt="card H" />
            <div className='card-view-horizontal-info d-flex'>
                <div className='m-auto'>
                    <img src={CardIcon} className='card-view-icon' alt="CardIcon" />
                    <h4 className='text-20 text-600'>{text}</h4>
                    <p className={`b2 text-700 ${mode === 'light' ? '' : 'text-white'}`}>{date}</p>
                </div>
            </div>
        </Link>
     );
};

export function CardViewShop({x}){
    
    return ( 
       
        <Link to={{pathname: "/market/explore/shop/cart",search:`?id=${encodeURIComponent(JSON.stringify(x))}`}} className="card-shop">
            <img src={x.imagePath} className='card-shop-image rounded-clip' alt="Card Shop Image" />
            <div className='card-shop-info'>
                <div className='d-flex align-items-center text-truncate text-block mb-sm-3 mb-2 text-20 text-500'>
                    <img src={x.userProfile ? x.userProfile : Avatar} alt="Avatar" className='card-shop-avatar' />
                    {x.nftName}
                </div>
                <div className='d-flex text-14 text-500 text-block justify-content-between align-items-start'>
                    <div>
                        <p className='text-gray mb-sm-1 mb-0'>Current Price</p>
                        <p>{parseFloat(x.nftPrice/1000000000000000000).toFixed(4)} ETH</p>
                    </div>
                    {/* <div className='text-end'>
                        <p className='text-gray mb-sm-1 mb-0'>Buy now</p>
                        <p>12.21 ETH</p>
                    </div> */}
                </div>
            </div>
        </Link>
     );
};
export function CardViewShopAuction({x}){
    
    return ( 
       
        <Link to={{pathname: "/market/explore/shop/cart/auction",search:`?id=${encodeURIComponent(JSON.stringify(x))}`}} className="card-shop">
            <img src={x.imagePath} className='card-shop-image rounded-clip' alt="Card Shop Image" />
            <div className='card-shop-info'>
                <div className='d-flex align-items-center text-truncate text-block mb-sm-3 mb-2 text-20 text-500'>
                    <img src={x.userProfile ? x.userProfile : Avatar} alt="Avatar" className='card-shop-avatar' />
                    {x.nftName}
                </div>
                <div className='d-flex text-14 text-500 text-block justify-content-between align-items-start'>
                    <div>
                        <p className='text-gray mb-sm-1 mb-0'>Current Price</p>
                        <p>{parseFloat(x.nftPrice/1000000000000000000).toFixed(4)} ETH</p>
                    </div>
                    {/* <div className='text-end'>
                        <p className='text-gray mb-sm-1 mb-0'>Buy now</p>
                        <p>12.21 ETH</p>
                    </div> */}
                </div>
            </div>
        </Link>
     );
};

export function CardViewInfoDE({mode}){
    return ( 
        <Link to="/nft-launchpad/divine-explorer" className={`card-view text-${mode === 'light' ? 'dark' : 'white'} card-view-information`}>
            <img src={CardImageInfoDE} alt="card H" />
            <div className='card-view-information-info d-flex'>
                <div className='m-auto'>
                    <h4 className='text-22 text-500 mb-md-3 mb-2'>Divine Explorer NFT</h4>
                    <p className={`b2  text-${mode === 'light' ? 'dark' : 'white'}`}>Allows exclusive access to Divine PODS across the UNESCO sites to explore and visit different cultural and heritage sites from around the world in a fully immersive and interactive way.</p>
                </div>
            </div>
        </Link>
     );
};

export function CardViewInfoCA({mode}){
    return ( 
        <Link to="/nft-launchpad/cultural-adventurer" className={`card-view text-${mode === 'light' ? 'dark' : 'white'} card-view-information`}>
            <img src={CardImageInfoCA} alt="card H" />
            <div className='card-view-information-info d-flex'>
                <div className='m-auto'>
                    <h4 className='text-22 text-500 mb-md-3 mb-2'>Cultural Adventurer NFT</h4>
                    <p className={`b2  text-${mode === 'light' ? 'dark' : 'white'}`}>Allows exclusive access to Divine PODS across the UNESCO sites to explore and visit different cultural and heritage sites from around the world in a fully immersive and interactive way.</p>
                </div>
            </div>
        </Link>
     );
};

export function CardViewInfoWV({mode}){
    return ( 
        <Link to="/nft-launchpad/world-voyager" className={`card-view text-${mode === 'light' ? 'dark' : 'white'} card-view-information`}>
            <img src={CardImageInfoWV} alt="card H" />
            <div className='card-view-information-info d-flex'>
                <div className='m-auto'>
                    <h4 className='text-22 text-500 mb-md-3 mb-2'>World Voyager NFT</h4>
                    <p className={`b2  text-${mode === 'light' ? 'dark' : 'white'}`}>Allows exclusive access to Divine PODS across the UNESCO sites to explore and visit different cultural and heritage sites from around the world in a fully immersive and interactive way.</p>
                </div>
            </div>
        </Link>
     );
};

export function CardViewNFTS({x, address}){
    const navigate = useNavigate();
    console.log("x", x);
    // const choosePath = async(y)=>{
    //     const valueToPass = encodeURIComponent(JSON.stringify(y));
    //     navigate(`/market/explore/shop/checkout?value=${valueToPass}`);
        
    // }
    return ( 
        <div className="card-shop">
            <img src={x.imagePath} className='card-shop-image rounded-clip' alt="Card Shop Image" />
            <div className='card-shop-info pb-2'>
                <h4 className='b1 mb-2'>{x.nftName}</h4>
                <h6 className='b1 mb-2'>{x.nftName} #{x.escrowAddress}</h6>
                <p className='b2 mb-3 text-black'>{x.nftDescription} </p>
                <h8 className='b1 mb-2'>Price :{parseFloat(x.nftPrice/1000000000000000000).toFixed(4)}  ETH</h8>
                {/* <Button onClick={()=>choosePath(x)}>Make an offer</Button> */}
                {(address).toLowerCase() === (x.ownerAddress).toLowerCase() ?
                (<>
                <Link  to={{pathname: "/market/setprice",search:`?id=${encodeURIComponent(JSON.stringify(x))}`}}className='btn btn-light border w-100 b2'>Make an offer</Link>
                </>):
                (<></>)}
                
            </div>
        </div>
     );
};

export function CardViewMintNFT({x}){
    const navigate = useNavigate();
    console.log("x", x);
    // const choosePath = async(y)=>{
    //     const valueToPass = encodeURIComponent(JSON.stringify(y));
    //     navigate(`/market/explore/shop/checkout?value=${valueToPass}`);
        
    // }
    return ( 
        <div className="card-shop">
            <img src={x.imagePath} className='card-shop-image rounded-clip' alt="Card Shop Image" />
            <div className='card-shop-info pb-2'>
                <h4 className='b1 mb-2'>{x.nftName}</h4>
                <h6 className='b1 mb-2'>{x.nftName} #{x.escrowAddress}</h6>
                <p className='b2 mb-3 text-black'>{x.nftDescription} </p>
                {/* <Button onClick={()=>choosePath(x)}>Make an offer</Button> */}
                {localStorage.getItem("walletAddress") === x.ownerAddress ?
                (<>
                <Link  to={{pathname: "/market/setprice",search:`?id=${encodeURIComponent(JSON.stringify(x))}`}}className='btn btn-light border w-100 b2'>Make an offer</Link>
                </>):
                (<></>)}
                
            </div>
        </div>
     );
};


export function CardViewNFTSale({x}){
    const navigate = useNavigate();
    // const choosePath = async(y)=>{
    //     const valueToPass = encodeURIComponent(JSON.stringify(y));
    //     navigate(`/market/explore/shop/checkout?value=${valueToPass}`);
        
    // }
    return ( 
        <div className="card-shop">
            <img src={x.imagePath} className='card-shop-image rounded-clip' alt="Card Shop Image" />
            <div className='card-shop-info pb-2'>
                <h4 className='b1 mb-2'>{x.nftName}</h4>
                <h6 className='b1 mb-2'>{x.nftName} #{x.escrowAddress}</h6>
                <p className='b2 mb-3 text-black'>{x.nftDescription} </p>

                <h8 className='b1 mb-2'>Price :{parseFloat(x.nftPrice/1000000000000000000).toFixed(4)}  ETH</h8>
                {localStorage.getItem("walletAddress") === (x.ownerAddress).toLowerCase() ?
                (<>                
                </>):(<>
                {x.nftType === "Auction" ? (<>
                    <Link  to={{pathname: "/market/explore/shop/cart/auction",search:`?id=${encodeURIComponent(JSON.stringify(x))}`}}className='btn btn-light border w-100 b2'>Buy</Link>
                </>):(<>
                    <Link  to={{pathname: "/market/explore/shop/cart",search:`?id=${encodeURIComponent(JSON.stringify(x))}`}}className='btn btn-light border w-100 b2'>Buy</Link>
                </>)}
                </>)}
                {/* <Button onClick={()=>choosePath(x)}>Make an offer</Button> */}
                {/* <Link  to={{pathname: "/market/setprice",search:`?id=${encodeURIComponent(JSON.stringify(x))}`}}className='btn btn-light border w-100 b2'>Make an offer</Link> */}
            </div>
        </div>
     );
};