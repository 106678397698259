import Avatar from '../../../asserts/IMAGES/avatar-pic.png';
import Verified from '../../../asserts/IMAGES/verified-yellow-icon.svg';
import LinkIcon from '../../../asserts/IMAGES/Link-arrow.svg';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { useState,useEffect } from 'react';
import { getNFTDetailsByAddressTypeSale, getNFTDetailsByTypeSale } from '../../../awsdatafile';
import { getNFTDetailsByAddressTypeSaleFirebase } from '../../../FirebaseFunctions';

export function TableRow({count, status, x}) {

    
    const [userProfile, setuserProfile] = useState([""]);
    const [royaltyCount, setroyaltyCount] = useState();
    const [NormalCount, setNormalCount] = useState();
    const [AuctionCount, setAuctionCount] = useState();
    const [royaltyCountOnSale, setroyaltyCountOnSale] = useState();
    const [NormalCountOnSale, setNormalCountOnSale] = useState();
    const [AuctionCountOnSale, setAuctionCountOnSale] = useState();

    const getnormalNFtdetails = async() =>{
        // let royalty = await getNFTDetailsByAddressTypeSale(x.walletAddress,"Royalty","yes");
        // let Normal = await getNFTDetailsByAddressTypeSale(x.walletAddress,"Normal","yes");
        // let Auction = await getNFTDetailsByAddressTypeSale(x.walletAddress,"Auction","yes");
        let royalty = await getNFTDetailsByAddressTypeSaleFirebase(x.walletAddress,"Royalty","no");
        let Normal = await getNFTDetailsByAddressTypeSaleFirebase(x.walletAddress,"Normal","no");
        let Auction = await getNFTDetailsByAddressTypeSaleFirebase(x.walletAddress,"Auction","no");
        let royaltyOnSale = await getNFTDetailsByAddressTypeSaleFirebase(x.walletAddress,"Royalty","yes");
        let NormalOnSale = await getNFTDetailsByAddressTypeSaleFirebase(x.walletAddress,"Normal","yes");
        let AuctionOnSale = await getNFTDetailsByAddressTypeSaleFirebase(x.walletAddress,"Auction","yes");
        // console.log("length",(royalty.data2).length)
        // setroyaltyCount((royalty.data2).length);
        // setNormalCount((Normal.data2).length);
        // setAuctionCount((Auction.data2).length)
        console.log("length",(royalty).length)
        setroyaltyCountOnSale((royaltyOnSale).length);
        setNormalCountOnSale((NormalOnSale).length);
        setAuctionCountOnSale((AuctionOnSale).length);
        setroyaltyCount((royalty).length + (royaltyOnSale).length);
        setNormalCount((Normal).length + (NormalOnSale).length);
        setAuctionCount((Auction).length + (AuctionOnSale).length)
        // console.log("userprofile",userprofiles.data2)
        // console.log("reverse",(userprofiles.data2).reverse())
        // setuserProfile((userprofiles.data2))
    }
    useEffect(()=>{getnormalNFtdetails()},[]) 
    

    return(
        <div className="table-stats px-0 py-3 d-flex align-items-center text-black text-uppercase text-500 text-14">
            <div className="table-stats-count text-center">{count}</div>
            <div className="table-stats-collect flex-grow-1">
                <div className='d-flex table-stats-avatar align-items-center'>
                    <span className='position-relative me-3'>
                        <img src={x.profileImagePath} alt='Avatar' />
                        <img src={Verified} alt="Verified" />
                    </span>
                {x.userName}
                </div>
            </div>
            <div className="table-stats-cell"><center><span className='opacity-50'>{NormalCount?NormalCount:"0"} </span></center></div>
            <div className="table-stats-cell" 
            // style={{color: status === 'up' ? '#28B833' : '#E94949'}}
            ><center><span className='opacity-50'>{royaltyCount?royaltyCount:"0"}</span></center></div>
            <div className="table-stats-cell"> <center><span className='opacity-50'>{AuctionCount?AuctionCount:"0"}</span></center></div>
            {/* <div className="table-stats-cell"
            //  style={{color: status === 'up' ? '#28B833' : '#E94949'}}
            >-23.9%</div> */}
            <div className="table-stats-cell"><center><a href={`https://basescan.org/address/${x.walletAddress}`} target='_blank'> <h6>{x.walletAddress && (x.walletAddress).substring(0, 5)}....{x.walletAddress && (x.walletAddress).substring((x.walletAddress).length - 5)}</h6></a></center></div>
            <div className="table-stats-cell"><center><span className='opacity-50'>{(NormalCountOnSale ||royaltyCountOnSale || AuctionCountOnSale)? (AuctionCountOnSale + NormalCountOnSale + royaltyCountOnSale) :"0"}</span></center></div>
        </div>
    )
}

export function BoxCell({count, status, x}) {
    const [userProfile, setuserProfile] = useState([""]);
    const [royaltyCount, setroyaltyCount] = useState();
    const [NormalCount, setNormalCount] = useState();
    const [AuctionCount, setAuctionCount] = useState();
    const [royaltyCountOnSale, setroyaltyCountOnSale] = useState();
    const [NormalCountOnSale, setNormalCountOnSale] = useState();
    const [AuctionCountOnSale, setAuctionCountOnSale] = useState();

    const getnormalNFtdetails = async() =>{
        // let royalty = await getNFTDetailsByAddressTypeSale(x.walletAddress,"Royalty","yes");
        // let Normal = await getNFTDetailsByAddressTypeSale(x.walletAddress,"Normal","yes");
        // let Auction = await getNFTDetailsByAddressTypeSale(x.walletAddress,"Auction","yes");
        let royalty = await getNFTDetailsByAddressTypeSaleFirebase(x.walletAddress,"Royalty","no");
        let Normal = await getNFTDetailsByAddressTypeSaleFirebase(x.walletAddress,"Normal","no");
        let Auction = await getNFTDetailsByAddressTypeSaleFirebase(x.walletAddress,"Auction","no");
        let royaltyOnSale = await getNFTDetailsByAddressTypeSaleFirebase(x.walletAddress,"Royalty","yes");
        let NormalOnSale = await getNFTDetailsByAddressTypeSaleFirebase(x.walletAddress,"Normal","yes");
        let AuctionOnSale = await getNFTDetailsByAddressTypeSaleFirebase(x.walletAddress,"Auction","yes");
        // console.log("length",(royalty.data2).length)
        // setroyaltyCount((royalty.data2).length);
        // setNormalCount((Normal.data2).length);
        // setAuctionCount((Auction.data2).length)
        console.log("length",(royalty).length)
        setroyaltyCountOnSale((royaltyOnSale).length);
        setNormalCountOnSale((NormalOnSale).length);
        setAuctionCountOnSale((AuctionOnSale).length);
        setroyaltyCount((royalty).length + (royaltyOnSale).length);
        setNormalCount((Normal).length + (NormalOnSale).length);
        setAuctionCount((Auction).length + (AuctionOnSale).length)
        // console.log("userprofile",userprofiles.data2)
        // console.log("reverse",(userprofiles.data2).reverse())
        // setuserProfile((userprofiles.data2))
    }
    useEffect(()=>{getnormalNFtdetails()},[]) 
    return(
        <div className="text-black border py-2 px-3 mb-3 text-500 text-14">
            <div className='d-flex mb-md-3 mb-2 align-items-center'>
                <div className='d-flex flex-grow-1 table-stats-avatar align-items-center'>
                    <span className='position-relative me-3'>
                        <img src={x.profileImagePath} alt='Avatar' />
                        <img src={Verified} alt="Verified" />
                    </span>
                    {x.userName}
                </div>

                {/* <Link to="/"><img src={LinkIcon} alt="LinkIcon" /></Link> */}
            </div>

            <Row>
                <Col xs={6}>
                    <p className='b4 m-0'>Total Items</p>
                    <h3 className='m-0 text-20 text-700'> <span className='b4'>{(NormalCountOnSale ||royaltyCountOnSale || AuctionCountOnSale)? (AuctionCountOnSale + NormalCountOnSale + royaltyCountOnSale) :"0"}</span></h3>
                    {/* <p className='b4' style={{color: status === 'up' ? '#28B833' : '#E94949'}}>+61.7%</p> */}
                </Col>
                <Col xs={6}>
                    <p className='b4 m-0'>Address</p>
                    <h3 className='m-0 text-20 text-700'><a href={`https://basescan.org/address/${x.walletAddress}`} target='_blank'> <h6>{x.walletAddress && (x.walletAddress).substring(0, 5)}....{x.walletAddress && (x.walletAddress).substring((x.walletAddress).length - 5)}</h6></a></h3>
                    {/* <p className='b4' style={{color: status === 'up' ? '#28B833' : '#E94949'}}>+1.2%</p> */}
                </Col>
            </Row>
        </div>
    )
}