import Coinbase from "../../asserts/IMAGES/coinbase-icon.svg";
import Metamask from "../../asserts/IMAGES/metamask-icon.svg";
import Wallet from "../../asserts/IMAGES/walletConnect-icon.svg";
import { InjectedConnector } from "@web3-react/injected-connector";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk'
import Web3 from 'web3';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClockLoader } from 'react-spinners';
import { ethers } from 'ethers';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  IconButton,
  Grid,
  Alert
} from '@mui/material';
import { createprofileNew, getuserDetailsbywallet } from "../../awsdatafile";
import { createprofileNewFirebase, getuserDetailsbywalletNewFirebase } from "../../FirebaseFunctions";


export function Ethereum() {
  const [signInLoader, setSignInLoader] = useState(false);
  const [coinBaseLoader, setCoinBase] = useState(false);
  const [metamaskLoader, setMetamask] = useState(false);

  const handleSignInLoad = () => setSignInLoader(true);
  const handleSignInHide = () => setSignInLoader(false);

  const handleCoinBaseLoad = () => setCoinBase(true);
  const handleCoinBaseHide = () => setCoinBase(false);

  const handleMetamaskLoad = () => setMetamask(true);
  const handleMetamaskHide = () => setMetamask(false);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openCoinbase, setOpenCoinbase] = useState(false);

  const handleOpenCoinbase = () => {
    setOpenCoinbase(true);
  };

  const handleCloseCoinbase = () => {
    setOpenCoinbase(false);
  };

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

  //   const loginViaCoinBase=async()=>{
  //     if (typeof window.ethereum !== "undefined") {
  //         console.log("Coinbase is installed!");
  //         localStorage.setItem("walletName","Coinbase");
    
  //         const APP_NAME = 'Coinbase';
  //         const APP_LOGO_URL = 'https://example.com/logo.png';
  //         const DEFAULT_ETH_JSONRPC_URL =  'https://base-mainnet.public.blastapi.io';
  //         const DEFAULT_CHAIN_ID = 8453;

  //         if (typeof window.ethereum === "undefined") {
  //           handleCoinBaseHide();
  //           console.log("Coinbase is not installed.");
  //           handleOpenCoinbase();
  //           // window.open('https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad', '_blank', 'noreferrer');
  //           return false;
  //         }

  //         handleCoinBaseLoad();
  //         console.log("Coinbase is installed!");
  //         localStorage.setItem("walletName", "Coinbase");

  //         const coinbaseWallet = new CoinbaseWalletSDK({
  //               appName: APP_NAME,
  //               appLogoUrl: APP_LOGO_URL,
  //               darkMode: false
  //             });
          
  //             // Initialize a Web3 Provider object
  //             const ethereum = coinbaseWallet.makeWeb3Provider(DEFAULT_ETH_JSONRPC_URL, DEFAULT_CHAIN_ID);
  //             const web3 = new Web3(ethereum);

  //             const accounts = await provider.request({ method: 'eth_requestAccounts' });
  //             const account = accounts[0];
      
  //             console.log(`User's address is ${account}`);
  //     // setUserAddress(userAddress);
  //     web3.eth.defaultAccount = account;
      
  //       localStorage.setItem("walletAddress", accounts[0]);
  //       // await createprofileNew(accounts[0]);
  //       await createprofileNewFirebase(accounts[0]);
  //       console.log("account", account);
  //       window.location.reload();
  //       } 
  //       else {
  //         console.log("Coinbase is not installed.");
  //         await sleep(3000);
  //         window.open('https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad', '_blank','noreferrer');

  //       }
  //     const { ethereum } = window;
  //     return Boolean(ethereum && ethereum.Coinbase);

  // }

  const loginViaCoinBase = async () => {
    try {
      const APP_NAME = 'Coinbase';
      const APP_LOGO_URL = 'https://example.com/logo.png';
      const DEFAULT_ETH_JSONRPC_URL =  'https://base-mainnet.public.blastapi.io';
      const DEFAULT_CHAIN_ID = 8453;
  
      if (typeof window.ethereum === "undefined") {
        handleCoinBaseHide();
        console.log("Coinbase is not installed.");
        handleOpenCoinbase();
        // window.open('https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad', '_blank', 'noreferrer');
        return false;
      }
  
      handleCoinBaseLoad();
      console.log("Coinbase is installed!");
      localStorage.setItem("walletName", "Coinbase");
  
      const coinbaseWallet = new CoinbaseWalletSDK({
        appName: APP_NAME,
        appLogoUrl: APP_LOGO_URL,
        darkMode: false,
        overrideIsMetaMask: false
      });
      console.log("after sdk", coinbaseWallet);
      const provider = coinbaseWallet.makeWeb3Provider(DEFAULT_ETH_JSONRPC_URL, DEFAULT_CHAIN_ID);
      console.log("after provider", provider);
      const accounts = await provider.request({ method: 'eth_requestAccounts' });
      const account = accounts[0];
      console.log(`User's address is ${account}`);

      localStorage.setItem("walletAddress", account);
      // await createprofileNew(account);
      await createprofileNewFirebase(account);
      console.log("account", account);
      window.location.reload();
    } catch (err) {
      // Handle errors
      handleCoinBaseHide();
      console.error(err);
      return false;
    }
  };

  // const loginViaMetaMask = async () => {
  //   if (typeof window.ethereum !== "undefined") {
  //     try {
  //       console.log("MetaMask is installed!");
  
  //       // Call the function to handle loading
  //       handleMetamaskLoad();
  
  //       localStorage.setItem("walletName", "Metamask");
  
  //       const injectedConnector = new InjectedConnector({
  //         supportedChainIds: [8453],
  //       });
  
  //       const chainId = await window.ethereum.request({ method: 'net_version' });
  
  //       console.log("injectedConnector", injectedConnector);
  //       console.log(chainId);
  
  //       if (chainId !== '8453') {
  //         const request = {
  //           method: 'wallet_switchEthereumChain',
  //           params: [{ chainId: '0x2105' }],
  //         };
  
  //         try {
  //           await window.ethereum.send(request);
  //         } catch (switchError) {
  //           try {
  //             await window.ethereum.request({
  //               method: "wallet_addEthereumChain",
  //               params: [
  //                 {
  //                   chainId: '0x2105',
  //                   chainName: 'Base',
  //                   nativeCurrency: {
  //                     name: 'ETH',
  //                     symbol: 'ETH',
  //                     decimals: 18,
  //                   },
  //                   rpcUrls: ['https://mainnet.base.org/'],
  //                   blockExplorerUrls: ['https://basescan.org'],
  //                 },
  //               ],
  //             });
  //           } catch (error) {
  //             console.error("addEthereumChain", error);
  //           }
  //         }
  //       }
  
  //       const accounts = await window.ethereum.request({
  //         method: 'eth_requestAccounts',
  //       });
  
  //       const account = accounts[0];
  //       localStorage.setItem("walletAddress", account);
  
  //       // let newUserCheck = await getuserDetailsbywallet(account);
  //       let newUserCheck = await getuserDetailsbywalletNewFirebase(account);
  
  //       if (!newUserCheck) {
  //         // await createprofileNew(account);
  //         await createprofileNewFirebase(account);
  //       }
  //       console.log("account", account);
  //       handleMetamaskHide();
  //       window.location.reload();
  //     } catch (err) {
  //       if (err.code === 4001) {
  //         // EIP-1193 userRejectedRequest error
  //         // If this happens, the user rejected the connection request.
  //         console.log('Please connect to Wallet.');
  //       } else {
  //         console.error(err);
  //         handleMetamaskHide();
  //       }
  //     }
  //   } else {
  //     handleMetamaskHide();
  //     console.log("MetaMask is not installed.");
  //     window.open(
  //       'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
  //       '_blank',
  //       'noreferrer'
  //     );
  //   }
  // };

  const loginViaMetaMask = async () => {
    if (typeof window.ethereum !== "undefined") {
      try {
        console.log("MetaMask is installed!");
  
        handleMetamaskLoad();
  
        localStorage.setItem("walletName", "Metamask");
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const network = await provider.getNetwork();
        const chainId = network.chainId;
  
        console.log("injectedConnector", provider);
        console.log(chainId);
  
        if (chainId !== 8453) {
          try {
            // Switch to the desired chain ID
            await provider.send("wallet_switchEthereumChain", [{ chainId: '0x2105' }]);
          } catch (switchError) {
            try {
              // Add the Ethereum chain if it doesn't exist
              await provider.send("wallet_addEthereumChain", [
                {
                  chainId: '0x2105',
                  chainName: 'Base',
                  nativeCurrency: {
                    name: 'ETH',
                    symbol: 'ETH',
                    decimals: 18,
                  },
                  rpcUrls: ['https://mainnet.base.org/'],
                  blockExplorerUrls: ['https://basescan.org'],
                },
              ]);
            } catch (error) {
              console.error("addEthereumChain", error);
            }
          }
        }
  
        // Request Ethereum accounts
        const accounts = await provider.send("eth_requestAccounts", []);
  
        const account = accounts[0];
        localStorage.setItem("walletAddress", account);
  
        // let newUserCheck = await getuserDetailsbywallet(account);
        let newUserCheck = await getuserDetailsbywalletNewFirebase(localStorage.getItem("walletAddress"));
  
        if (newUserCheck.length == 0) {
          // await createprofileNew(account);
          await createprofileNewFirebase(localStorage.getItem("walletAddress"));
        }
        console.log("account", account);
        handleMetamaskHide();
        window.location.reload();
      } catch (err) {
        if (err.code === 4001) {
          // EIP-1193 userRejectedRequest error
          // If this happens, the user rejected the connection request.
          console.log('Please connect to Wallet.');
        } else {
          console.error(err);
          handleMetamaskHide();
        }
      }
    } else {
      handleMetamaskHide();
      toast.error("Please Connect your Wallet to continue");
      console.log("MetaMask is not installed.");
      handleOpen();
      // window.open(
      //   'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
      //   '_blank',
      //   'noreferrer'
      // );
    }
  };

  const handleMetamask = () => {
    window.open(
      'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
      '_blank',
      'noreferrer'
    );
}

const handleCoinbase = () => {
  window.open(
    'https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad', 
    '_blank', 
    'noreferrer'
  );
}


  useEffect(() => {
      localStorage.clear();
  }, [])

    return(

      <>
      <div>
          {/* <p className="b4">Popular</p> */}

          {/* <Button variant="white" className="btn-icon d-flex align-items-center py-0 w-100 mb-3 h-56" onClick={() => loginViaCoinBase()}><img src={Coinbase} className="me-3 w-32" alt="Coinbase" /> Coinbase Wallet</Button>
          <Button variant="white" className="btn-icon d-flex align-items-center py-0 w-100 mb-3 h-56" onClick={() => loginViaMetaMask()}><img src={Metamask} className="me-3 w-32" alt="Metamask"/> MetaMask</Button>   */}
          {coinBaseLoader ? <>
              <Button variant="white" className="d-block w-100 mb-2" ><center><ClockLoader size={25} /></center></Button>
          </> : <>
              <Button variant="white" className="btn-icon icon-coinbase w-100 mb-3" onClick={loginViaCoinBase}>Coinbase</Button>
          </>}
          
          {metamaskLoader ? <>
              <Button variant="white" className="d-block w-100 mb-2" ><center><ClockLoader size={25} /></center></Button>
          </> : <>
              <Button variant="white" className="btn-icon icon-metamask w-100 mb-3" onClick={loginViaMetaMask}>Metamask</Button>
          </>}          
         {/* <Button variant="white" className="btn-icon d-flex align-items-center py-0 w-100 mb-4 h-56"><img src={Wallet} className="me-3 w-32" alt="Wallet" /> WalletConnect</Button>
          <Button variant="white" className="btn-icon w-100 mb-3 mb-4">Show more</Button> */}
      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle className="mb-4">
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ position: 'absolute', right: 25, top: 10 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ overflow: 'hidden', maxHeight: '80vh', maxWidth: '100vw' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} className="border-right">
              <Typography variant="h5" className="mb-4">What is MetaMask Wallet?</Typography>
              <DialogContentText>
                MetaMask is a free web and mobile crypto wallet that allows users to store and swap cryptocurrencies, interact with the Ethereum blockchain ecosystem, and host a growing array of decentralized applications (dApps). It is one of the most widely used crypto applications in the world.
              </DialogContentText>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" className="mb-4">Try the MetaMask Wallet browser extension</Typography>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                <Button onClick={handleMetamask} variant="contained" color="primary" style={{ textAlign: 'center' }} className="mb-4">
                  Install
                </Button>
                <Typography variant="body2" color="textSecondary">
                  Please reload the tab after MetaMask extension is successfully installed. And don't forget to create a wallet in the MetaMask extension.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={openCoinbase} onClose={handleCloseCoinbase} maxWidth="sm" fullWidth>
        <DialogTitle className="mb-4">
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseCoinbase}
            aria-label="close"
            sx={{ position: 'absolute', right: 25, top: 10 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ overflow: 'hidden', maxHeight: '80vh', maxWidth: '100vw' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} className="border-right">
              <Typography variant="h5" className="mb-4">What is Coinbase Wallet?</Typography>
              <DialogContentText>
              Coinbase Wallet is a self-custody wallet that gives you complete control of your crypto. This means that the private keys (that represent ownership of the cryptocurrency) for your Wallet are stored directly on your mobile device and not with a centralized exchange like Coinbase.com.
              </DialogContentText>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" className="mb-4">Try the Coinbase Wallet browser extension</Typography>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                <Button onClick={handleCoinbase} variant="contained" color="primary" style={{ textAlign: 'center' }} className="mb-4">
                  Install
                </Button>
                <Typography variant="body2" color="textSecondary" className="mb-4">
                  Please reload the tab after Coinbase extension is successfully installed. And don't forget to create a wallet in the Coinbase extension.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
    )
}