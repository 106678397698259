// import 'rsuite/dist/rsuite.min.css';
import './asserts/FONTS/fonts.css';
import './app.scss';
import LogIn from './pages/Login';
import React, { useEffect, useState } from 'react';
import { Web3Provider } from './Web3Context.js';
import { Route, Routes, useNavigate } from 'react-router-dom';
import CreateAccount from './pages/Create-account';
import ResetPasswordSubmit from './pages/ResetPasswordSubmit';
import Signup from './pages/Signup';
import AvatarMain from './pages/Avatar/AvatarMain';
import Avatar from './pages/Avatar/Avatar';
import Badges from './pages/Avatar/Badges';
import CreateAvatarMain from './pages/Avatar/CreateAvatarMain';
import CreateAvatar from './pages/Avatar/Create';
import EventMain from './pages/Events/EventMain';
import EventAvailable from './pages/Events/EventAvailableNow';
import EventApproval from './pages/Events/EventApproval';
import EventSingle from './pages/Events/EventSingle';
import CreateEvent from './pages/Events/CreateEvent';
import Marketplace from './pages/Marketplace/Index';
import MarketExplore from './pages/Marketplace/Explore';
import MarketExploreSingle from './pages/Marketplace/ExploreSingle';
import MarketReligious from './pages/Marketplace/Religious';
import MarketReligiousHindu from './pages/Marketplace/Hindu';
import MarketReligiousHinduPeople from './pages/Marketplace/People';
import MarketReligiousHinduHolyPlaces from './pages/Marketplace/HolyPlaces';
import MarketExploreShop from './pages/Marketplace/ExploreShop';
import MarketExploreShopRoyalty from './pages/Marketplace/ExploreShopRoyalty';
import MarketExploreShopRoyaltyAuction from './pages/Marketplace/ExploreShopRoyaltyAuction';
import MarketExploreCart from './pages/Marketplace/ExploreCart';
import MarketExploreCartAuction from './pages/Marketplace/ExploreCartAuction';
import Fundraising from './pages/AllUsers/Collection.js';
import MarketExploreCheckout from './pages/Marketplace/ExploreCheckout';
import Setprice from './pages/Marketplace/SetPrice';
import MarketCreate from './pages/Marketplace/Create';
import MarketMint from './pages/Marketplace/Mint';
import MarketMintCrossmint from './pages/Marketplace/MintCrossmint';
import CreateLaunchpad from './pages/Launchpad/CreateLaunchpad';
import CustomLaunchpad from './pages/Launchpad/CustomLaunchpad';
import Launchpad from './pages/Launchpad/launchpad';
import LaunchpadShop from './pages/Launchpad/LaunchpadShop';
import LaunchpadIndex from './pages/Launchpad/Index';
import NFTLaunchpadIndex from './pages/NFTLaunchpad/Index';
import NFTLaunchpadDe from './pages/NFTLaunchpad/NFTlaunchpadDe';
import NFTLaunchpadCa from './pages/NFTLaunchpad/NFTlaunchpadCa';
import NFTLaunchpadWv from './pages/NFTLaunchpad/NFTlaunchpadWv';
import NFTDivineExplorer from './pages/NFTLaunchpad/NFTDivineExplorer';
import NFTShop from './pages/NFTLaunchpad/NFTShop';
import Stats from './pages/Stats/Index';
import Admin from './pages/Admin/Index';
import ProfileIndex from './pages/Profile/Index';
import Profile from './pages/Profile/Profile';
import Nfts from './pages/Profile/components/Nfts';
import NftsAll from './pages/Profile/components/NftsAllUser';
import AllProfile from './pages/AllUsers/Profile';
import AllSales from './pages/AllUsers/Sales';
import AllProperties from './pages/AllUsers/Properties';
import Allusers from './pages/AllUsers/Index';
import Allactivity from './pages/AllUsers/Activity';
import Sales from './pages/Profile/components/Sales';
import Properties from './pages/Profile/components/Properties';
import Activity from './pages/Profile/components/Activity';
import Favourite from './pages/Profile/components/Favourite';
import Collections from './pages/Profile/components/Collections';
import Wallet from './pages/Profile/components/Wallet';
import ProfileEdit from './pages/Profile/Edit';
import ProfileSetting from './pages/Profile/components/ProfileSetting';
import Account from './pages/Profile/components/Account';
import Notifications from './pages/Profile/components/Notifications';
import MyAvatar from './pages/Avatar/MyAvatars';
import VirtualTourism from './pages/Marketplace/VirtualTourism';
import Tourism from './pages/VirtualTourism/Index';
import StakeIndex from './pages/NFTStaking/index';
import Staking from './pages/NFTStaking/Staking';
import CustomStaking from './pages/NFTStaking/CustomStaking';
import FanToken from './pages/NFTStaking/FanToken.js';
import MapPage from './pages/Map/Map';
import Map from './pages/Map/Index';
import { ethers } from 'ethers';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import { useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { dimeContract as contracterc20 } from './address';
import { abierc20 } from './abi.js';

const projectId = 'a0566b417a74c151a64e8e2f9c911652'

const metadata = {
  name: 'Divine Dimension',
  description: 'My Website description',
  url: 'https://www.divinedimension.io/',
  icons: ['https://testnet.divinedimension.io/static/media/logo-icon.cdce6297c02ec9b165cd0f543ffb50ef.svg']
}
const mainnet = {
  chainId: 8453,
  name: 'Base',
  currency: 'ETH',
  explorerUrl: 'https://basescan.org/',
  rpcUrl: 'https://base-rpc.publicnode.com/'
}
createWeb3Modal({
  ethersConfig: defaultConfig({ 
    metadata,
    defaultChainId: 8453,
    enableEIP6963: true,
    enableInjected: true,
    enableCoinbase: true,
    rpcUrl: 'https://base-rpc.publicnode.com/'
  }),
  chains: [mainnet],
  projectId
})

function App() {

  const { address, chainId, isConnected } = useWeb3ModalAccount();

  const [balances, setBalances] = useState({
    dime: 0.00,
    eth: 0.00
  });

  const balanceOfTokens = async () => {
    if (isConnected) {
      try {
        // const provider = ethers.getDefaultProvider(Network_Name, {
        //   etherscan: API_KEY});
        const provider = ethers.getDefaultProvider('https://base-rpc.publicnode.com/');

        // const dime = await getTokenBalance(address, DIME_Token_Address, DIME_Token_ABI);
        const eth = await provider.getBalance(address);
        
        // let dimeBalance = ethers.utils.formatUnits(dime, 9);
        let etherBalance = ethers.utils.formatUnits(eth, 18);

            // const signer = provider.getSigner();
        // const erc20token = new web3.eth.Contract(abierc20, "0xf35A6D2F0f08638e6f09473Dd5f40e69b4889a8C");
        const erc20token = new ethers.Contract(contracterc20,abierc20, provider);

        const dime = await erc20token.balanceOf(address);

        let dimeBalance = ethers.utils.formatUnits(dime, 18);

        setBalances({
          dime: parseFloat(dimeBalance).toFixed(2),
          eth: parseFloat(etherBalance).toFixed(4)
        });

        console.log("Balances updated:", balances);
      } catch (error) {
        console.error("Error fetching token balances:", error);
      }
    }
    else{
      setBalances({
        dime: 0.00,
        eth: 0.00
      });
    }
  };

  useEffect(() => {
    balanceOfTokens();
}, [isConnected, address]);

  return (
    <Web3Provider>
    <div className="App-main">
      <Routes>
        <Route path="/login" element={ <LogIn /> } />
        <Route path="/" element={ <NFTLaunchpadIndex ethBalance = {balances.eth} dimeBalance = {balances.dime}/> }>
        {/* <Route path="/" element={ <Marketplace ethBalance = {ethBalance} dimeBalance = {dimeBalance}/> }> */}
          {/* <Route index element={<MarketExploreShopRoyalty />} /> */}
          <Route index element={<NFTDivineExplorer />} />
        </Route>
        {/* <Route path="/forgot-password" element={ <CreateAccount /> } />
        <Route path="/reset-submission" element={ <ResetPasswordSubmit /> } />
        <Route path="/signup" element={ <Signup /> } /> */}
        {/* <Route path="avatar" element={ <AvatarMain /> }>
          <Route index element={<Avatar />} />
          <Route path='/avatar/badges' element={<Badges />} />
        </Route> */}
        <Route path="/avatars" element={ <CreateAvatarMain /> }>
          <Route path="/avatars/my-avatars" element={<MyAvatar />} />
          <Route path='/avatars/create' element={<CreateAvatar />} />
        </Route>
        <Route path="/event" element={ <EventMain ethBalance = {balances.eth} dimeBalance = {balances.dime}/> }>
          <Route index element={<EventAvailable />} />
          <Route path='/event/approval' element={<EventApproval />} />
          <Route path='/event/:slug' element={<EventSingle />} />
          <Route path='/event/create' element={<CreateEvent />} />
        </Route>
        <Route path="/market" element={ <Marketplace ethBalance = {balances.eth} dimeBalance = {balances.dime}/> }>
          <Route path="/market/explore" element={<MarketExplore />} />
          <Route path="/market/create" element={<MarketCreate />} />
          <Route path="/market/mint" element={<MarketMint />} />
          <Route path="/market/crossmint" element={<MarketMintCrossmint />} />
          <Route path="/market/explore/single" element={<MarketExploreSingle />} />
          <Route path="/market/explore/shop/heritage" element={<MarketExploreShop />} />
          <Route path="/market/explore/shop/royalty" element={<MarketExploreShopRoyalty />} />
          <Route path="/market/explore/shop/auction" element={<MarketExploreShopRoyaltyAuction />} />
          <Route path="/market/explore/shop/cart" element={<MarketExploreCart />} />
          <Route path="/market/explore/shop/cart/auction" element={<MarketExploreCartAuction />} />
          <Route path="/market/explore/shop/checkout" element={<MarketExploreCheckout />} />
          <Route path="/market/setprice" element={<Setprice />} />
          <Route path="/market/explore/religious" element={<MarketReligious />}>
            <Route path="/market/explore/religious/hindu" element={<MarketReligiousHindu />}>
              <Route path="/market/explore/religious/hindu/people" element={<MarketReligiousHinduPeople />} />
              <Route path="/market/explore/religious/hindu/holy-places" element={<MarketReligiousHinduHolyPlaces />} />
            </Route>
          </Route>
        </Route>
        <Route path="/tourism" element={ <Tourism ethBalance = {balances.eth} dimeBalance = {balances.dime}/> }>
          <Route path="/tourism/explore" element={<VirtualTourism />} />
          </Route>
        <Route path="/map" element={ <Map ethBalance = {balances.eth} dimeBalance = {balances.dime}/> }>
          <Route path="/map/explore" element={<MapPage />} />
        </Route>
        <Route path="/launchpad" element={ <LaunchpadIndex ethBalance = {balances.eth} dimeBalance = {balances.dime}/> }>
          <Route index element={<Launchpad />} />
          <Route path='/launchpad/create' element={<CreateLaunchpad />} />
          <Route path='/launchpad/custom' element={<CustomLaunchpad />} />
          <Route path='/launchpad/shop' element={<LaunchpadShop />} />
        </Route>
        <Route path="/nft-launchpad" element={ <NFTLaunchpadIndex ethBalance = {balances.eth} dimeBalance = {balances.dime}/> }>
          <Route index element={<NFTDivineExplorer />} />
          <Route path='/nft-launchpad/divine-explorer' element={<NFTLaunchpadDe />} />
          <Route path='/nft-launchpad/cultural-adventurer' element={<NFTLaunchpadCa />} />
          <Route path='/nft-launchpad/world-voyager' element={<NFTLaunchpadWv />} />
          <Route path='/nft-launchpad/divine-explorer/shop' element={<NFTShop name="De"/>} />
          <Route path='/nft-launchpad/cultural-adventurer/shop' element={<NFTShop name="Ca"/>} />
          <Route path='/nft-launchpad/world-voyager/shop' element={<NFTShop name="Wv"/>} />
        </Route>
        <Route path="/NFTstaking" element={<StakeIndex />}>
            <Route index element={<Staking />} />
            <Route path="/NFTstaking/custom" element={<CustomStaking />} />
            <Route path="/NFTstaking/fan-token" element={<FanToken />} />
        </Route>
        <Route path="/stats" element={ <Stats ethBalance = {balances.eth} dimeBalance = {balances.dime}/> } />
        <Route path="/admin" element={ <Admin ethBalance = {balances.eth} dimeBalance = {balances.dime}/> } />
        <Route path="/all" element={<Allusers ethBalance = {balances.eth} dimeBalance = {balances.dime}/>} >
           <Route path="/all" element={<AllProfile />} >
          <Route path="/all/nfts" element={<NftsAll />} />
          <Route path="/all/sales" element={<AllSales />} />
          <Route path="/all/properties" element={<AllProperties />} />
          <Route path="/all/activity" element={<Allactivity />} />
          <Route path="/all/fundraising" element={<Fundraising />} />
          </Route>
        </Route>

        <Route path="/profile" element={ <ProfileIndex ethBalance = {balances.eth} dimeBalance = {balances.dime}/> }>
          <Route path="/profile" element={<Profile />}>
            <Route path="/profile/nfts" element={<Nfts />} />
           
            <Route path="/profile/sales" element={<Sales />} />
            <Route path="/profile/properties" element={<Properties />} />
            <Route path="/profile/activity" element={<Activity />} />
            <Route path="/profile/favourite" element={<Favourite />} />
            <Route path="/profile/wallet" element={<Wallet ethBalance = {balances.eth} dimeBalance = {balances.dime}/>} />
            <Route path="/profile/collections" element={<Collections />} />
          </Route>
          <Route path="edit" element={<ProfileEdit />}>
            <Route path="/profile/edit/setting" element={<ProfileSetting />} />
            <Route path="/profile/edit/account" element={<Account />} />
            <Route path="/profile/edit/notifications" element={<Notifications />} />
          </Route>
        </Route>
      </Routes>
    </div>
    </Web3Provider>
  );
}

export default App;
