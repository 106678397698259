import { useState } from 'react';
import Verified from '../../../asserts/IMAGES/verified-yellow-icon.svg';
import { Col, Modal, Row } from 'react-bootstrap';
import { putEventStatus } from '../../../awsdatafile';
import { ToastContainer, Zoom, toast} from 'react-toastify';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Grid, Paper } from '@mui/material';
import { putEventStatusFirebase } from '../../../FirebaseFunctions';
import { useNavigate } from 'react-router-dom';

export function TableRow({count, status, x}) {

    const [show, setShow] = useState(false); 
    const [showReject, setShowReject] = useState(false);
    const [isApproved , setIsApproved] = useState();

    const navigate = useNavigate();

    const handleOpen = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };

    const handleOpenReject = () => {
        setShowReject(true);
    };

    const handleCloseReject = () => {
        setShowReject(false);
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
      setModalIsOpen(true);
      console.log("more info open")
    };
  
    const closeModal = () => {
      setModalIsOpen(false);
      console.log("more info close")
    };

    console.log("more info", x);

    const handleYesClick = async (id) => {
        // const [value, data] = await putEventStatus(id, "Yes", x.name, x.description, x.rewards, x.walletAddress, x.networkName);
        const [value, data] = await putEventStatusFirebase(id, "Yes", x.name, x.description, x.rewards, x.walletAddress, x.networkName);
        if(value)
        {
            toast.success("Fundraising approved!");
            setIsApproved(true)
        }
        else
            toast.error("approval error!")
        setShow(false);
    };

    const handleYesClickReject = async (id) => {
        // const [value, data] = await putEventStatus(id, "Reject");
        const [value, data] = await putEventStatusFirebase(id, "Reject");
        if(value)
        {
            toast.success("Fundraising Rejected!");
            setIsApproved(false)
        }
        else
            toast.error("Rejection error!")
        setShowReject(false);
    };

    const handleNameClick = () => {
      navigate('/event');
    };

    return(
        <>
        <ToastContainer position='bottom-right' draggable = {false} transition={Zoom} autoClose={4000} closeOnClick = {false}/>
        <div className="table-stats px-0 py-3 d-flex align-items-center text-black text-uppercase text-500 text-14">
            <div className="table-stats-count d-flex">{count}</div>
            <div className="table-stats-collect flex-grow-1">
                <div className='d-flex table-stats-avatar align-items-center'>
                <span onClick={handleNameClick} style={{cursor: 'pointer'}}>
                    <span className='position-relative me-3'>
                        <img src={x.imagePath} alt='Avatar' />
                        <img src={Verified} alt="Verified" />
                    </span>
                {(x.name).slice(0, 20)}{x.name.length > 20 ? '...' : ''}</span>
                </div>
            </div>
            <div className="table-stats-cell d-flex"><span className='opacity-50'>{x.status} </span></div>
            <div className="table-stats-cell d-flex"><a href={`https://basescan.org/address/${x.email}`} target='_blank'> <h6>{x.email && (x.email).substring(0, 5)}....{x.email && (x.email).substring((x.email).length - 5)}</h6></a></div>
            <div className="table-stats-cell d-flex"> <span className='opacity-50'>{x.approvalStatus  === "No" ? "Pending" : x.approvalStatus === "Yes" ? "Approved" : "Rejected"}</span></div>
            {/* <div className="table-stats-cell"
            //  style={{color: status === 'up' ? '#28B833' : '#E94949'}}
            >-23.9%</div> */}
            <div className="table-stats-cell d-flex"><a href={`https://basescan.org/address/${x.walletAddress}`} target='_blank'> <h6>{x.walletAddress && (x.walletAddress).substring(0, 5)}....{x.walletAddress && (x.walletAddress).substring((x.walletAddress).length - 5)}</h6></a></div>
            <div className="table-stats-cell d-flex"><Button style={{fontSize: '12px'}} variant="outlined" color="secondary" onClick={openModal}>More info</Button></div>
            {x.approvalStatus === "Reject" || isApproved === false? <>
            <div className="table-stats-cell d-flex">
              <center>
                <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                  <Button disabled style={{ fontSize: '12px' }} variant="outlined" color="success">
                    Approve
                  </Button>
                  <Button style={{ fontSize: '12px' }} variant="outlined" color="error">
                    Rejected
                  </Button>
                </div>
              </center>
            </div>
            </> : (x.approvalStatus === "Yes" || isApproved === true) ? <>
            <center>
                <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                  <Button style={{ fontSize: '12px' }} variant="outlined" color="success">
                    Approved
                  </Button>
                  <Button disabled style={{ fontSize: '12px' }} variant="outlined" color="error">
                    Reject
                  </Button>
                </div>
              </center>
            </> : <>
            <div className="table-stats-cell d-flex">
              <center>
                <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                  <Button style={{ fontSize: '12px' }} variant="outlined" color="success" onClick={handleOpen}>
                    Approve
                  </Button>
                  <Button style={{ fontSize: '12px' }} variant="outlined" color="error" onClick={handleOpenReject}>
                    Reject
                  </Button>
                </div>
              </center>
            </div>
            </>}
        </div>
        <Dialog open={show} onClose={handleClose}>
          <DialogTitle>You wish to approve this fund raising?</DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions className='justify-content-center'>
            <Button color="error" className='me-2' onClick={handleClose}>
              No
            </Button>
            {/* <Button color="success" onClick={() => handleYesClick(x.keyId)}> */}
            <Button color="success" onClick={() => handleYesClick(x.id)}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={showReject} onClose={handleCloseReject}>
          <DialogTitle>You wish to reject this fund raising?</DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions className='justify-content-center'>
            <Button color="error" className='me-2' onClick={handleCloseReject}>
              No
            </Button>
            {/* <Button color="success" onClick={() => handleYesClickReject(x.keyId)}> */}
            <Button color="success" onClick={() => handleYesClickReject(x.id)}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={modalIsOpen} onClose={closeModal} maxWidth="md">
          <DialogTitle>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="h6">{x.name}</Typography>
              <Button onClick={closeModal} color="primary">
                Close
              </Button>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <img src={x.imagePath} alt={x.name} style={{ maxWidth: '100%', height: 'auto' }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper elevation={3} style={{ padding: '20px', maxWidth: '500px', margin: 'auto' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6">{x.name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Created Date & Time:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">{x.createdTime}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Creator Wallet Address:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" style={{ wordWrap: 'break-word' }}>
                        {x.walletAddress}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Description:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" style={{ wordWrap: 'break-word' }}>
                        {x.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Contract Address:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" style={{ wordWrap: 'break-word' }}>
                        {x.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Required Amount:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">{x.rewards}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Starting Time:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">{x.startTime}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Ending Time:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">{x.endTime}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Category:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">{x.status}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Crossmint Enabled:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">{x.crossmint}</Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        </>
    )
}

export function BoxCell({count, status, x}) {
    const [show, setShow] = useState(false); 
    const [showReject, setShowReject] = useState(false);

    const handleOpen = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };

    const handleOpenReject = () => {
        setShowReject(true);
    };

    const handleCloseReject = () => {
        setShowReject(false);
    };

    const handleYesClick = async (id) => {
        // const [value, data] = await putEventStatus(id, "Yes");
        const [value, data] = await putEventStatusFirebase(id, "Yes");
        if(value)
            toast.success("Fundraising approved!")
        else
            toast.error("approval error!")
        setShow(false);
    };

    const handleYesClickReject = async (id) => {
        // const [value, data] = await putEventStatus(id, "Reject");
        const [value, data] = await putEventStatusFirebase(id, "Reject");
        if(value)
            toast.success("Fundraising Rejected!")
        else
            toast.error("Rejection error!")
        setShow(false);
    };
    return(
        <>
        <div className="text-black border py-2 px-3 mb-3 text-500 text-14">
            <div className='d-flex mb-md-3 mb-2 align-items-center'>
                <div className='d-flex flex-grow-1 table-stats-avatar align-items-center'>
                    <span className='position-relative me-3'>
                        <img src={x.imagePath} alt='Avatar' />
                        <img src={Verified} alt="Verified" />
                    </span>
                    {x.name}
                </div>

                {/* <Link to="/"><img src={LinkIcon} alt="LinkIcon" /></Link> */}
            </div>

            <Row>
                <Col xs={6}>
                    <p className='b4 m-0'>Approval Status</p>
                    <h3 className='m-0 text-20 text-700'> <span className='b4'>{x.approvalStatus}</span></h3>
                    {/* <p className='b4' style={{color: status === 'up' ? '#28B833' : '#E94949'}}>+61.7%</p> */}
                </Col>
                <Col xs={6}>
                    <p className='b4 m-0'>Address</p>
                    <h3 className='m-0 text-20 text-700'><a href={`https://basescan.org/address/${x.walletAddress}`} target='_blank'> <h6>{x.walletAddress && (x.walletAddress).substring(0, 5)}....{x.walletAddress && (x.walletAddress).substring((x.walletAddress).length - 5)}</h6></a></h3>
                    <div className="table-stats-cell"><center><Button className='opacity-50' onClick={handleOpen}>Approve</Button></center></div>
                    {/* <p className='b4' style={{color: status === 'up' ? '#28B833' : '#E94949'}}>+1.2%</p> */}
                </Col>
            </Row>
        </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>You wish to approve this fund raising?</Modal.Title>
                </Modal.Header>
                <Modal.Body className='justify-content-center'>
                    <center>
                        <Button variant="secondary" className='me-2' onClick={handleClose}>
                            No
                        </Button>
                        {/* <Button variant="primary" onClick={() => handleYesClick(x.keyId)}> */}
                        <Button variant="primary" onClick={() => handleYesClick(x.id)}>
                            Yes
                        </Button>
                    </center>
                </Modal.Body>
            </Modal>

            <Modal show={showReject} onHide={handleCloseReject}>
                <Modal.Header closeButton>
                    <Modal.Title>You wish to reject this fund raising?</Modal.Title>
                </Modal.Header>
                <Modal.Body className='justify-content-center'>
                    <center>
                        <Button variant="secondary" className='me-2' onClick={handleCloseReject}>
                            No
                        </Button>
                        {/* <Button variant="primary" onClick={() => handleYesClickReject(x.keyId)}> */}
                        <Button variant="primary" onClick={() => handleYesClickReject(x.id)}>
                            Yes
                        </Button>
                    </center>
                </Modal.Body>
            </Modal>
            </>
    )
}