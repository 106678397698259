import { useEffect, useState } from 'react';
import { NavLink,useLocation } from 'react-router-dom';
import { Button, ButtonGroup, Card, Col, Dropdown, FloatingLabel, Form, InputGroup, Nav, Row, Stack, Tab, Tabs, Toast,  ToggleButton, ToggleButtonGroup, Modal } from 'react-bootstrap';
import ShopImage from '../../asserts/IMAGES/shope-image.png'
import Loader from '../../asserts/IMAGES/loader-icon.svg'
import Refresh from '../../asserts/IMAGES/refresh-icon.svg'
import Verified from '../../asserts/IMAGES/verified-icon.svg'
import CopyIcon from '../../asserts/IMAGES/copy-icon.svg'
import FixedIcon from '../../asserts/IMAGES/fixed-price-icon.svg'
import TimedAuction from '../../asserts/IMAGES/time-action.svg'
import Royalty from '../../asserts/IMAGES/royalty-icon.svg'
import { PriceHistory, Properties, TradingHistory } from './Components/TabsContent';
import { CardViewShop } from '../../Snippets/CardView';

import { Navigation, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Web3 from 'web3';

import {  Zoom, toast, ToastContainer} from 'react-toastify';
import '../../toast-style-override.css';

import 'swiper/scss';
import 'swiper/scss/navigation';

// import {abibytecode} from "../abi/datasbytecodeNormalNFT";
// import { abi } from '../abi/Normalnftcontract';
// import {abibytecodeauction} from '../abi/datasbytecodeAuctionNFT';
// import {abiauction} from '../abi/Auctionnftcontract';
// import {abibytecoderoyalty} from '../abi/datasbytecodeRoyaltyNFT';
// import {abiroylaty} from '../abi/Royaltynftcontract';
import { abibytecoderoyalty, abibytecode, abibytecodeauction} from '../../abibytecode';
import { abiroylaty, abinormal as abi, abiauction} from '../../abi';
import { createActivityTable, putByPrice, putBySale } from '../../awsdatafile';
import { useNavigate } from 'react-router-dom';
import {Ethereum as ConnectPopup} from '../../component/tabs/TabContent';
import { ethers, ContractFactory  } from 'ethers';
import bigInt from 'big-integer';
import web3Instance from '../../web3Inastance';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';
import { createActivityTableFirebase, putBySaleFirebase, putBypriceFirebase } from '../../FirebaseFunctions';
import { ChangeNetwork, ConnectWalletCommon as ConnectWallet } from '../../GeneralFunctions';
import { nftCreateAddress as adminAddress } from '../../address';

function MarketExploreCheckout({web3State}) {
    const location = useLocation();
    let navigate = useNavigate();    
    
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();

    const dataParam = new URLSearchParams(location.search).get('id');
    const dataObject = dataParam ? JSON.parse(decodeURIComponent(dataParam)) : null;
    console.log("data",dataObject)
    const [like, setLike] = useState(false);
    const [show, setShow] = useState(false);
    const [price, setPrice] = useState("");
    const[month,setmonth] = useState();
    console.log(month);
    const [selectValue311,setSelectValue311] = useState("1");  
    const handleShowLoad = () => setLoader(true);  
    const[loader, setLoader] = useState(false); 
    const[saleOn, setsaleOn] = useState(false); 
    const handleHideLoad = () => setLoader(false);
    const [checkAccount, setCheckAccount] = useState(null);
    const [ispopup, setIspopup] = useState(false);
    const handleClose = () => setIspopup(false);
    
    // const connectToEthereum = async () => {
    //     try {
    //       if (window.ethereum) {
    //         await window.ethereum.request({ method: 'eth_requestAccounts' });
    //         const web3 = new Web3(window.ethereum);
    //         if(localStorage.getItem("walletAddress") !== "" && localStorage.getItem("walletAddress") !== undefined && localStorage.getItem("walletAddress") !== null){
    //             setCheckAccount(true);
    //         }
    //         else{
    //             setCheckAccount(false);
    //         }
    //         return web3;
    //       } else {
    //         throw new Error('No Ethereum wallet found.');
    //       }
    //     } catch (error) {
    //       console.error(error);
    //       setCheckAccount(false);
    //       return null;
    //     }
    //   };

    const updatePrice =async() =>{
        handleShowLoad()    
        if(price === null || price === undefined || price === "" ){
            toast.warning(`please enter price`,{autoClose:5000})
            console.log("price")
            handleHideLoad()            
        }else if(isNaN(price))
        {        
            toast.warning(`please valid number`,{autoClose:5000})
            handleHideLoad()            
        }
        //else if(getprices === "0" || parseInt(getprices) === 0){
        else if(price === "0"){
            toast.warning(`please enter above 0 price`,{autoClose:5000})
            handleHideLoad()            
        }
        else if(price === "00" || price === "000" || price === "0000" || price === "00000" || price === "000000" || price === "0000000"){
            toast.warning(`you are entered zeros`,{autoClose:5000})
            handleHideLoad()            
        }
        else if(price.length >= 7 ){                                    
            toast.warning(`you are entered Maximum Values`,{autoClose:5000})
            handleHideLoad()            
        }
        else if(address === null || address === "" || address === undefined || address === " "){
            toast.warning(`please connect your wallet`,{autoClose:5000})
            handleHideLoad()         
        }        
        //else{
        // let minbalance=await minAlgoBalance()
        // if(minbalance < (961000+2000)){
        //     toast.error("Your Algo balance is low. Please get more Algos from dispenser",{autoClose:5000});              
        //     handleHideLoad()
        // }
        else{        
        handleShowLoad()             
       
        toast.info("Updating The Price of NFT",{autoClose:5000});  
        try{
            handleShowLoad()  
            const web3 = web3Instance;
            if (!web3) return;
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner(); 
            const account = address;

            let abicode =[];
            if(dataObject.nftType === "Normal"){
                abicode = abi
                
            }else if (dataObject.nftType === "Royalty"){
                abicode = abiroylaty
            }else{
                abicode = abiauction
              
            }
         
            let amountmul=((price)*1000000000000000000);
            toast.info("Updating The Price of NFT",{autoClose:5000});  
            const contract = new ethers.Contract(dataObject.nftAddress,abicode,signer);

            const weeb3 = new Web3(window.ethereum);
            const bigNumber =  weeb3.utils.toBigInt(amountmul);
            console.log("entereing",bigNumber)
            let id = dataObject.escrowAddress;
            
            let txreceipt = await contract.setTokenState(id,true,{gasLimit: 210000});
            let txres = await txreceipt.wait();
                // .send({
                //     from:localStorage.getItem('walletAddress'),
                //     gas: 210000,
                //     //gasPrice: '20000000000'
                //   });  
            
           let tx = await contract.setTokenPrice(dataObject.escrowAddress,bigNumber,{gasLimit: 210000})
           let txres1 = await tx.wait();
        //    .send({
        //         from:localStorage.getItem('walletAddress'),              
        //         gas: 210000,
        //         //gasPrice: '20000000000'
        //     })
            if(dataObject.nftType === "Auction"){
                console.log("check",month);
                let timegivencontract = 86400 * month;
                let txreceipt1 = await contract.auction(timegivencontract,address,dataObject.escrowAddress,{gasLimit: 210000});
                let txres2 = await txreceipt1.wait();
                // .send({
                //     from:localStorage.getItem('walletAddress'),
                //     gas: 210000
                // })
            }
            else{
               
                let txreceipt2 = await contract.approve(dataObject.nftAddress,dataObject.escrowAddress,{gasLimit: 210000});
                let txres3 = await txreceipt2.wait();
                // .send({
                //     from:localStorage.getItem('walletAddress'),
                //     gas: 210000,
                //     //gasPrice: '20000000000'
                // }) 
            }
            
            // console.log("setprice",tx)  
        // await putByPrice(amountmul,dataObject.keyValue);
        // await createActivityTable(address,"Update price",dataObject.nftAddress,tx.hash,dataObject.nftType)
        await putBypriceFirebase(amountmul,dataObject.id);
        await createActivityTableFirebase(address,"Update price",dataObject.nftAddress,tx.hash,dataObject.nftType)

        setsaleOn(true);
        toast.success(`NFT Price Updated Successfully`,{autoClose: 5000});            
        //await saledb()
        
        await sleep(7000);
        handleHideLoad()
        // window.location.reload(false)
          
        }catch(error){
            console.log("error",error)
            console.log(error)                                
            handleHideLoad()
            // window.location.reload(false)
        }
        }
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const updateSale = async() =>{
        if(address === null || address === "" || address === undefined || address === " "){            
            toast.warn(`please connect your wallet`,{autoClose: 5000});            
            handleHideLoad()            
        }else if(address === dataObject.ownerAddress){   
            handleShowLoad()                             
            toast.info("Updating The Sale of NFT",{autoClose:5000}); 
            try{

                const web3 = web3Instance;
                if (!web3) return;
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const account = address;
                
                    let abicode =[];
                    if(dataObject.nftType === "Normal"){
                        abicode = abi
                        
                    }else if (dataObject.nftType === "Royalty"){
                        abicode = abiroylaty
                    }else{
                        abicode = abiauction
                    }
                    const contract = new ethers.Contract(dataObject.nftAddress,abi,signer);
                    let id = dataObject.escrowAddress;
                    // let tx = await contract.transferFrom(address,"0xFC32107b3153322F4055bf5Ba7de21978E9E3Dfc",id)
                    let tx = await contract.transferFrom(address,adminAddress,id)
                    let txres = await tx.wait();
                    // .send({
                    //     from:localStorage.getItem('walletAddress')
                    //   });
                    // await putBySale("yes",dataObject.keyValue);
                    // await createActivityTable(address,"Put For Sale",dataObject.nftAddress,tx.hash,dataObject.nftType)
                    await putBySaleFirebase("yes",dataObject.id, address);
                    await createActivityTableFirebase(address,"Put For Sale",dataObject.nftAddress,tx.hash,dataObject.nftType)
    
                    // await createActivityTable(address,"Put for Sale", pooladdressfinal,transactionHash,"NFT")
                    toast.success(`NFT Put for Sale Successfully Done`,{autoClose: 5000});            
                        //await saledb()
                        handleHideLoad()
                        navigate('/profile/sales');
                
                
                    // await done();
            }catch(error){
                console.log("Error",error)
                console.log(error)                                
                handleHideLoad()
                window.location.reload(false)
            }
        }
    }

    useEffect(() => {
        if(address !== "" && address !== undefined && address !== null){
            setCheckAccount(true);
        }
        else{
            setCheckAccount(false);
        }
    }, [address, isConnected]);

    useEffect(() => {
        console.log(month); 
    },[month]);

    const handlemonth=(event) => {
        console.log("event check",event.target.value)
        setmonth(parseInt(event.target.value));
    }

    const refresh = () => {
        window.location.reload();
    }

    return ( 
        <>
        
            {/* <ToastContainer position="bottom-end" className="p-3" style={{ zIndex: 1 }}>
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                    <Toast.Body className='b2 px-3 text-gray'>Copied Succesfully!</Toast.Body>
                </Toast>
            </ToastContainer> */}
            <div className="d-flex overflow-auto">
                <div className="inner-links flex-nowrap text-nowrap navbar-nav d-flex flex-row">
                <NavLink className="nav-link" to="/market/explore">Explore</NavLink>
                    <NavLink className="nav-link" to="/market/create">Create</NavLink>
                    <NavLink className="nav-link" to="/market/explore/single">Trending Collection</NavLink>
                   
                    <NavLink className="nav-link" to="/market/explore/shop/heritage">Heritage</NavLink>
                    <NavLink className="nav-link" to="/market/explore/shop/royalty">Artifact</NavLink>
                  
                    <NavLink className="nav-link" to="/market/explore/shop/auction">Auction</NavLink>
                </div>
            </div>
           
            <div className="mb-lg-5 mb-4 mt-md-1 mt-4">
            <ToastContainer position='bottom-right' draggable = {false} transition={Zoom} autoClose={4000} closeOnClick = {false}/>
                <div className='d-none d-md-flex justify-content-end'>
                    <ButtonGroup aria-label="First group">
                        {/* <Button onClick={() => setLike(!like)} variant="white" className='d-flex align-items-center'>
                            {like ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="me-2" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                </svg>
                            ):(
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="me-2" viewBox="0 0 16 16">
                                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                                </svg>
                            )}
                            {like ? 1 : 0}
                        </Button>
                        <Button variant="white" className='d-flex align-items-center'>
                            <img src={Loader} className='d-block' alt="Loader" />
                        </Button> */}
                        <Button variant="white" className='d-flex align-items-center' onClick={refresh}>
                            <img src={Refresh} className='d-block' alt="Refresh" />
                        </Button>
                    </ButtonGroup>
                </div>
                <Row className='gx-lg-5 gx-md-4'>
                    <Col md={6} xs={12} className='mb-md-0 mb-2'>
                        <div className='d-block mb-md-3 mb-2'>
                            <p className='b2 mb-2 text-black'>Divine Dimension   <img src={Verified} className='ms-1' alt="Verified" /></p>

                            <h1 className='text-36 text-black text-600'>{dataObject.nftName}<br />{dataObject.valid} - {dataObject.escrowAddress}</h1>

                            <p className='b2 mb-20'>Owned by <span className='ms-1 text-block'>{(dataObject.ownerAddress).substring(0, 4)}...{(dataObject.ownerAddress).substring((dataObject.ownerAddress).length -4, (dataObject.ownerAddress).length)}</span></p>
                        </div>

                        <div className='d-flex d-md-none justify-content-end'>
                            <ButtonGroup aria-label="First group">
                                {/* <Button onClick={() => setLike(!like)} variant="white" className='d-flex align-items-center'>
                                    {like ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="me-2" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                        </svg>
                                    ):(
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="me-2" viewBox="0 0 16 16">
                                            <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                                        </svg>
                                    )}
                                    {like ? 1 : 0}
                                </Button>
                                <Button variant="white" className='d-flex align-items-center'>
                                    <img src={Loader} className='d-block' alt="Loader" />
                                </Button> */}
                                <Button variant="white" className='d-flex align-items-center' onClick={refresh}>
                                    <img src={Refresh} className='d-block' alt="Refresh" />
                                </Button>
                            </ButtonGroup>
                        </div>

                        <div className='shop-frame d-flex border'>
                            <img src={dataObject.imagePath} alt="ShopImage" className='img-fluid m-auto' />
                        </div> 
                    </Col>
                    <Col md={6} xs={12} className='pt-md-3'>
                        <div className='mt-md-5 pt-md-3 mb-md-4 mb-3'>
                            <Button variant='white' onClick={() =>  {navigator.clipboard.writeText(dataObject.nftAddress); setShow(true)}} className='px-md-3 px-2 me-md-3 me-2 mb-md-3 mb-2' size='sm'>NFT Address: {(dataObject.nftAddress).substring(0, 4)}...{(dataObject.nftAddress).substring((dataObject.nftAddress).length -4, (dataObject.ownerAddress).length)} <img src={CopyIcon} className='ms-1' style={{verticalAlign: '-5px'}} alt='Copy Icon' /></Button>
                            <Button variant='white' onClick={() =>  {navigator.clipboard.writeText(dataObject.escrowAddress); setShow(true)}} className='px-md-3 px-2 mb-md-3 mb-2' size='sm'>Token ID: {dataObject.escrowAddress} <img src={CopyIcon} className='ms-1' style={{verticalAlign: '-5px'}} alt='Copy Icon' /></Button>
                            
                            <p className='b2 mb-20'>{dataObject.nftDescription}</p>
                        </div>

                        <div className='d-flex mb-md-4 mb-3 align-items-center justify-content-between'>
                            <div className='pe-2'>
                                <h4 className='text-500 mb-0'>Put on marketplace</h4>
                                {/* <p className='b1'>Single edition on Ethereum</p> */}
                            </div>
                            {/* <Form.Check
                                type="switch"
                                id="custom-switch"
                                label=""
                            /> */}
                        </div>


                        <ToggleButtonGroup type="radio" className='d-flex mb-md-4 mb-3 btn-toggles' name="options" defaultValue={1}>
                            {dataObject.nftType === "Normal" ? (<>
                                <ToggleButton id="tbg-radio-1" className='mx-md-2 mx-1 bg-white justify-content-center flex-1 align-items-center d-flex flex-column' value={1}>
                                <img src={FixedIcon} alt='FixedIcon' />
                                <span>Fixed price</span>
                            </ToggleButton>
                            </>): dataObject.nftType === "Auction"? (<>
                                <ToggleButton id="tbg-radio-2" className='mx-md-2 mx-1 bg-white justify-content-center flex-1 align-items-center d-flex flex-column' value={1}>
                                <img src={TimedAuction} alt='TimedAuction' />
                                <span>Timed auction</span>
                            </ToggleButton>
                            </>):(<>
                                <ToggleButton id="tbg-radio-3" className='mx-md-2 mx-1 bg-white justify-content-center flex-1 align-items-center d-flex flex-column' value={1}>
                                <img src={Royalty} alt='Royalty' />
                                <span>Royalty</span>
                            </ToggleButton>
                            </>)}
                            
                           
                           
                        </ToggleButtonGroup>

                        <h4 className='text-500 text-20 text-black mb-md-4 mb-3'>Price</h4>

                        <Row>
                            <Col xs={8} className='mb-3'>
                                <FloatingLabel
                                    controlId="floatingInput.Email"
                                    label="Enter price"
                                >
                                    <Form.Control type="email" placeholder=" " onChange={(e)=>setPrice(e.target.value)}/>
                                </FloatingLabel>
                            </Col>
                            <Col xs={4} className='mb-3'>
                                <Form.Select className='form-control h-100' aria-label="Floating label select example">
                                    <option>ETH</option>
                                    {/* <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option> */}
                                </Form.Select>
                            </Col>
                            {/* <Col sm={5} className='mb-3'>
                                <div className='form-control'>
                                    <div className='d-flex align-items-center justify-content-between b2'>
                                        <span>Price</span>
                                        <span>— ETH</span>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between b3 text-gray'>
                                        <span>divinedimension fee?</span>
                                        <span className='text-500 text-black'>1%</span>
                                    </div>
                                    <hr className='my-2' />
                                    <div className='d-flex align-items-center justify-content-between text-black-6'>
                                        <span>You will receive</span>
                                        <span className='text-700 text-black'>— ETH</span>
                                    </div>
                                </div>
                            </Col> */}
                            {dataObject.nftType === "Auction"? (<>
                                <Col sm={7} className='mb-3'>
                                <div className='form-control'>
                                    <div className='b2'>
                                        Date of listing expiration
                                    </div>

                                    <Form.Select className='form-control text-14 px-2 form-control-sm my-1 bg-light' aria-label="Floating label select example" onChange={handlemonth}>
                                    <option>1 Day</option>
                                    <option value="1" onClick={(event) =>handlemonth(parseInt(event.target.value))}>1 Day</option>
                                    <option value="2" onClick={()=>setmonth(2)}>2 Days</option>
                                    <option value="7" onClick={()=>setmonth(7)}>7 Days</option>
                                    </Form.Select>

                                    {/* <div className='b4 text-black-6'>
                                        Expiration at 03/09/2023, 18:51
                                    </div> */}
                                </div>
                            </Col>
                            </>):dataObject.nftType === "Royalty"?
                             (<>
                                <Col xs={12} className='mb-3'>
                                <InputGroup className='mb-md-3 mb-2'>
    <InputGroup.Text className='bg-transparent opacity-5 b1 border-0' id="basic-addon1">%</InputGroup.Text>
                        <Form.Select className='form-control h-100' aria-label="Floating label select example"defaultValue={selectValue311} 
                                            onChange={(e)=> setSelectValue311(e.target.value)}>
                                                <option value="1">1</option>                                                
                                                <option value="2">2</option>                                                
                                                <option value="3">3</option>                                                
                                                <option value="4">4</option>                                                
                                                <option value="5">5</option>   
                            </Form.Select>
                            {/*  */}
                    </InputGroup>
                    <p className='b1'>Suggested: 1%, 2%, 3%, 4%. Maximum is 5%</p>
                            </Col>
                             </>):(<></>)}
                           
                         
                            <Col xs={12}>
                            {checkAccount && (chainId == 8453) ? 
                            <>{dataObject.nftPrice > 0 || saleOn ?
                                (<>
                                   

                                   <Button  variant="black" className="d-block w-100 mb-2" size='md' onClick={()=>updateSale()}>Sale</Button>
                               </>):(<>
                                   <Button  variant="black" className="d-block w-100 mb-2" size='md' onClick={()=>updatePrice()}>Update Price</Button>
                               </>)}</> : 
                               <>
                               {checkAccount ? 
                                        <Button type="reset" className="d-block w-100 mb-2" variant="black"  onClick={() => ChangeNetwork(walletProvider)}> Change Network </Button>: 
                                        <Button type="reset" className="d-block w-100 mb-2" variant="black"  onClick={ConnectWallet}> Connect Wallet </Button> }
                               {/* <Button  variant="black" className="d-block w-100 mb-2" size='md' onClick={chainId !== 84532 ? ChangeNetwork : ConnectWallet}>{chainId !== 84532 ? "Change Network" : "Connect Wallet"}</Button> */}
                               </>}
                                <Modal show={ispopup} size="lg" centered="true" onHide={handleClose}>
                        <Modal.Body className='p-md-4 p-3'>
                            <div className='p-md-2'>
                            <div className='text-end position-relative' style={{marginBottom: '-20px', zIndex: 2}}>
                                <Button variant='reset' onClick={handleClose} className='p-0 text-gray m-0'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="d-block" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                                </Button>
                            </div>
                            <Row className='justify-content-center'>
                                <Col md={9}>
                                 <div className='text-center mt-md-0 mt-3 mb-3'>
                                <h2>Connect wallet</h2>
                                <p className='b2 mx-md-4 px-md-5'>Choose how you want to connect. There are several wallet providers.</p>
                                </div>

                                <Tabs defaultActiveKey="ethereum"
                                      id="uncontrolled-tab-example"
                                      className='mb-md-4 mb-lg-4 mb-3 w-100'>
                                    <Tab eventKey="ethereum" title="Ethereum">
                                        <ConnectPopup/>
                                    </Tab>
                                </Tabs>
                                </Col>
                            </Row>
            
                            </div>
                        </Modal.Body>
                    </Modal>
                                
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </div>

            {/* <div className='mb-5'>
                <Tabs
                    defaultActiveKey="properties"
                    id="uncontrolled-tab-example"
                    className='mb-md-4 mb-3'
                >
                    <Tab eventKey="properties" title="Properties">
                        <Properties />
                    </Tab>
                    <Tab eventKey="price" title="Price History">
                        <PriceHistory />
                    </Tab>
                    <Tab eventKey="trading" title="Trading History">
                        <TradingHistory />
                    </Tab>
                </Tabs>
            </div>


            <div className='overflow-xl-hidden'>
                <h2 className='h1 mb-20'>Explore the Collection</h2>

                <Swiper
                    modules={[Navigation, A11y]}
                    spaceBetween={16}
                    slidesPerView={'auto'}
                    autowidth="true"
                    navigation
                    className='swiper-card-4'
                    // onSwiper={(swiper) => console.log(swiper)}
                    // onSlideChange={() => console.log('slide change')}
                >
                    <SwiperSlide><CardViewShop /></SwiperSlide>
                    <SwiperSlide><CardViewShop /></SwiperSlide>
                    <SwiperSlide><CardViewShop /></SwiperSlide>
                    <SwiperSlide><CardViewShop /></SwiperSlide>
                </Swiper>
            </div> */}
            
        </>
     );
}

export default MarketExploreCheckout;