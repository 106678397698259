import { Container } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';

function LayoutAfterLogin(props) {
    return ( 
        <div className="app-dashboard">
            <Header ethBalance = {props.ethBalance} dimeBalance = {props.dimeBalance}/>

            <Container className='overflow-hidden'>
                {props.children}
            </Container>

            <Footer />
        </div>
    )
}

export default LayoutAfterLogin;