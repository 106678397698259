import { Link, NavLink } from 'react-router-dom';
import Banner from '../../asserts/IMAGES/nft-banner-image.png'
import BannerBG from '../../asserts/IMAGES/nft-banner-bg.png'
import RocketIcon from '../../asserts/IMAGES/rocket-icon.svg'
import { Col, Nav, Row } from 'react-bootstrap';
// import  {abi1,tokenaddress1De,tokenaddress1Ca,tokenaddress1Wv} from './NFTLaunchpadcontracts';
import { abinftlaunchpad as abi1 } from '../../abi';
import { tokenaddress1De, tokenaddress1Ca, tokenaddress1Wv } from '../../address';

import { CardViewHorizontalDe } from '../../Snippets/CardView';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { CircularProgress } from '@mui/material';

function NFTLaunchpadDe() {
    let desc = "The Divine Explorer NFT serves as your exclusive ticket to embark on a spiritual journey within our immersive metaverse. With this unique NFT, you gain the power to traverse the globe and visit awe-inspiring religious monuments from every corner of the world.";
    const [startDate, setStartDate] = useState(0);
    const [endDate, setEndDate] = useState(0);
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const fetchEndDate = async () => {
        const url = "https://base-rpc.publicnode.com/";
        const provider = new ethers.providers.JsonRpcProvider(url);
        const instance = new ethers.Contract(tokenaddress1De, abi1, provider);
        console.log("instance", instance);
        const [
            startDateContract,
            endDateContract,
        ] = await Promise.all([
            instance.start_time(),
            instance.end_time(),
        ]);

        const formattedStartDate = ethers.utils.formatUnits(startDateContract, 0);
        const formattedEndDate = ethers.utils.formatUnits(endDateContract, 0);

        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
    }

    useEffect(() => {
        fetchEndDate();
    }, [address, isConnected])

    function epochToDateTime(epoch) {
        const milliseconds = epoch * 1000;
      
        const dateObject = new Date(milliseconds);
      
        const year = dateObject.getFullYear();
        const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
        const day = ("0" + dateObject.getDate()).slice(-2);
      
        let hours = dateObject.getHours();
        const minutes = ("0" + dateObject.getMinutes()).slice(-2);
        const seconds = ("0" + dateObject.getSeconds()).slice(-2);
      
        const ampm = hours >= 12 ? 'PM' : 'AM';
      
        hours = hours % 12;
        hours = hours ? hours : 12;
        const formattedHours = ("0" + hours).slice(-2);
      
        const dateTimeString = `${year}-${month}-${day} ${formattedHours}:${minutes}:${seconds} ${ampm}`;
      
        return dateTimeString;
    }
    

    return ( 
        <>
            <div className="mb-20 d-flex d-md-none align-items-center justify-content-between">
                <h1 className="text-600 mb-0">NFT Membership</h1>
            </div>

            <div className="mb-lg-5 mb-4 mt-4 page-banner text-white page-banner-dark d-flex px-100" style={{backgroundColor: 'rgba(4, 11, 32, 1)'}}>
                <img src={BannerBG} className='page-banner-bg' alt="image" /> 
                <img src={Banner} className='page-banner-image-sm-expand' alt="image" />

                <div className='my-5 my-sm-auto text-sm-start text-center pb-sm-0 pb-4 w-100'>
                    <Row>
                        <Col xs={12} md={8} xl={7} sm={10}>
                            <h1 className='text-500'>Discover, and collect <br />Monster NFTs extraordinary</h1>
                            <p>The Arcadians: Reloaded collection is inspired by classic arcade games. Each Arcadian is custom-made by the minter from a pool of traits. </p>

                            <Link to="/market/explore" className='btn btn-small mb-2 b1 btn-outline-white me-md-3 me-2 d-inline-flex align-items-center justify-content-center' onClick={e => e.preventDefault()}><img className='me-2' src={RocketIcon} alt="RocketIcon" /> Explore more</Link>
                            <Link to="/nft-launchpad" className='btn btn-small mb-2 b1 btn-white'>Go back to NFT Membership</Link>
                        </Col>
                    </Row>
                </div>
            </div>

            {/* <div className="d-flex mb-40 overflow-auto">
                <div className="inner-links flex-nowrap text-nowrap navbar-nav d-flex flex-row">
                    <NavLink className="nav-link" to="/nft-launchpad">Completed</NavLink>
                    <Nav.Link className="nav-link ms-md-4" to="#Live">Live</Nav.Link>
                    <Nav.Link className='ms-md-4' to="#">Upcoming</Nav.Link>
                </div>
            </div> */}

            <div className='overflow-xl-hidden'>
                <h2 className='h1 mb-20'>Explore</h2>
                <Row className='gx-2 gx-md-3' xs={1} sm={2} md={3} lg={2} xl={3}>
                {!startDate ? <>
                    <center><CircularProgress color="inherit" /></center>
                </> : <>
                    <Col className='mb-md-3 mb-2'>
                    <div style={{ border: '4px solid black', padding: '3px', borderRadius: '20px' }}>
                        <CardViewHorizontalDe size='sm' text="Phase 1" phase="1" date={startDate > Math.floor(Date.now() / 1000) ? "Not Started" : `End Date: ${epochToDateTime(endDate)}`} description={desc} />
                    </div>
                    <div style={{ marginTop: '5px', textAlign: 'center', fontWeight: 'bold', fontSize: "20px" }}>PILOT</div>
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                    <CardViewHorizontalDe size='sm' text="Phase 2" phase="2" date="Coming Soon..." description={desc} />
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                    <CardViewHorizontalDe size='sm' text="Phase 3" phase="3" date="Coming Soon..." description={desc} />
                    </Col>
                    </>}
                </Row>
                </div>
        </>
     );
}

export default NFTLaunchpadDe;