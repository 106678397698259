import { db } from './firebase';
import axios from "axios";
import { collection, addDoc, getDocs, updateDoc, doc  } from "firebase/firestore";

//============================ POST =============================

export const createEventsFirebase = async(name, descr, contractAddress, starttime, endtime, rewards, walletaddrr, imagepath, category, email, crossmintFeature, stakeAddress) => {
    const newEvent = {
        name,
        description: descr,
        email: contractAddress,
        startTime: starttime,
        endTime: endtime,
        rewards,
        walletAddress: walletaddrr,
        imagePath: imagepath,
        status: category,
        approvalStatus: "No",
        networkName: email,
        crossmint: crossmintFeature,
        stakeContract: stakeAddress,
        createdTime: new Date().toISOString()
    };

    try {
        const docRef = await addDoc(collection(db, "events"), newEvent);
        console.log("Event created with ID: ", docRef.id);

    } catch (error) {
        console.error("Error adding event: ", error);
    }
};

export const createLaunchpadTxnTableFirebase = async(txnTime,txnHash,senderAddress,amount,contractAddress,keyId) => {
    const newEvent = {
        txnTime: txnTime,
        txnHash: txnHash,
        senderAddress: senderAddress,
        amount: amount,
        contractAddress: contractAddress,
        keyId: keyId
    };

    try {
        const docRef = await addDoc(collection(db, "launchpadtxn"), newEvent);
        console.log("Event created with ID: ", docRef.id);

    } catch (error) {
        console.error("Error adding event: ", error);
    }
};

export const createNftLaunchpadTxnTableFirebase = async(txnTime,phase,txnHash,senderAddress,amount,contractAddress,keyId) => {
    const newEvent = {
        txnTime: txnTime,
        phase: phase,
        txnHash: txnHash,
        senderAddress: senderAddress,
        amount: amount,
        contractAddress: contractAddress,
        keyId: keyId
    };

    try {
        const docRef = await addDoc(collection(db, "launchpadnfttxn"), newEvent);
        console.log("Event created with ID: ", docRef.id);

    } catch (error) {
        console.error("Error adding event: ", error);
    }
};

export const createActivityTableFirebase = async(algoaddre,txntype,nftaddress,txhash,page) => {
    const newEvent = {
        walletAddress: algoaddre,
        txnType: txntype,
        nftAddress: nftaddress,
        txnHash: txhash,
        txnPage: page
    };

    try {
        const docRef = await addDoc(collection(db, "activity"), newEvent);
        console.log("Event created with ID: ", docRef.id);

    } catch (error) {
        console.error("Error adding event: ", error);
    }
};

export const createNFTDetailsFirebase = async(address,nftaddress,tokenid,type,descr,name,socialLink,imagePath,nfttype,profileimage) => {
    const newEvent = {
        creatorAddress:address,
        nftAddress:nftaddress,
        escrowAddress:tokenid,
        nftType:type,
        nftDescription:descr,
        nftName:name,
        nftPrice:"", 
        socialLink:socialLink, 
        ownerAddress:address,
        previousAddress:address,
        saleDetails:"no", 
        valid:nfttype,
        imagePath:imagePath,
        userProfile:profileimage
    };

    try {
        const docRef = await addDoc(collection(db, "nftdetails"), newEvent);
        console.log("Event created with ID: ", docRef.id);

    } catch (error) {
        console.error("Error adding event: ", error);
    }
};

export const createStakingTableFirebase = async(txnHash,senderAddress,tokenId,contractAddress,txnTime,action,contractName) => {
    const newEvent = {
        txnHash: txnHash,
        senderAddress: senderAddress,
        tokenId: tokenId,
        contractAddress: contractAddress,
        txnTime: txnTime,
        action: action,
        contractName: contractName
    };

    try {
        const docRef = await addDoc(collection(db, "nftstaking"), newEvent);
        console.log("Event created with ID: ", docRef.id);

    } catch (error) {
        console.error("Error adding event: ", error);
    }
};

export const createprofileNewFirebase = async(walletAddress) => {
    const newEvent = {
        walletAddress: walletAddress,
        // coverImagePath: "https://gateway.pinata.cloud/ipfs/Qmdez1SZp2VvYfoJNVo6K8cNjer3HBUp51PivC7Cp/5dLBU?_gl=1*14oea56*_ga*NTA2MzM5MTg3LjE2OTAzNTIxNDU.*_ga_5RMPXG14TE*MTY5MDU0MzkyMC4zLjEuMTY5MDU0NDMxMC42MC4wLjA.",
        coverImagePath: "https://gateway.pinata.cloud/ipfs/QmSF1H62RCokmtcTTsvMr7wDSDGRHSpp723L8LSHdZHX8T",
        profileImagePath: "https://gateway.pinata.cloud/ipfs/QmdLCS6mfnfxvhy9TKZ7h3MCNAJ6y3piz2M7MKMhp2zvej"
        // profileImagePath: "https://gateway.pinata.cloud/ipfs/QmNSS2Z6ayrdGEkGLJBfRvGdpTPbyB6UUmsaZTsy3LTDxh"
    };

    try {
        const docRef = await addDoc(collection(db, "userprofile"), newEvent);
        console.log("Event created with ID: ", docRef.id);

    } catch (error) {
        console.error("Error adding event: ", error);
    }
};

export const createprofileSignUpNewFirebase = async(walletAddress, username) => {
    const newEvent = {
        userName: username,
        walletAddress: walletAddress,
        // coverImagePath: "https://gateway.pinata.cloud/ipfs/Qmdez1SZp2VvYfoJNVo6K8cNjer3HBUp51PivC7Cp/5dLBU?_gl=1*14oea56*_ga*NTA2MzM5MTg3LjE2OTAzNTIxNDU.*_ga_5RMPXG14TE*MTY5MDU0MzkyMC4zLjEuMTY5MDU0NDMxMC42MC4wLjA.",
        coverImagePath: "https://gateway.pinata.cloud/ipfs/QmSF1H62RCokmtcTTsvMr7wDSDGRHSpp723L8LSHdZHX8T",
        profileImagePath: "https://gateway.pinata.cloud/ipfs/QmdLCS6mfnfxvhy9TKZ7h3MCNAJ6y3piz2M7MKMhp2zvej"
        // profileImagePath: "https://gateway.pinata.cloud/ipfs/QmNSS2Z6ayrdGEkGLJBfRvGdpTPbyB6UUmsaZTsy3LTDxh"
    };

    try {
        const docRef = await addDoc(collection(db, "userprofile"), newEvent);
        console.log("Event created with ID: ", docRef.id);

    } catch (error) {
        console.error("Error adding event: ", error);
    }
};

export const SignUpApiFirebase = async(email, password, walletAddress, username) => {
    const newEvent = {
        mailId: email,
        passWord: password,
        signinStatus: false,
        walletAddress: walletAddress,
        userName: username
};

    try {
        const docRef = await addDoc(collection(db, "resetpassword"), newEvent);
        console.log("Event created with ID: ", docRef.id);
        let status= true;
        return status;
    } catch (error) {
        console.error("Error adding event: ", error);
        let status= false;
        return status;
    }
};

//============================ GET =============================

export const getLanuchpadTxnFirebase = async (contractValue) => {
    try {
        const querySnapshot = await getDocs(collection(db, "launchpadtxn"));
        const documents = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            // Check if the contract value of the document matches the specified contract value
            if ((data.contractAddress).toLowerCase() === contractValue.toLowerCase()) {
            documents.push({ id: doc.id, ...doc.data() });
            }
        });
        console.log("raw",querySnapshot,"pro", documents);
        return documents;
    } catch (error) {
        console.error("Error getting documents: ", error);
        return [];
    }
};

export const getNFTLanuchpadTxnFirebase = async (contract,phase) => {
    try {
        const querySnapshot = await getDocs(collection(db, "launchpadnfttxn"));
        const documents = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            // Check if the contract value of the document matches the specified contract value
            if ((data.contractAddress).toLowerCase() === contract.toLowerCase() && data.phase === phase) {
            documents.push({ id: doc.id, ...doc.data() });
            }
        });
        console.log("raw",querySnapshot,"pro", documents);
        return documents;
    } catch (error) {
        console.error("Error getting documents: ", error);
        return [];
    }
};

export const getTradingHistoryFirebase = async (nftAddress) => {
    try {
        const querySnapshot = await getDocs(collection(db, "activity"));
        const documents = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            // Check if the contract value of the document matches the specified contract value
            if ((data.nftAddress).toLowerCase() === nftAddress.toLowerCase()) {
            documents.push({ id: doc.id, ...doc.data() });
            }
        });
        console.log("raw",querySnapshot,"pro", documents);
        return documents;
    } catch (error) {
        console.error("Error getting documents: ", error);
        return [];
    }
};

export const getActivityByWalletFirebase = async (address) => {
    try {
        const querySnapshot = await getDocs(collection(db, "activity"));
        const documents = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            // console.log(data.walletAddress, address)
            // Check if the contract value of the document matches the specified contract value
            if (data.walletAddress === address) {
            documents.push({ id: doc.id, ...doc.data() });
            }
        });
        console.log("raw",querySnapshot,"pro", documents);
        return documents;
    } catch (error) {
        console.error("Error getting documents: ", error);
        return [];
    }
};

export const getAlluserDetailsNewFirebase = async () => {
    try {
        const querySnapshot = await getDocs(collection(db, "userprofile"));
        const documents = [];
        querySnapshot.forEach((doc) => {
            documents.push({ id: doc.id, ...doc.data() });
        });
        console.log("raw",querySnapshot,"pro", documents);
        return documents;
    } catch (error) {
        console.error("Error getting documents: ", error);
        return [];
    }
};

export const getAllEventsFirebase = async () => {
    try {
        const querySnapshot = await getDocs(collection(db, "events"));
        const documents = [];
        querySnapshot.forEach((doc) => {
            documents.push({ id: doc.id, ...doc.data() });
        });
        console.log("raw",querySnapshot,"pro", documents);
        return documents;
    } catch (error) {
        console.error("Error getting documents: ", error);
        return [];
    }
};

export const getEventbywalletFirebase = async (address1) => {
    console.log(address1);
    try {
        const querySnapshot = await getDocs(collection(db, "events"));
        const documents = [];
        console.log(querySnapshot);
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            // Check if the contract value of the document matches the specified contract value
            if ((data.walletAddress).toLowerCase() === (address1).toLowerCase()) {
                documents.push({ id: doc.id, ...doc.data() });
                }
        });
        console.log("raw",querySnapshot,"pro", documents);
        return documents;
    } catch (error) {
        console.error("Error getting documents: ", error);
        return [];
    }
};

export const getuserDetailsbywalletNewFirebase = async (address) => {
    try {
        const querySnapshot = await getDocs(collection(db, "userprofile"));
        const documents = [];
        
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            // Check if the contract value of the document matches the specified contract value
            if ((data.walletAddress).toLowerCase() === (address).toLowerCase()) {
            documents.push({ id: doc.id, ...doc.data() });
            }
        });
        console.log("raw",querySnapshot,"pro", documents);
        return documents;
    } catch (error) {
        console.error("Error getting documents: ", error);
        return [];
    }
};

export const getNFTDetailsByTypeSaleFirebase = async (type,sale) => {
    try {
        const querySnapshot = await getDocs(collection(db, "nftdetails"));
        const documents = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            // Check if the contract value of the document matches the specified contract value
            if (data.nftType === type && data.saleDetails === sale) {
            documents.push({ id: doc.id, ...doc.data() });
            }
        });
        console.log("raw",querySnapshot,"pro", documents);
        return documents;
    } catch (error) {
        console.error("Error getting documents: ", error);
        return [];
    }
};

export const getNFTDetailsByAddressTypeSaleFirebase = async (owner,type,sale) => {
    try {
        const querySnapshot = await getDocs(collection(db, "nftdetails"));
        const documents = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            // Check if the contract value of the document matches the specified contract value
            if ((data.nftType).toLowerCase() === (type).toLowerCase() && (data.saleDetails).toLowerCase() === (sale).toLowerCase() && (data.ownerAddress).toLowerCase() === (owner).toLowerCase()) {
            documents.push({ id: doc.id, ...doc.data() });
            }
        });
        console.log("raw",querySnapshot,"pro", documents);
        return documents;
    } catch (error) {
        console.error("Error getting documents: ", error);
        return [];
    }
};

export const getNFTDetailsByTypeSaleBycategoryFirebase = async (type,sale,category) => {
    try {
        const querySnapshot = await getDocs(collection(db, "nftdetails"));
        const documents = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            // Check if the contract value of the document matches the specified contract value
            if (data.nftType === type && data.saleDetails === sale && data.valid === category) {
            documents.push({ id: doc.id, ...doc.data() });
            }
        });
        console.log("raw",querySnapshot,"pro", documents);
        return documents;
    } catch (error) {
        console.error("Error getting documents: ", error);
        return [];
    }
};

export const getStakingbyaddressFirebase = async (senderAddress) => {
    try {
        const querySnapshot = await getDocs(collection(db, "nftstaking"));
        const documents = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            // Check if the contract value of the document matches the specified contract value
            if ((data.senderAddress).toLowerCase() === senderAddress.toLowerCase()) {
                documents.push({ id: doc.id, ...doc.data() });
                }
        });
        console.log("raw",querySnapshot,"pro", documents);
        return documents;
    } catch (error) {
        console.error("Error getting documents: ", error);
        return [];
    }
};

export const getPasswordbymailFirebase = async (email) => {
    try {
        const querySnapshot = await getDocs(collection(db, "resetpassword"));
        const documents = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            // Check if the contract value of the document matches the specified contract value
            if (data.email === email) {
                documents.push({ id: doc.id, ...doc.data() });
                }
        });
        console.log("raw",querySnapshot,"pro", documents);
        return documents;
    } catch (error) {
        console.error("Error getting documents: ", error);
        return [];
    }
};

export const getStakingbyAddressContractTokenFirebase = async (contractAddress, tokenId, senderAddress) => {
    try {
        const querySnapshot = await getDocs(collection(db, "nftstaking"));
        const documents = [];
        querySnapshot.forEach((doc) => {
             const data = doc.data();
             // Check if the contract value of the document matches the specified contract value
             if ((data.contractAddress).toLowerCase() === contractAddress.toLowerCase() && data.tokenId === tokenId && (data.senderAddress).toLowerCase() === senderAddress.toLowerCase()) {
                documents.push({ id: doc.id, ...doc.data() });
             }
        });
        console.log("raw",querySnapshot,"pro", documents);
        return documents;
    } catch (error) {
        console.error("Error getting documents: ", error);
        return [];
    }
};

export const getSignUpFirebase = async (emailId) => {
    try {
        const querySnapshot = await getDocs(collection(db, "resetpassword"));
        const documents = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            // Check if the contract value of the document matches the specified contract value
            if (data.mailId === emailId) {
            documents.push({ id: doc.id, ...doc.data() });
            }
        });
        console.log("raw",querySnapshot,"pro", documents);
        return [true,documents];
    } catch (error) {
        console.error("Error getting documents: ", error);
        return [false, 'Error occurred while making the request'];
    }
};

export const SignInApiFirebase = async (emailId, password) => {
    try {
        const querySnapshot = await getDocs(collection(db, "resetpassword"));
        const documents = [];
        querySnapshot.forEach((doc) => {
              const data = doc.data();
             // Check if the contract value of the document matches the specified contract value
             if (data.mailId === emailId && data.passWord === password) {
                documents.push({ id: doc.id, ...doc.data() });
             }
        });
        console.log("raw",querySnapshot,"pro", documents);
        if (documents.length === 0) {
            console.log("No matching documents found");
            return "N";
        } else {
            console.log("Matching documents found:", documents);
            return "Y";
        }
    } catch (error) {
        console.error("Error getting documents: ", error);
        return error;
    }
}

//============================ PUT =============================

export const putByOwnerFirebase = async (address, docId) => {
    try {
        // Reference to the specific document in the "nftdetails" collection
        const docRef = doc(db, "nftdetails", docId);
        const updatedData = {
            ownerAddress: address
        };
        // Update the document with the new data
        await updateDoc(docRef, updatedData);
        console.log("Document updated with ID: ", docId);
    } catch (error) {
        console.error("Error updating document: ", error);
    }
};

export const putBypriceFirebase = async (price, docId) => {
    try {
        // Reference to the specific document in the "nftdetails" collection
        const docRef = doc(db, "nftdetails", docId);
        const updatedData = {
            nftPrice:price
        };
        // Update the document with the new data
        await updateDoc(docRef, updatedData);
        console.log("Document updated with ID: ", docId);
    } catch (error) {
        console.error("Error updating document: ", error);
    }
};

export const putBySaleFirebase = async (sale, docId, address) => {
    try {
        let coverAndProfileImages = await getuserDetailsbywalletNewFirebase(address);
        // Reference to the specific document in the "nftdetails" collection
        const docRef = doc(db, "nftdetails", docId);
        const updatedData = {
            saleDetails:sale,
            userProfile:coverAndProfileImages[0].profileImagePath
        };
        // Update the document with the new data
        await updateDoc(docRef, updatedData);
        console.log("Document updated with ID: ", docId);
    } catch (error) {
        console.error("Error updating document: ", error);
    }
};

export const putEventStatusFirebase = async (keyid, status, name, description, rewards, walletAddress, email) => {
    try {
        // Reference to the specific document in the "nftdetails" collection
        const docRef = doc(db, "events", keyid);
        // Initialize the updatedData object
        const updatedData = {
            keyId: keyid,
        };

        // Check each parameter and add it to updatedData if it has a value
        if (status) updatedData.approvalStatus = status;
        if (name) updatedData.name = name;
        if (description) updatedData.description = description;
        if (rewards) updatedData.rewards = rewards;
        if (walletAddress) updatedData.walletAddress = walletAddress;
        if (email) updatedData.networkName = email;
        // Update the document with the new data
        let data = await updateDoc(docRef, updatedData);
        let value = true;
        console.log("Document updated with ID: ", keyid);
        return [value, data];
    } catch (error) {
        let data = error;
        let value = false;
        console.error("Error updating document: ", error);
        return [value, data];
    }
};

export const putbgImagebywalletNewFirebase = async (firstName, lastName, username, walletAddress, description, twitter, discord, youtube, website, coverImage, profileImage) => {
    try {
        let document = await getuserDetailsbywalletNewFirebase(walletAddress);
        // Reference to the specific document in the "nftdetails" collection
        const docRef = doc(db, "userprofile", document[0].id);
        const updatedData = {
            walletAddress: walletAddress
        };
        if (firstName) updatedData.firstName = firstName;
        if (lastName) updatedData.lastName = lastName;
        if (username) updatedData.userName = username;
        if (description) updatedData.description = description;
        if (twitter) updatedData.twitter = twitter;
        if (discord) updatedData.discord = discord;
        if (youtube) updatedData.youtube = youtube;
        if (website) updatedData.website = website;
        if (coverImage) updatedData.coverImagePath = coverImage;
        if (profileImage) updatedData.profileImagePath = profileImage;
        // Update the document with the new data
        let data = await updateDoc(docRef, updatedData);
        let status = true;
        console.log("Document updated with ID: ", document[0].id);
        return status;
    } catch (error) {
        let data = error;
        let status = false;
        console.error("Error updating document: ", error);
        return status;
    }
};

export const updateStaketableFirebase = async (action, contractAddress, tokenId, senderAddress) => {
    try {
        let document = await getStakingbyAddressContractTokenFirebase(contractAddress, tokenId, senderAddress);
        // Reference to the specific document in the "nftdetails" collection
        const docRef = doc(db, "nftstaking", document[0].id);
        const updatedData = {
            action: action
        };
        // Update the document with the new data
        let data = await updateDoc(docRef, updatedData);
        let status = true;
        console.log("Document updated with ID: ", document[0].id);
        return status;
    } catch (error) {
        let data = error;
        let status = false;
        console.error("Error updating document: ", error);
        return status;
    }
};

export const updateUserOtpFirebase = async (email, otp, expirestatus) => {
    try {
        let doc = await getPasswordbymailFirebase(email);
        // Reference to the specific document in the "nftdetails" collection
        const docRef = doc(db, "resetpassword", doc.id);
        const updatedData = {
            otp:otp,
            expired:expirestatus
        };
        // Update the document with the new data
        await updateDoc(docRef, updatedData);
        console.log("Document updated with ID: ", doc.id);
    } catch (error) {
        console.error("Error updating document: ", error);
    }
};
  
export const ResetPasswordFirebase = async (email) => {
    const otp = generateOTP(6);
    console.log('Generated OTP:', otp);
  
    const emailSent = await sendEmail(email, otp);
    if (emailSent) {
      const otpUpdated = await updateUserOtpFirebase(email, otp, false);
      return true;
    } else {
      return false;
    }
  };

  export const ResetPasswordSubmitApiFirebase = async (emailId, otp, password) => {
    try {
        let doc = await getPasswordbymailFirebase(emailId);
        if(doc.expired == true){
            console.log("Reset Failed!");
        } else{
            // Reference to the specific document in the "nftdetails" collection
            const docRef = doc(db, "resetpassword", doc.id);
            const updatedData = {
                passWord: password
            };
            // Update the document with the new data
            await updateDoc(docRef, updatedData);
            const otpUpdated = await updateUserOtpFirebase(emailId, otp, true);
            console.log("Document updated with ID: ", doc.id);
            return true;
        }
    } catch (error) {
        console.error("Error updating document: ", error);
        return false;
    }
};

export const signStatusFirebase = async (emailId, signInStatus) => {
    try {
        let doc = await getPasswordbymailFirebase(emailId);
        // Reference to the specific document in the "nftdetails" collection
        const docRef = doc(db, "resetpassword", doc.id);
        const updatedData = {
            mailId: emailId,
            signinStatus: signInStatus
        };
        // Update the document with the new data
        await updateDoc(docRef, updatedData);
        console.log("Document updated with ID: ", doc.id);
    } catch (error) {
        console.error("Error updating document: ", error);
    }
};

//============================ HELPER FUNCTIONS =============================

const generateOTP = (length) => {
    const chars = "0123456789";
    let otp = "";
  
    for (let i = 0; i < length; i++) {
      const index = Math.floor(Math.random() * chars.length);
      otp += chars.charAt(index);
    }
  
    return otp;
  }

  const sendEmail = async (email, otp) => {
    try {
      const response = await axios.post('https://api.sendgrid.com/v3/mail/send', {
        personalizations: [{ to: [{ email: email }] }],
        from: { email: 'thiru.ezhilarasan@bosonlabs.net' },
        subject: 'OTP for Login',
        content: [{
          type: 'text/plain',
          value: `Your OTP is: ${otp}\nClick https://divine-dimension.vercel.app/reset-submission to reset the Password.`
        }]
      }, {
        headers: {
          'Authorization': 'Bearer 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',
          'Content-Type': 'application/json'
        }
      });
  
      if (response.status === 202) {
        console.log('Email sent successfully');
        return true;
      } else {
        console.log('Failed to send email:', response.status);
        return false;
      }
    } catch (error) {
      console.error('Error sending email:', error);
      return false;
    }
  };

  