import { Button, Col, FloatingLabel, Form, InputGroup, Row, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import LayoutAfterLogin from "../../component/LayoutAL";
import TabIcon1 from "../../asserts/IMAGES/Tab-SVG-icon-1.svg";
import TabIcon2 from "../../asserts/IMAGES/Tab-SVG-icon-2.svg";
import TabIcon3 from "../../asserts/IMAGES/Tab-SVG-icon-3.svg";
import BinanceLogo from "../../asserts/IMAGES/binance-logo.svg";
import AptosLogo from "../../asserts/IMAGES/aptos_round.svg";
import TabIcon4 from "../../asserts/IMAGES/Tab-SVG-icon-4.svg";
import { BoxCell, TableRow } from "./Components/Cell";
import { getAllEvents } from "../../awsdatafile";
import { useState,useEffect } from "react";
import Web3 from "web3";
import { ethers } from "ethers";
// import { tokenaddress1Ca,tokenaddress1Wv,tokenaddress1De,abi1 } from "../NFTLaunchpad/NFTLaunchpadcontracts";
import { tokenaddress1Ca, tokenaddress1Wv, tokenaddress1De } from "../../address";
import { abinftlaunchpad as abi1 } from "../../abi";
import { getAllEventsFirebase } from "../../FirebaseFunctions";
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';
import { adminAddress } from "../../address";

function Admin({ethBalance, dimeBalance}) {
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();

    const [eventDetails, setEventDetails] = useState([""]);
     const [nftFee,setNftFee]=useState(0);
    const [fundraisingFee,setFundraisingFee]=useState(0);

    const getEventsDetails = async() =>{
        let filterDetails = [];
        // let eventDetailsApi = await getAllEvents();
        // console.log("eventDetails",eventDetailsApi?.data2)
        // filterDetails = (eventDetailsApi?.data2);
        // let filteredDetails = filterDetails.filter(item => item.approvalStatus === "No");
        let eventDetailsApi = await getAllEventsFirebase();
        console.log("eventDetails",eventDetailsApi)
        filterDetails = (eventDetailsApi);
        let filteredDetails = filterDetails?.filter(item => item.approvalStatus === "No");
        setEventDetails(filteredDetails)
    }

    useEffect(()=>{getEventsDetails()}, []) 

    useEffect(() => {
        const getBalance = async () => {
            try{
                if (window.ethereum) {
                    const provider = new ethers.providers.Web3Provider(window.ethereum);
                    // const accounts = await provider.listAccounts();
                    // const account = accounts[0];
    
                    const balanceWei = await provider.getBalance(address);
                    const balanceEther = ethers.utils.formatEther(balanceWei);
                    let val = parseFloat(balanceEther);
    
                    const contract1 = new ethers.Contract(tokenaddress1Ca, abi1, provider);
                    const t1 = await contract1.MAX_NFT_SUPPLY();
    
                    const contract2 = new ethers.Contract(tokenaddress1De, abi1, provider);
                    const t2 = await contract2.MAX_NFT_SUPPLY();
    
                    const contract3 = new ethers.Contract(tokenaddress1Wv, abi1, provider);
                    const t3 = await contract3.MAX_NFT_SUPPLY();
    
                    const totalNftFee = (t1.toNumber() + t2.toNumber() + t3.toNumber()) * 0.0001;
                    val -= totalNftFee;
                    
                    console.log("check",totalNftFee);
                    setNftFee(totalNftFee);
                    setFundraisingFee(val);
                } else {
                    console.log('Ethereum wallet not detected');
                }
            }
             catch(error) {
                console.error("error:", error);
            }
        } 

        getBalance();
    }, [address, isConnected]);
    
    if((address)?.toLowerCase() === (adminAddress).toLowerCase())
    {
        return ( 
        <LayoutAfterLogin ethBalance={ethBalance} dimeBalance={dimeBalance}>
            <div className="pb-5">
                <Row className="mb-20 justify-content-md-start justify-content-center g-2">
                    {/* <Col xs={'auto'} md={'auto'}>
                        <ToggleButtonGroup type="radio" name="options-days" className="toggle-btns mb-0" defaultValue={2}>
                            <ToggleButton className="b3" id="tbg-radio-1" value={1}>
                                1H
                            </ToggleButton>
                            <ToggleButton className="b3" id="tbg-radio-2" value={2}>
                                1D
                            </ToggleButton>
                            <ToggleButton className="b3" id="tbg-radio-3" value={3}>
                                7D
                            </ToggleButton>
                            <ToggleButton className="b3" id="tbg-radio-4" value={4}>
                                30D
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Col> */}
                    <Col xs={'auto'} md={'auto'}>
                        <ToggleButtonGroup type="radio" name="options" className="toggle-btns mb-0" defaultValue={1}>
                            <ToggleButton className="b3" id="tbg-radio-logo-1" value={1}>
                                <img src={TabIcon1} alt="TabIcon1" />
                            </ToggleButton>
                            {/* <ToggleButton className="b3" id="tbg-radio-logo-2" value={2}>
                                <img src={BinanceLogo} height="16px" alt="TabIcon2" />
                            </ToggleButton>
                            <ToggleButton className="b3" id="tbg-radio-logo-3" value={3}>
                                <img src={AptosLogo} height="16px" alt="TabIcon3" />
                            </ToggleButton> */}
                            {/* <ToggleButton className="b3" id="tbg-radio-logo-4" value={4}>
                                <img src={TabIcon4} alt="TabIcon4" />
                            </ToggleButton> */}
                        </ToggleButtonGroup>
                    </Col>
                    {/* <Col xs={'auto'} className="d-none ps-3 d-md-flex align-items-center">
                        <span className="b3 text-400 text-gray me-3 text-nowrap">Floor price</span>
                        <div className="d-flex align-items-center">
                            <Form.Control
                                type="text"
                                className="btn py-2" style={{width: '80px'}}
                                placeholder="Min"
                            />
                            <span className="px-2">-</span>
                            <Form.Control
                                type="text"
                                className="btn py-2" style={{width: '80px'}}
                                placeholder="Max"
                            />
                        </div>
                        <Button style={{padding: '10.5px 15px'}} className="ms-3 b4 text-gray form-control">ETH</Button>
                    </Col> */}
                    {/* <Col xs={12} md={'auto'} className="d-md-none">
                        <InputGroup>
                            <FloatingLabel
                                controlId="floatingInput.Password"
                                label="Search by Collections"
                            >
                                <Form.Control
                                    type="text"
                                    placeholder=" "
                                />
                            </FloatingLabel>
                            <Button variant="reset" className="border-0" id="button-addon2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                            </svg>
                            </Button>
                        </InputGroup>
                    </Col> */}
                </Row>

                {/* <div className="table-stats p-2 d-none d-lg-flex text-gray text-uppercase text-500 text-12"> */}
                <div className="table-stats px-2 py-3 d-none d-lg-flex align-items-center text-gray text-uppercase text-500 text-14">
                    <div className="table-stats-count text-center">#</div>
                    <div className="table-stats-collect flex-grow-1">Fundraising Name</div>
                    <div className="table-stats-cell text-center">Type</div>
                    <div className="table-stats-cell text-center">Contract</div>
                    <div className="table-stats-cell text-center">Approval Status</div>
                    <div className="table-stats-cell text-center">Address</div>
                    {/* <div className="table-stats-cell">Items</div> */}
                    <div className="table-stats-cell text-center">Approval</div>
                </div>
                {eventDetails[0] === null || eventDetails[0] === "" || eventDetails[0] === "null" || eventDetails[0] === undefined ?
                    (<></>):
                    (<>
                    {eventDetails.map((r,i) => {
                        return(<>
                        <div className="border d-none d-lg-block p-2" key={r.keyId}>
                        <TableRow count={i+1} status="up" x={r} />
                        <div className="text-center py-md-4 py-3">
                            {/* <Button variant="white" className="b2 col-md-2 col-5 text-black">Load More</Button> */}
                        </div>
                    </div>
                        </>)
                    })}
                    </>) }
                    {/* <TableRow count="1" status="up" />
                    <TableRow count="2" status="up" />
                    <TableRow count="3" status="down" />
                    <TableRow count="4" status="down" />
                    <TableRow count="5" status="up" />
                    <TableRow count="6" status="down" />
                    <TableRow count="7" status="up" />
                    <TableRow count="8" status="down" /> */}
                <div className="d-lg-none">
                {eventDetails[0] === null || eventDetails[0] === "" || eventDetails[0] === "null" || eventDetails[0] === undefined ?
                    (<></>):
                    (<>
                    {eventDetails.map((r,i) => {
                        return(<>
                         <BoxCell count={i+1} status="up" x={r} />
                        {/* <TableRow count={i+1} status="up" x={r} /> */}
                        
                        </>)
                    })}
                     
                    </>) }
                   
                    {/* <BoxCell status="up" />
                    <BoxCell status="down" />
                    <BoxCell status="down" />
                    <BoxCell status="up" />
                    <BoxCell status="down" />
                    <BoxCell status="up" />
                    <BoxCell status="down" /> */}

                    {/* <div className="text-center py-2">
                        <Button variant="white" className="b2 col-7 text-black">Load More</Button>
                    </div> */}
                </div>
 <br/><br/>
                <div className="table-stats px-2 py-3 d-none d-lg-flex align-items-center text-gray text-uppercase text-500 text-14">
                    <div className="table-stats-count text-center">#</div>
                    <div className="table-stats-collect flex-grow-1">Platform Address</div>
                    <div className="table-stats-cell text-center">NFT Fees</div>
                    <div className="table-stats-cell text-center">Fundraising Fees</div>
                    
                </div>
                <div className="border d-none d-lg-block p-2">
                <div className="table-stats px-2 py-3 d-none d-lg-flex align-items-center text-gray text-uppercase text-500 text-14">
                    <div className="table-stats-count text-center">1</div>
                    {/* <div className="table-stats-collect flex-grow-1">0xeA3FCB45f8E27F4fc30675e760d1109f7911B579</div> */}
                    <div className="table-stats-collect flex-grow-1">{adminAddress}</div>
                    <div className="table-stats-cell text-center">{nftFee} ETH</div>
                    <div className="table-stats-cell text-center">{fundraisingFee} ETH</div>
                 </div>  
                 </div> 
                </div>
        </LayoutAfterLogin> 
         );
    }
    else
    {
        return (<></>);
    }
}

export default Admin;