import { Button, Col, Row, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import Avatar from "../../asserts/IMAGES/avatar-preview.png";
import Face from "../../asserts/IMAGES/face-image.png";
import Checked from '../../asserts/IMAGES/checked.png';
import Body from '../../asserts/IMAGES/Avatar/body-icon.svg';
import FaceIcon from '../../asserts/IMAGES/Avatar/face-icon.svg';
import Shirt from '../../asserts/IMAGES/Avatar/shirt-icon.svg';
import Pant from '../../asserts/IMAGES/Avatar/pants-icon.svg';
import Shoes from '../../asserts/IMAGES/Avatar/shoes-icon.svg';
import Sort from '../../asserts/IMAGES/Avatar/sort-icon.svg';
import Search from '../../asserts/IMAGES/Avatar/search-icon.svg';
import Filter from '../../asserts/IMAGES/Avatar/filter-icon.svg';


function CreateAvatar() {
    return ( 
        <>
            <div className="float-end d-none d-lg-block" style={{marginTop: '-75px'}}>
                <Link to="/profile" className="btn b2 btn-black btn-md">Set avatar</Link>
            </div>

            <Row className="gx-xl-5">
                <Col md={{ span: 4, order: 1 }} className="mb-md-0 mb-3">
                    <div className="card-avatar-preview d-flex align-items-end">
                        <img src={Avatar} alt="Avatar" />
                    </div>
                </Col>

                <Col md={8}>
                    <div className="card-container p-md-3 p-2 h-100 d-flex flex-column">
                        <div className="w-100">
                            <Stack direction="horizontal" gap={2} className="mb-md-3 flex-wrap avatar-icons justify-content-around mb-2">
                                <label>
                                    <input type="radio" name="accessories" hidden />
                                    <span className="btn btn-white">
                                        <img src={Body} alt="Body" />
                                    </span>
                                </label>
                                <label>
                                    <input type="radio" name="accessories" hidden />
                                    <span className="btn btn-white">
                                        <img src={FaceIcon} alt="Body" />
                                    </span>
                                </label>
                                <label>
                                    <input type="radio" name="accessories" hidden />
                                    <span className="btn btn-white">
                                        <img src={Shirt} alt="Body" />
                                    </span>
                                </label>
                                <label>
                                    <input type="radio" name="accessories" hidden />
                                    <span className="btn btn-white">
                                        <img src={Pant} alt="Body" />
                                    </span>
                                </label>
                                <label>
                                    <input type="radio" name="accessories" hidden />
                                    <span className="btn btn-white">
                                        <img src={Shoes} alt="Body" />
                                    </span>
                                </label>

                                <Button variant="white" className="order-sm-4">
                                    <img src={Filter} alt="Filter" />
                                </Button>

                                <div className="border skin p-2">
                                    <Stack direction="horizontal" gap={2}>
                                        <label className="skin">
                                            <input type="radio" name="skin" hidden />
                                            <span className="btn"><span className="btn-color" style={{backgroundColor: '#FDDFC5'}}></span></span>
                                        </label>
                                        <label className="skin">
                                            <input type="radio" name="skin" hidden />
                                            <span className="btn"><span className="btn-color" style={{backgroundColor: '#EDC3A7'}}></span></span>
                                        </label>
                                        <label className="skin">
                                            <input type="radio" name="skin" hidden />
                                            <span className="btn"><span className="btn-color" style={{backgroundColor: '#D8AA8E'}}></span></span>
                                        </label>
                                        <label className="skin">
                                            <input type="radio" name="skin" hidden />
                                            <span className="btn"><span className="btn-color" style={{backgroundColor: '#D3A88B'}}></span></span>
                                        </label>
                                        <label className="skin">
                                            <input type="radio" name="skin" hidden />
                                            <span className="btn"><span className="btn-color" style={{backgroundColor: '#C49774'}}></span></span>
                                        </label>
                                        <label className="skin">
                                            <input type="radio" name="skin" hidden />
                                            <span className="btn"><span className="btn-color" style={{backgroundColor: '#8E6044'}}></span></span>
                                        </label>
                                        <label className="skin">
                                            <input type="radio" name="skin" hidden />
                                            <span className="btn"><span className="btn-color" style={{backgroundColor: '#644323'}}></span></span>
                                        </label>
                                    </Stack>
                                </div>

                                <Button variant="white">
                                    <img src={Sort} alt="Sort" />
                                </Button>
                                <Button variant="white">
                                    <img src={Search} alt="Search" />
                                </Button>
                            </Stack>

                            <Row className="g-md-3 g-2">
                                <Col xs={6} sm={4} xl={3}>
                                    <input id="face1" type="radio" name="face" hidden />
                                    <label htmlFor="face1" className="card-avatar-choose d-block">
                                        <div className="d-flex align-items-center justify-content-end">
                                            <img src={Checked} alt="Checked" />
                                        </div>
                                        <img src={Face} className="card-avatar-choose-image" alt="Avatar" />
                                    </label>
                                </Col>
                                <Col xs={6} sm={4} xl={3}>
                                    <input id="face2" type="radio" name="face" hidden />
                                    <label htmlFor="face2" className="card-avatar-choose d-block">
                                        <div className="d-flex align-items-center justify-content-end">
                                            <img src={Checked} alt="Checked" />
                                        </div>
                                        <img src={Face} className="card-avatar-choose-image" alt="Avatar" />
                                    </label>
                                </Col>
                                <Col xs={6} sm={4} xl={3}>
                                    <input id="face3" type="radio" name="face" hidden />
                                    <label htmlFor="face3" className="card-avatar-choose d-block">
                                        <div className="d-flex align-items-center justify-content-end">
                                            <img src={Checked} alt="Checked" />
                                        </div>
                                        <img src={Face} className="card-avatar-choose-image" alt="Avatar" />
                                    </label>
                                </Col>
                                <Col xs={6} sm={4} xl={3}>
                                    <input id="face4" type="radio" name="face" hidden />
                                    <label htmlFor="face4" className="card-avatar-choose d-block">
                                        <div className="d-flex align-items-center justify-content-end">
                                            <img src={Checked} alt="Checked" />
                                        </div>
                                        <img src={Face} className="card-avatar-choose-image" alt="Avatar" />
                                    </label>
                                </Col>
                                <Col xs={6} sm={4} xl={3}>
                                    <input id="face5" type="radio" name="face" hidden />
                                    <label htmlFor="face5" className="card-avatar-choose d-block">
                                        <div className="d-flex align-items-center justify-content-end">
                                            <img src={Checked} alt="Checked" />
                                        </div>
                                        <img src={Face} className="card-avatar-choose-image" alt="Avatar" />
                                    </label>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default CreateAvatar;