import NoFound from "../Profile/components/NoFound";
import { getActivityByWallet } from "../../awsdatafile";
import { useEffect,useState } from "react";
import { Link} from "react-router-dom";
import { Card,Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { getActivityByWalletFirebase } from "../../FirebaseFunctions";

        
       
export default function Activity() {
    const location = useLocation();
    const dataParam = new URLSearchParams(location.search).get('address');
    const[getImgreffalgoActivity,setgetImgreffalgoActivity]=useState([]);   
    const[pageSize,setPageSize]=useState(12);  
    
    const dbcallalgoActivity=async()=>{        
        let req = [];
        if(dataParam  === null || dataParam  === "" || dataParam === " " || dataParam === undefined || dataParam === ''){
        }
        else{            
        let getalgo=dataParam;   
        // let activitys = await getActivityByWallet(getalgo)   ;
        // setgetImgreffalgoActivity(activitys.data2);
        // console.log("activity",activitys.data2)
        let activitys = await getActivityByWalletFirebase(getalgo)   ;
        setgetImgreffalgoActivity(activitys);
        console.log("activity",activitys)
    }        
}      
useEffect(()=>{
    if(getImgreffalgoActivity.length === 0)
        dbcallalgoActivity()
})
    return(
        // <NoFound />
        // <div>
       <Row>
    {getImgreffalgoActivity === null || getImgreffalgoActivity === "" || getImgreffalgoActivity === undefined || getImgreffalgoActivity[0] === null || getImgreffalgoActivity[0] === "" || getImgreffalgoActivity[0] === undefined || getImgreffalgoActivity.length === 0 ?
     (
        <div className="no-found py-5p text-center">
        {/* <h2>No Data Found</h2>                            
       <Link to="/market/explore" className='btn btn-primary'>Browse marketplace</Link> */}
            <center><CircularProgress color="inherit" /></center>
        </div>
    ) : ( 
    <>
    {getImgreffalgoActivity.map((x,index)=>{
    if(index<pageSize)                
     return(                                     
 <div className="card-dash p-3 d-block border-1 card">
    <div className="activity-item d-flex align-items-center mb-3">        
        <div className="activity-content">                                                
            <p>  {x.txnType}</p>
            <div class='mint-nft-container'>
              <p class="b1 cursor-pointer" >
                <a href={`https://basescan.org//tx/${x.txnHash}`} target='_blank'> {x.txnHash}</a></p>                
              <p>{x.txnTime}</p>
            </div>
        </div>
     </div>                                                                                                        
 </div> )
    })}  
    </>
    )}                                                              
    </Row>
//   </div>
    )   
}