export const tokenaddress1De='0x799499bb57d53F77086F93ba3e15FA2F41c00E55'//'0x03eA6a561fe1D161c8eB10A6a093Ca2e51451D4e';

export const tokenaddress1Ca='0x8A6ca792Af2096986Bc54F5AEA396937f8c552E4';

export const tokenaddress1Wv='0xF3fD84d6CD604c874D3ef5bA66ab1d595295DA0c';

export const divineLive1 = '0xF6bD04283E077970529D0ffA766BfF8679E2eF85';

export const dimeContract= "0x93B47489c931A65713Bfb51F5C704b12bDD2439E";
export const dimeContract2 = '0x19AcB98E4E2Ba4c39BDbEDE4706Cb37d4E0d4f18'; //from launchpadshop.js

export const adminAddress= "0xbd0659188b64ac119e6fe6bece72be1e8a01680d";
export const nftCreateAddress = '0xFC32107b3153322F4055bf5Ba7de21978E9E3Dfc';

// contracts from testnet
export const customStakeaddress = "0x20Eb1e6503e783dC8d7DEc0EEB2073e3923806B6";
export const customNftaddress = "0xD72cD54cC4e473f7E8B1E80482ddA4d6F611F57f";
export const StakingAddress = "0x930a27bA32DeB60cda667881a0Cd732642A30bB9" ; 

export const collectionIdDE = "a19c4197-3dfd-436a-a226-182e9c298a87";
export const collectionIdCA = "a19c4197-3dfd-436a-a226-182e9c298a87";
export const collectionIdWV = "a19c4197-3dfd-436a-a226-182e9c298a87";

export const ImageUrlDE = "https://gateway.pinata.cloud/ipfs/QmW4m8adsjP5J87zehVcYZvcohNPYG16n6FoK4Ex3c9SsS";
export const ImageUrlCA = "https://gateway.pinata.cloud/ipfs/QmW4m8adsjP5J87zehVcYZvcohNPYG16n6FoK4Ex3c9SsS";
export const ImageUrlWV = "https://gateway.pinata.cloud/ipfs/QmW4m8adsjP5J87zehVcYZvcohNPYG16n6FoK4Ex3c9SsS";

//from testnet details:
export const TajmahalImageCM = "https://gateway.pinata.cloud/ipfs/QmPwtxkDV9dLrE3AGKpa6Gfnog2wbfy7bjDU59QN2A5LP7";
export const TajmahalJSONCM = "https://gateway.pinata.cloud/ipfs/QmVVsW3qzuHokF3ytjdRbk5rYSwMZu7tviWG6myFYe8v7t";
export const TajMahalAddressCM = "0x524e6C4ea682a65FF04277929bF41D6F890F6A40"//"0x5D75D1854b7e321C13E0aD1c9A4eBbB2350F001c";
export const collectionIdTajMahal = "1ffaa5ae-0dc0-4da2-9b84-d0d6f170e1e7"//"85b62d3f-45cc-4f18-81de-175d7e946551";

export const PalaceOfArtsImageCM = "https://gateway.pinata.cloud/ipfs/QmWowJRub7ayaxkp9dBPNYySHii52LTSpHzTPdoB51aTdJ";
export const PalaceOfArtsJSONCM = "https://gateway.pinata.cloud/ipfs/QmUxaXi3raUYRNsVTULU2YSpGjtWSEfZJ4BcWyhrQZ3BPw";
export const PalaceOfArtsAddressCM = "0xdd2b090680243770c42eACBE5c833826c047eb50"//"0x3A646FE9bc95880CBbECF699FD83D559D6770f9b";
export const collectionIdPalaceofArts = "e0737b02-5b19-46e4-995c-59293f20425e"//"c6aa94e3-dd04-46ce-9900-b7855e3f2b69";