import Dropdown from 'react-bootstrap/Dropdown';
import AvatarPic from '../asserts/IMAGES/avatar-pic.png';
import MetaIcon from '../asserts/IMAGES/metamask-icon.svg';
import CoinIcon from '../asserts/IMAGES/coinbase-icon.svg';
import Logout from '../asserts/IMAGES/logout-icon.svg';
import LogoutBtn from '../asserts/IMAGES/logout-btn.svg';
import CopyBtn from '../asserts/IMAGES/copy-btn.svg';
import ETH from '../asserts/IMAGES/eth-icon.svg';
import DIME from '../asserts/IMAGES/dime.png';
import RARI from '../asserts/IMAGES/rari-icon.svg';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Button, FloatingLabel, Form, Nav, Stack, Col, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { Menu } from '../component/Header';
import { useState, useEffect } from 'react';
import Web3 from 'web3';
import NoFound from '../pages/Profile/components/NoFound';
import { Ethereum } from '../component/tabs/TabContent';
import { getSignUp, getuserDetailsbywalletNew, signStatus } from '../awsdatafile';
import { getuserDetailsbywalletNewFirebase } from '../FirebaseFunctions';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import BigInt from 'big-integer';
import "./AvatarDropdown.css"
import adminDetails from "../admin.json";
import { ConnectWalletCommon as ConnectWallet } from '../GeneralFunctions';
import { useWeb3ModalAccount, useWeb3ModalProvider, useWeb3Modal, useDisconnect } from '@web3modal/ethers5/react';
import { useWeb3 } from '../Web3Context';

function Avatar() {
  const [show, setShow] = useState(false);
  const [copyAddress, setCopyAddress] = useState(false);
  const [connect, setConnect] = useState(false);
  const [profileImage, setProfileImage] = useState();
  const [username, setUsername] = useState();
  const [icon, setIcon]=useState(MetaIcon);
  const { balances } = useWeb3();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let navigate = useNavigate();
  const { open } = useWeb3Modal();
  const { walletProvider } = useWeb3ModalProvider();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { disconnect } = useDisconnect();

  useEffect(() => {
    if(!address || address === null || address === "" || address === undefined)
      setConnect(true);
  }, [connect, address, isConnected])

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const disconnectWallet = async () => {
    try {
      disconnect();
      handleClose();
      // console.log('Wallet disconnected successfully.');
    } catch (error) {
      // console.error('Error disconnecting wallet:', error);
    }
  };

  async function copy() {
    navigator.clipboard.writeText(address);
    setCopyAddress(true);
    await sleep(1000);
    setCopyAddress(false);
  }

  const profileImageFetch = async () => {
      // let getProfileUpdate = await getuserDetailsbywalletNew(address);
      let getProfileUpdate = await getuserDetailsbywalletNewFirebase(address);
      // setProfileImage(getProfileUpdate?.data2[0]?.profileImagePath);
      // setUsername(getProfileUpdate?.data2[0]?.userName);
      setProfileImage(getProfileUpdate[0]?.profileImagePath);
      setUsername(getProfileUpdate[0]?.userName);
  }

  useEffect(() => {
    if(address==="Coinbase")
     setIcon(CoinIcon);

    if(address)
      profileImageFetch();
  },[address, isConnected]);

  const buyWithCash = async () => {
    window.open(
      'https://portfolio.metamask.io/buy', 
      '_blank', 
      'noreferrer'
    );
  }

  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;

    if (selectedOption !== 'default') {
        window.location.href = selectedOption;
    }
  };

  const switchMetaMaskAccount = async () => {
    if (typeof window.ethereum !== "undefined") {
      try {
        await window.ethereum.request({
          method: 'wallet_requestPermissions',
          params: [
            {
              eth_accounts: {},
            },
          ],
        });
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
  
        // if (accounts.length === 0) {
        //   console.log("No accounts found.");
        //   return;
        // }
  
        // const currentAccount = address;
        // const currentIndex = accounts.indexOf(currentAccount);
        // const nextIndex = (currentIndex + 1) % accounts.length;
        // const nextAccount = accounts[nextIndex];
  
        localStorage.setItem("walletAddress", accounts[0]);
  
        // console.log("Switched to account:", nextAccount);
        window.location.reload();
      } catch (error) {
        console.error("Error switching account:", error);
      }
    } else {
      console.log("MetaMask is not installed.");
    }
  };

  const connectWallet = async () => {
    // await ConnectWallet();
    await open();
  }

  const ChangeNetwork = async () => {
    try {
      await walletProvider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x2105' }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await walletProvider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x2105',
                chainName: 'Base',
                rpcUrls: ['https://base-rpc.publicnode.com/'],
                blockExplorerUrls: ['https://basescan.org/'],
                nativeCurrency: {
                    name: 'Base ETH',
                    symbol: 'ETH',
                    decimals: 18,
                  },
              },
            ],
          });
        } catch (addError) {
          throw addError;
        }
      }
    }
  };

  return (
    <>
    <Dropdown className='ms-lg-3 ms-2'>
      <Dropdown.Toggle variant="reset" className='p-0 border-0' id="dropdown-basic">
        {profileImage ? <>
          <img src={profileImage} width="24px" className='d-none d-sm-block' alt='Avatar' />
        </> : <>
          <img src={AvatarPic} width="24px" className='d-none d-sm-block' alt='Avatar' />
        </>}

        <button aria-controls="offcanvasNavbar-expand-xl" type="button" aria-label="Toggle navigation" class="border-0 outline-none shadow-none p-0 ms-2 navbar-toggler collapsed"><span class="navbar-toggler-icon"></span></button>
      </Dropdown.Toggle>

      <Dropdown.Menu className='dropdown-filter dropdown-avatar mt-xl-4 mt-2' align={'end'}>
        <hr className='mt-0 d-xl-none sticky-top' style={{opacity: '.1'}} />
        <div className='d-flex mb-3 align-items-center'>
          <div className='d-flex align-items-center flex-grow-1'>
          {profileImage ? <>
            <span className='me-3'><img src={profileImage} alt='Avatar' style={{width: '42px'}} /></span>
          </> : <>
            <span className='me-3'><img src={AvatarPic} alt='Avatar' style={{width: '42px'}} /></span>
          </>}
            <div>
              <h2 className='m-0'>{username}</h2>
              <Link className='b3 text-400 opacity-50 text-black' to="/profile/edit/setting">View profile</Link>
            </div>
          </div>
          {/* <Button variant='reset' className='p-0 border-0'><img className='d-block' src={Logout} alt="logout" /></Button> */}
        </div>

        <FloatingLabel
          controlId="floatingInput.Category"
          label="Network"
          className='mb-3'
        >
          <Form.Select className='b1' aria-label="Floating label select example" onChange={handleSelectChange}>
            <option value="default">Base Mainnet</option>
            <option value="https://testnet.divinedimension.io">Base Sepolia Testnet</option>
            {/* <option value="2">Two</option>
            <option value="3">Three</option> */}
          </Form.Select>
        </FloatingLabel>
        <div className='d-xl-none'>
          <Menu />

          <hr style={{opacity: '.1'}} />
        </div>

          <Nav className="flex-grow-1 flex-column">
            <NavLink className="nav-link" to="/profile/nfts">My NFTs</NavLink>
            {/* <NavLink className="nav-link" to="#">My Assets</NavLink> */}
            <NavLink className="nav-link" to="/profile/wallet">Wallet</NavLink>
            <NavLink className="nav-link" to="/profile/edit/setting">Settings</NavLink>
            {address?.toLowerCase() === adminDetails.adminAddress ? <>
              <NavLink className="nav-link" to="/admin">Admin</NavLink>
            </> : <></>}
            <NavLink className="nav-link" onClick={buyWithCash}>Buy ETH with Metamask</NavLink>
          </Nav>

          <div className='pt-3'>
            {!isConnected ? (<>
              <div className='px-2 text-13 mb-3 text-500 text-gray justify-content-between d-flex align-items-center'>
                <Button variant='black' onClick={connectWallet} className='px-md-4 px-3 me-lg-3 me-2' size='sm'>Connect wallet</Button>
              </div>   
            </>) : (<>{chainId === 8453 ? <>
                <div className='px-2 text-13 mb-3 text-500 text-gray justify-content-between d-flex align-items-center'>
                  <span>Connected wallet</span>
                  <span className='bold-on-hover' onClick={() => switchMetaMaskAccount()}>Manage Wallets</span>
                </div>

                <div className='border'>
                  <div className='py-3 d-flex align-items-center  pe-2 ps-3'>
                    <div className='d-flex align-items-center flex-grow-1'>
                      <span className='me-3'><img src={icon} alt='MetaIcon' style={{width: '40px'}} /></span>
                      <div>
                        <p className='text-black text-500 text-13 opacity-50 m-0'>Ethereum <span style={{color: '#28B833'}}>•</span></p>
                        <p className='text-black text-500 text-12'>{address && address.substring(0, 5)}....{address && address.substring((address).length - 5)}</p>
                      </div>
                    </div>
                    <Stack direction='horizontal' gap={2}>
                    {copyAddress && <div className="tooltip">Copied</div>}
                      <Button variant='reset' className='p-0 m-0' onClick={() => copy()}><img src={CopyBtn} alt="CopyBtn" /></Button>                  
                      <Button variant='reset' className='p-0 m-0' onClick={() => disconnectWallet()}><img src={LogoutBtn} alt="LogoutBtn"/></Button>                  
                    </Stack>
                  </div>

                  <div className='border -m-1 p-3'>
                    <Stack direction='vertical' gap={3}>
                      <div className='d-flex align-items-center'>
                        <span className='me-2'><img src={ETH} alt="ETH" /></span>
                        <span className='b4 flex-grow-1 text-black'>Base ETH</span>
                        <span className='b4 text-black opacity-50'>{balances.length ? <>{balances.eth} ETH</> : "0"}</span> 
                      </div>
                      {/* <div className='d-flex align-items-center'>
                        <span className='me-2'><img src={DIME} alt="DIME" height="22px"/></span>
                        <span className='b4 flex-grow-1 text-black'>DIME</span>
                        <span className='b4 text-black opacity-50'>{dimeBalance ? <>{dimeBalance} DIME</> : "0"}</span>
                      </div> */}
                      {/* <div className='d-flex align-items-center'>
                        <span className='me-2'><img src={RARI} alt="RARI" /></span>
                        <span className='b4 flex-grow-1 text-black'>RARI</span>
                        <span className='b4 text-black opacity-50'>$0</span>
                      </div> */}
                    </Stack>
                  </div>

                  <div className='p-3'>
                    <Stack direction='horizontal' gap={3}>
                      {/* <Button variant='light' type="button" className='b3 flex-grow-1 text-500'>Swap wETH</Button> */}
                      <Button variant='black' type="button" className='b3 flex-grow-1 text-500'><a a href="https://base.faucetme.pro/" target="_blank" rel="noreferrer" style={{color: "white"}}>Add funds</a></Button>
                    </Stack>
                  </div>
                </div>
            </> : 
            <><div className='px-2 text-13 mb-3 text-500 text-gray justify-content-between d-flex align-items-center'>
            <Button variant='black' onClick={ChangeNetwork} className='px-md-4 px-3 me-lg-3 me-2' size='sm'>Wrong Chain</Button>
          </div></>}</>)}    
          </div>

      </Dropdown.Menu>
    </Dropdown>

    <Modal show={show} size="lg" centered="true" onHide={handleClose}>
    <Modal.Body className='p-md-4 p-3'>
      <div className='p-md-2'>
        <div className='text-end position-relative' style={{marginBottom: '-20px', zIndex: 2}}>
          <Button variant='reset' onClick={handleClose} className='p-0 text-gray m-0'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="d-block" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </Button>
        </div>

        <Row className='justify-content-center'>
          <Col md={9}>
            <div className='text-center mt-md-0 mt-3 mb-3'>
              <h2>Connect wallet</h2>
              <p className='b2 mx-md-4 px-md-5'>Choose how you want to connect. There are two wallet providers.</p>
            </div>

            <Tabs
              defaultActiveKey="ethereum"
              id="uncontrolled-tab-example"
              className='mb-md-4 mb-lg-4 mb-3 w-100'
          >
              <Tab eventKey="ethereum" title="Base">
                  <Ethereum />
              </Tab>
              {/* <Tab eventKey="tezos" title="Tezos">
                  <NoFound />
              </Tab>
              <Tab eventKey="immutable" title={<TabTitleWithDot />}>
                  <NoFound />
              </Tab>
              <Tab eventKey="polygon" title="Polygon">
                  <NoFound />
              </Tab> */}
            </Tabs>
          </Col>
        </Row>

      </div>
    </Modal.Body>
    </Modal>
    </>
  );
}

export default Avatar;

function TabTitleWithDot() {
  return (
    <>
      Immutable X <span>•</span>
    </>
  );
}