import { Link, NavLink } from 'react-router-dom';
import Banner from '../../asserts/IMAGES/nft-banner-image.png'
import BannerBG from '../../asserts/IMAGES/nft-banner-bg.png'
import RocketIcon from '../../asserts/IMAGES/rocket-icon.svg'
import { Col, Nav, Row } from 'react-bootstrap';

import { CardViewHorizontalCa } from '../../Snippets/CardView';

function NFTLaunchpadDe() {

    let desc = "The Cultural Adventurer NFT launchpad is your passport to a boundless cultural odyssey within our metaverse platform. This remarkable NFT empowers you to embark on enlightening journeys, allowing you to immerse yourself in and gain knowledge about diverse cultures from around the world.";

    return ( 
        <>
            <div className="mb-20 d-flex d-md-none align-items-center justify-content-between">
                <h1 className="text-600 mb-0">NFT Membership</h1>
            </div>

            <div className="mb-lg-5 mb-4 mt-4 page-banner text-white page-banner-dark d-flex px-100" style={{backgroundColor: 'rgba(4, 11, 32, 1)'}}>
                <img src={BannerBG} className='page-banner-bg' alt="image" /> 
                <img src={Banner} className='page-banner-image-sm-expand' alt="image" />

                <div className='my-5 my-sm-auto text-sm-start text-center pb-sm-0 pb-4 w-100'>
                    <Row>
                        <Col xs={12} md={8} xl={7} sm={10}>
                            <h1 className='text-500'>Discover, and collect <br />Monster NFTs extraordinary</h1>
                            <p>The Arcadians: Reloaded collection is inspired by classic arcade games. Each Arcadian is custom-made by the minter from a pool of traits. </p>

                            <Link to="/market/explore" className='btn btn-small mb-2 b1 btn-outline-white me-md-3 me-2 d-inline-flex align-items-center justify-content-center' onClick={e => e.preventDefault()}><img className='me-2' src={RocketIcon} alt="RocketIcon" /> Explore more</Link>
                            <Link to="/nft-launchpad" className='btn btn-small mb-2 b1 btn-white'>Go back to NFT Membership</Link>
                        </Col>
                    </Row>
                </div>
            </div>

            {/* <div className="d-flex mb-40 overflow-auto">
                <div className="inner-links flex-nowrap text-nowrap navbar-nav d-flex flex-row">
                    <NavLink className="nav-link" to="/nft-launchpad">Completed</NavLink>
                    <Nav.Link className="nav-link ms-md-4" to="#Live">Live</Nav.Link>
                    <Nav.Link className='ms-md-4' to="#">Upcoming</Nav.Link>
                </div>
            </div> */}

            <div className='overflow-xl-hidden'>
                <h2 className='h1 mb-20'>Explore</h2>
                
                <Row className='gx-2 gx-md-3' xs={1} sm={2} md={3} lg={2} xl={3}>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewHorizontalCa size='sm' text="Phase 1" phase="1" date="Coming Soon..." description={desc}/>
                        {/* <CardViewHorizontalCa size='sm' text="Phase 1" phase="1" date="End Date: July 27, 2024 12:00:00 PM" description="default launchpad"/> */}
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewHorizontalCa size='sm' text="Phase 2" phase="2" date="Coming Soon..." description={desc}/>
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewHorizontalCa size='sm' text="Phase 3" phase="3" date="Coming Soon..." description={desc}/>
                    </Col>
                </Row>
            </div>
        </>
     );
}

export default NFTLaunchpadDe;