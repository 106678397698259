import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import BackIcon from '../../asserts/IMAGES/back-icon.svg';
import LayoutAfterLogin from "../../component/LayoutAL";
import { Button, Nav } from "react-bootstrap";

function CreateAvatarMain() {
    const navigate = useNavigate();
    
    return (
        <LayoutAfterLogin>
            <h1 className="mb-3 text-600 d-flex align-items-start">
                <Button variant="reset" className="p-0 border-0 btn-back me-md-3 me-2" onClick={() => navigate(-1)}>
                    <img src={BackIcon} alt="back" className="d-block" />
                </Button>
                Create Your Avatar
            </h1>

            <div className="d-flex">
                <div className="inner-links navbar-nav d-flex flex-row">
                    <NavLink className="nav-link" to="/avatars/my-avatars">My Avatars</NavLink>
                    <Nav.Link to="/">Shop</Nav.Link>
                    <NavLink to="/avatars/create">Create</NavLink>
                </div>
            </div>

            <div className="mt-4">
                <Outlet />
            </div>
        </LayoutAfterLogin> 

    )
}

export default CreateAvatarMain;